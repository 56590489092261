import React from 'react';
import dayjs from "dayjs";

const WarningMessage = ({message}) => {
  return (
    <div className="section-div1 warning" style={{ maxWidth: "80%", margin:" 40px auto" }}>
        <div id="section2-warning" className="section-div2 warning">
            <div className="section-div3-warning">
            <div className="section-div4">
                <div className="text-center py-1">
                <span className="span f-s-italic">
                    <pre className="w-100" style={{whiteSpace: "pre-wrap"}}>{message.bodytext}</pre>
                </span>
                </div>
            </div>
            <time aria-hidden="true" className="_6xe5 d-flex time-warning">
                {dayjs(message.timestamp).locale("es").format("DD-MMM hh:mm a")}
            </time>
            </div>
        </div>
    </div>
  );
};

export default WarningMessage;