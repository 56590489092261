import React from "react";
import { Handle, Position } from 'reactflow';

const handleStyle = { left: 10 };


const CustomNode = ({ data, isConnectable }) => {
    return (
        <button
            type="button"
            style={{
                border: 0,
                borderRadius: "4px",
                color: "#000",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "8px",
                fontWeight: "bold",
                height: "15px",
                width: "150px",
                zIndex: 100,
            }}
        >   
            <Handle 
                type="source" 
                position={Position.Right} 
                id="b" 
                isConnectable={isConnectable}  
                style={{backgroundColor: '#44B26E'}}
            />
            {data.label}
        </button>
    )
}

export default CustomNode;