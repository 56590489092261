import { useQuery, useInfiniteQuery} from '@tanstack/react-query';
import { queryClient } from "../plugins/queryClient";

import Avvvatars from "avvvatars-react";
import React, { Fragment, useContext, useEffect, useState, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import useAPI from "../../hooks/useAPI";
import Spinner from "../plugins/Spinner";
import Modal from "../layout/Modal";
import Select from "react-select";
import Header from "../layout/Header";
import { BsSearch} from "react-icons/bs";
import { PiExport } from "react-icons/pi";
import { FaAngleLeft } from "react-icons/fa";
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import debounce from 'lodash/debounce';


const List = () => {
  const [list, setList] = useState({name: "", description: "", persons: ""});
  const [validation, setValidation] = useState({});
  const [personsList, setPersonsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(500);
  const [title, setTitle] = useState('');
  const [modalFilter, setModalFilter] = useState(false);
  const [listTags, setListTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState({_id: "123abc123" , title: "Todos"});
  const [isDark, setIsDark] = useState(false);
  const [selectedSort, setSelectedSort] = useState({id: "1", title: "Fecha de Creación"});

  const page = useRef(0);
  const totalPages = useRef(1);
  const searchTermRef = useRef("");
  const listContactsRef = useRef([])
  const personsCheckedList = useRef({})
  const personsChecked = useRef(new Set())

  const { id_param } = useParams();
  const token = localStorage.getItem('USER_TOKEN');
  const { urlAPI, urlAPI_1, urlAPI_2, optGET, optPOST, optPUT, urlSocket } = useAPI();
  
  const { watch, register, handleSubmit, formState: { errors, isDirty }, reset, } = useForm({ mode: "all", defaultValues: { name: "", description: "", persons: {} } });

  // const formFilter = useForm();
  // const error2 = formFilter.formState.errors;

  const listOfSortOptions = [
    {id: "1", title: "Fecha de Creación"},
    {id: "2", title: "Orden alfabetico"}
  ]

  useEffect(() => {
    console.log("id_param: ", id_param);
    if(id_param == "new"){
      setTitle((prev) => { return "Nueva lista" });
      const name = "";
      const description = "";
      reset({ name, description });
    }else{
      setTitle((prev) => { return "Editando: " });
      getList();
    }
    fetchPersons();
    getTags()
  }, []);


  const fetchPersons = async (reset = false, filter = false) => {
    try {
      setLoading(true);
      let url;
      if (reset) {
        url = urlAPI_1 + `personsByList/${id_param}?page=0&pageSize=${pageSize}&search=`;
      } else if (filter) {
        console.log('entro acá porque es filtroooooooooo.........................🤫')
        url = urlAPI_1 + `personsByList/${id_param}?page=0&pageSize=${pageSize}&tag=${filter}`;
      } else{
        url = urlAPI_1 + `personsByList/${id_param}?page=${page.current}&pageSize=${pageSize}&search=${searchTermRef.current}`;
      }
      const response = await fetch(url, {
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      console.log('Lista de persons      👩‍🦱👩‍🦱 : ', result.data)
      if (result.code === 0) {
        Swal.fire({
          title: '¡Atención!',
          text: result.message,
          icon: result.type,
        });
      } else {
        let listPersonsCheck;
        let listPersonsCheck_aux = result.data.receiversList || [];
        listPersonsCheck_aux = listPersonsCheck_aux.map((row) => {
          row.userActive = parseBoolean(row.userActive);
          return row;
        });

        if(searchTermRef.current || page.current == 0){
          listPersonsCheck = listPersonsCheck_aux;
        }else{
          listPersonsCheck = [...listContactsRef.current, ...listPersonsCheck_aux];
        }

        const clientsTrue = listPersonsCheck.filter((cliente) => cliente.userActive === true);
        clientsTrue.forEach((receiver) => {
          personsChecked.current.add(receiver._id);
          personsCheckedList.current[receiver._id] = receiver;
        })

        if (page.current == 0) {
          const clientsFalse = listPersonsCheck.filter((cliente) => cliente.userActive === false);
          const orderedClients = [...clientsTrue, ...clientsFalse];
          listContactsRef.current = orderedClients;
          setPersonsList((prev) => { return orderedClients });
        } else {
          let new_clients_true = []; 
          for(let key_id in personsCheckedList.current){
            new_clients_true.push(personsCheckedList.current[key_id]);
          }

          const clientsFalse  = listPersonsCheck.filter((row) => {
            if(row.userActive === false && !personsChecked.current.has(row._id)){
              return true
            }
            return false; 
          });        

          const orderedClients = [...new_clients_true, ...clientsFalse];
          listContactsRef.current = orderedClients;
          setPersonsList((prev) => { return orderedClients });
          setValidation(personsChecked.current.length && personsList.length > 0);
        } 
        // Ajustamos el paginado.
        page.current = result.data.currentPage + 1
        totalPages.current = result.data.totalPages;
      }
      // console.log('📝📝📝:', personsList)
      setLoading(false);
      return result;
    } catch (error) {
      console.log('error on actualizar', error);
      return null;
    }
  };

  const getList = async () => {
    try {
      const url = urlAPI_1 + `lists/${id_param}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      setList(result.data);
      const { name, description } = result.data;
      reset({ name, description });
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  //#--> Listar las etiquetas
  const getTags = async () => {
    try {
      // setLoading(true);
      const url = urlAPI_1 + `tags`;
      const response = await fetch(url, optGET());
      const result = await response.json();
      // console.log(":: ========>> TAGS ========>> :::", result);
      if(!result.data) {
        console.log("no hay data");
        setListTags([]);
      } else {
        console.log("si hay lista");
        let list = result.data
        list.push({
          title: "Todos"
        })
        list = list.reverse()
        setListTags(list);
      }
      // setLoading(false);
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  }


  const navigate = useNavigate();

  const actionList = async (inputs) => {
    console.log("personsChecked.current: ", personsChecked.current);
    if (personsChecked.current.length === 0) {
      Swal.fire({
        title: "¡Atención!",
        text: "Debes seleccionar al menos una persona",
        icon: "error"
      });
      return;
    }

    const values = {
      name: inputs.name,
      description: inputs.description,
      persons: Array.from(personsChecked.current).toString()
    };

    let url = urlAPI_1 + `lists/`;
    let method = "POST";
    if(id_param != "new"){
      url = urlAPI_1 + `lists/${id_param}`;
      method = "PUT";
    }

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: method,
        mode: "cors",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      const result = await response.json();

      if (result.code === 1) {
        navigate('/home/lists');
      } else {
        Swal.fire({
          title: "¡Bien!",
          text: result.message,
          icon: result.type,
          confirmButtonText: "Aceptar",
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const searchQuery = (reset = false) => {
    if (page.current > totalPages.current) {
      searchQueryLocal();
    } else {
      // busqueda en db
      fetchPersons(reset);
    }
  };

  const debouncedSearchQuery = useCallback(
    debounce((value) => {
      if (value === "") {
        searchQuery(true);
      } else {
        searchQuery();
      }
    }, 2000),
    []
  );

  
  const handleChange = (e) => {
    const value = e.target.value;
    searchTermRef.current = value;
    setSearchTerm(value);
    debouncedSearchQuery(value);
  };


  const searchQueryLocal = () => {
    var searchResults = personsList.filter((element) => {
      if (
        element.name.toString().toLowerCase().includes(searchTermRef.current.toLowerCase())
      ) {
        return element;
      }
    });
    setPersonsList(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false)
    }
  };

  const handlePersonCheck = (e, person) => {
    person.userActive = parseBoolean(e.target.checked);
    let new_person_states = personsList.map((row) => {
      if (row._id === person._id) {
        row.userActive = parseBoolean(e.target.checked);
      }
      return row;
    });
    setPersonsList(new_person_states);
    if(person._id in personsCheckedList.current){
      delete personsCheckedList.current[person._id];
    }
    personsChecked.current.delete(person._id);

    if(person.userActive){
      personsChecked.current.add(person._id);
      personsCheckedList.current[person._id] = person;
    }
    console.log('👩‍🦱👩‍🦱📑📑person checked:', personsCheckedList)
    setValidation(personsChecked.current.length && personsList.length > 0);
  };

  function parseBoolean(value) {
    if (typeof value === 'boolean') {
      return value;
    } else if (typeof value === 'string') {
      const lowerCaseValue = value.toLowerCase();
      if (lowerCaseValue === 'true') {
        return true;
      } else if (lowerCaseValue === 'false') {
        return false;
      }
    }
    // Si no es ni booleano ni una cadena válida, devuelve false por defecto
    return false;
  }

  const handleScroll = (event) => {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      fetchPersons();
    }
  };

  //#--> Funcion para controlar la opcion de ordenar por:
  const handleChange3 = async (data) => {
    console.log('Orden seleccionado===============>>',data);
    setSelectedSort(data)
    setLoading(true)
    if (data.id === '2') {
      const sortedItems = personsList.sort((a, b) => a.name.localeCompare(b.name));
      console.log('📑sortedItems======= orden alfabetico ========>>', sortedItems);
      setPersonsList(sortedItems);
    } else if (data.id === "1") {
      const sortedItems = personsList.sort((a, b) => new Date(b.created) - new Date(a.created));
      console.log('📑sortedItems======== fecha de creación =======>>', sortedItems);
      setPersonsList(sortedItems);
    }
    setLoading(false)
  };
  //#--> Funcion para controlar las etiqueta seleccionada para filtrar
  const handleChange2 = (data) => {
    console.log('Etiquetas seleccionadas seleccionado=====>>',data);
    setSelectedTags(data)
  };

  const filterByTags = () => {
    setModalFilter(false)
    console.log('tags selected:', selectedTags)
    const reset = false
    if (selectedTags.title == "Todos") {
      fetchPersons()
    } else {
      fetchPersons(reset, selectedTags._id)
    }
  }

  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };
  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const exportToExcel = () => {
    let data = Object.values(personsCheckedList.current)
    console.log('DATA recibida:', data)
    if (data.length <= 0) {
      Swal.fire({
        title: '¡Atención!',
        text: 'Debes seleccionar al menos un contacto para poder descargar.',
        icon: 'info',
      });
    } else {
      let listContacts = []
      for (let c = 0; c < data.length; c++) {
        const element = data[c];
        let contact = {
          Nombre: element.name,
          Correo: element.email,
          Código: element.callingCode, 
          Teléfono: element.phone,
        }
        // console.log('element:', element)
        // console.log('element.additional_fields:', element.additional_fields)
        if (Object.keys(element.additional_fields).length > 0) {
          for (const key in element.additional_fields) {
            if (element.additional_fields.hasOwnProperty(key)) {
              const value = element.additional_fields[key];
              if (key != "segments" && key != "isV") {
                contact[key] = value;
              }
            }
          }
        }
        listContacts.push(contact)
      }
      console.log('DATA Fiiinal a exportar:', listContacts)
      const worksheet = XLSX.utils.json_to_sheet(listContacts);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${list.name === "" ? 'lista sin nombre' : list.name}.xlsx`);
    }
  };

  return (
    <Fragment>
      {loading ? <div className="content-spinner"><Spinner /></div> : null}
      <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3" >
        <div style={{ height: "45px", lineHeight: "95px" }}>
          <button onClick={() => navigate('/home/lists')} type="button" className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex" >
            <FaAngleLeft /> Listas
          </button>
        </div>

        <Header />

      </div>

      <section className="module-create-user" style={{ width: "100%", height: "88%" }}>
        <header className="message" style={{height:"9%"}}>
          <div id="tittleFormProduct"><h1>{title} {list.name}</h1></div>
        </header>

        <form onSubmit={handleSubmit(actionList)} className="Form-create-new-list" style={{height:"91%"}}>
          <div className="form-create-list m-0 px-3" style={{height:"87%"}}>

            <form className="left-content mt-3">
              <div className="module-section" id="product-basic">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label htmlFor="name">Nombre de la lista: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      id="name"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "El nombre es requerido.",
                        },
                      })}
                    />
                  </div>
                  {errors.name && (<Alert>* {errors.name.message}</Alert>)}
                </div>

                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Descripcion de la lista: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="text"
                      name="description"
                      id="description"
                      {...register("description", {
                        required: {
                          value: true,
                          message: "La descripcion es requerida.",
                        },
                      })}
                    />
                  </div>
                  {errors.description && (<Alert>* {errors.description.message}</Alert>)}
                </div>
              </div>
            </form>

            <div className="right-content pt-3" style={{height:"100%"}}>
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0 pt-1 h-100">
                <div className='' style={{ marginTop: "-15px", height:"15%" }}>
                  <div className="pb-2 w-50 d-block" style={{ float: "left", paddingTop: "15px" }}>
                    <label>Agregar contactos a la lista: </label>
                    {validation && personsChecked.current.length > 0 ? (
                      <div className="error-message">
                        <Alert className="w-100">* Debes seleccionar al menos una persona</Alert>
                      </div>
                    ) : null}
                  </div>
                  <div className="pr-4 d-flex align-items-center justify-content-between w-100" style={{ float: "right" }}>

                    <div className='d-flex align-items-center'>
                      {/* <div className='d-flex align-items-center mr-2'>
                        <label>Ordenar por: </label>
                        <Select
                          styles={isDark == 'true' ? customStylesDark : customStyles}
                          className="form-control select-template"
                          placeholder="Selecciona..."
                          onChange={handleChange3}
                          value={selectedSort}
                          options={listOfSortOptions}
                          getOptionLabel={(option) => (
                            <div className="option d-flex" style={{fontSize: "15px"}}>
                              {option.title}
                            </div>
                          )}
                          getOptionValue={(option) => option.title}
                        />
                      </div>

                      <HiAdjustmentsHorizontal
                        className="icon-filter-2 f-36 pointer ml-2"
                        style={{ color: "#9fb4b9" }}
                        onClick={() => {
                          setModalFilter(true);
                          getIsDark();
                        }}
                      /> */}
                    </div>
                    
                    <div className='d-flex align-items-center'>
                      {/* <button
                        id="inventory-button"
                        className="btn btn-default d-flex align-items-center mr-2"
                        style={{height:"2.5em"}}
                        onClick={(e) => {
                          e.preventDefault();
                          exportToExcel();
                        }}
                      >
                        <PiExport className="f-22 mr-1" /> Exportar
                      </button> */}
                      
                      <form className="form-inline">
                        <div className="su-control mt-1">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Búsqueda"
                            value={searchTerm}
                            onChange={handleChange}
                          />
                          <button
                            className="btn btn-info"
                            type="button"
                            id="buttonSearchProductBack"
                            style={{ height: "34px" }}
                            onClick={() => {
                              searchQuery();
                            }}
                          >
                            <BsSearch />
                          </button>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>

                <div className="container-fluid mt-2 px-0 mr-2 table-list-users" style={{ height: "85%", overflow: "auto", width: "99%" }} onScroll={handleScroll}>
                  <ul>
                    {personsList.length > 0 && personsList.map((person, k) => {
                      const initialWords = person.name.split(' ');
                      const firstWord = initialWords[0];
                      const secondWord = initialWords[1];
                      let icon = ""
                      if (secondWord === undefined) {
                        icon = firstWord[0];
                      } else {
                        icon = firstWord[0] + secondWord
                      }

                      return (
                        <li key={person._id} id="li-lists" className="bg-light b-primary m-t-5 px-3 py-2 row w-100 d-flex justify-content-around align-items-center">
                          <div className="col-1">
                            <Avvvatars value={icon} className="icon-avatar" />
                          </div>
                          <div className="col-3">
                            <span className="">{person.name}</span>
                          </div>
                          <div className="col-3">
                            <span className="">{person.email}</span>
                          </div>
                          <div className="col-2">
                            <span className="">{person.phone}</span>
                          </div>
                          <div className="col-2">
                            <input
                              type="checkbox"
                              value={parseBoolean(person.userActive)}
                              className="cmn-toggle cmn-toggle-round-flat"
                              name={person._id}
                              id={person.name}
                              defaultChecked={parseBoolean(person.userActive)}
                              onChange={(e) => {
                                handlePersonCheck(e, person);
                              }}
                            />
                            <label className="ml-4" htmlFor={person.name}></label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {!personsList.length && <h1 className="text-center mt-3">No existen registros en el sistema</h1>}
                </div>

                {/* <footer className="pb-0 pt-1 bg-white">
                  <div className="row">
                    <div className="col-md-8">
                    </div>
                  </div>
                </footer> */}

              </div>
            </div>
          </div>

          <footer style={{height:"13%"}}>
            <div className="row">
              <div className="col-sm-6">

              </div>
              <div className="col-sm-6 right">
                <a href="javascript:;" className="btn btn-lg btn-default mr-1"
                  onClick={() => navigate('/home/lists')}
                >Volver</a>

                <input className="btn btn-lg btn-info" value="Guardar" type="submit" disabled={validation} />
              </div>
            </div>
          </footer>

        </form>

      </section>


      {/* Modal para crear o editar una respuesta rápida */}
      <Modal status={modalFilter} changeStatus={setModalFilter} title='Filtrar' width={"600px"}>
        <Content>
          <form
            // onSubmit={handleSubmit(postQuickReply)}
            style={{ width: "100%" }}
          >
            <div className="">
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Selecciona la etiqueta a filtrar: </label>
                <div className="su-control-group">
                  <Select
                    styles={isDark == 'true' ? customStylesDark : customStyles}
                    className="form-control select-template"
                    placeholder="Selecciona una etiqueta"
                    onChange={handleChange2}
                    value={selectedTags}
                    options={listTags}
                    getOptionLabel={(option) => (
                      <div key={option._id} className="option d-flex" style={{fontSize: "15px"}}>
                        {option.title}
                      </div>
                    )}
                    getOptionValue={(option) => option.title}
                  />
                </div>
              </div>
              
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalFilter((prevMessage) => { return false});
                }}
              >
                Volver
              </a>
              <input
                className="btn btn-lg btn-info"
                type="button"
                // type="submit"
                value="Aceptar"
                onClick={() => {
                  filterByTags()
                }}
              />
            </div>
          </form>


        </Content>
      </Modal>

    </Fragment>
  );
};

export default List;

const Button = styled.button`
    /*width: 100px;
    padding: 15px;
    font-weight: 900;
    margin-bottom: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);*/
`

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 85px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  .content-img img:hover {
    opacity: 0.6;
  }
`;

const Container = styled.div`
  background: #fff;
  border-radius: 12px;
  border: 1px solid #ddd;
  padding-bottom: 20px;
  
  .left-content {
    width: 60%;
  }

  .left-content label {
    font-weight: 900;
    font-size: 16px;
  }

  .left-content input {
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px;
    border-radius: 12px;
    border: 1px solid #cccccc;
    width: 85%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    height: 45px;
  }

  .right-content {
    width: 90%;
    margin-top: 25px;
  }

  .right-content table {
    border: 1px solid #ddd;
  }

  .right-content .table-list-users {
    border-bottom: 1px solid #ddd;
    border-radius: 12px;
  }

  .form-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  @media (max-width: 660px) {
    .form-content {
      flex-direction: column;
    }

    .form-field {
      margin-top: 10px;
    }
  }

  .form-field {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 40px auto;
  }

  .form-field label {
    width: 100%;
    text-align: center;
  }

  .table-content {
    width: 95%;
    margin: 0 auto;
    height: 460px;
    overflow: auto;
  }

  .thead {
    font-weight: 900;
  }

  .checkbox {
      width: 30px;
      height: 30px;
      margin: 0 auto;
  }

  .td-check {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
