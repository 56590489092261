import React from 'react';
import { Recorder } from "react-voice-recorder";
import { BsTrash3 } from "react-icons/bs";
import { IoMdSend } from "react-icons/io";

const SendMessageOrRecordAudio = ({
  recording,
  contenMessage,
  selectedEmoji,
  isRecor,
  audioDetails,
  dataFilesRef,
  setIsMedia,
  setFileType,
  setIsRecor,
  setAudioDetails,
  setRecording,
  setSelectedFiles,
  onSendMessage,
  convertRecorBase64,
  focusLastMessage,
}) => {

  const handleAudioUpload = (file) => {
    console.log('Actualizando audio.......')
    console.log(file);
  };

  const handleCountDownAudio = (data) => {
    console.log('grabando audio.......')
    setRecording(true);
  };

  //Funciones para controlar el record-audio
  const handleAudioStop = async (data) => {
    console.log("Data del audio: ", data);
    data.blob.name = "record.webm";
    setAudioDetails((prevMessage) => { return data})
    setRecording(false);
    setIsRecor(true);
    //await uploadToS3(data.blob);

    const selectedFiles = [];
    const base64 = await convertRecorBase64(data.blob);
    if (base64) {
      selectedFiles.push({
        src: base64,
        name: "record.webm",
        size: `${((data.blob.size * 1) / 1000000).toFixed(2)} MB`,
        file: data.blob,
        type: "audio",
        url: data.url
      });
    }

    setSelectedFiles(selectedFiles);
    dataFilesRef.current = selectedFiles;

  };

  const handleResetAudio = () => {
    console.log('limpiando audio.......')
    setAudioDetails((prevMessage) => { return {url: null, blob: null, chunks: null, duration: {h: 0, m: 0, s: 0, ms: 0}}})
  };

  return (
    <div id ={isRecor && 'content-recorder'} className={!recording ? "mx-3 d-flex recording" : "mx-3 d-flex"} style={!recording ? { alignItems: "center", zIndex: "999" } : { alignItems: "center", width: "100%" }}>
      {contenMessage || selectedEmoji ? (
        <span className='d-flex ml-2 w-auto' style={{backgroundColor:"#25d366", padding:"8px 6px 8px 11px", borderRadius:"50%"}}>
          <IoMdSend id='icon-send-messages' className="send pointer" style={{ fontSize: "27px" }}
              onClick={() => {
                  onSendMessage({});
                  setTimeout(function(){
                    focusLastMessage("template-4699");
                  }, 200);
                  setIsMedia((isMedia) => { return false });
                  setFileType("");
              }}
          />
        </span>
      ) : (
        <>
          {isRecor && (
            <BsTrash3 className="icon-trash-empty audio mr-2" style={{ fontSize: "30px" }}
              onClick={() => {
                handleResetAudio();
                setIsRecor(false);
                setAudioDetails({
                  url: null,
                  blob: null,
                  chunks: null,
                  duration: {
                    h: 0,
                    m: 0,
                    ms: 0,
                    s: 0,
                  },
                });
              }}
            />
          )}
          <Recorder
            record={true}
            title={"New recording"}
            audioURL={audioDetails.url}
            showUIAudio
            handleAudioStop={(data) => handleAudioStop(data)}
            handleAudioUpload={(data) => handleAudioUpload(data)}
            handleCountDown={(data) => handleCountDownAudio(data)}
            handleReset={() => handleResetAudio()}
            mimeTypeToUseWhenRecording={`audio/webm`}
          />
          {isRecor && (
            <span className='d-flex ml-2 w-auto' style={{backgroundColor:"#25d366", padding:"8px 6px 8px 11px", borderRadius:"50%"}}>
              <IoMdSend id='icon-send-messages' className="send pointer" style={{ fontSize: "27px" }}
                onClick={() => {
                  onSendMessage({});
                  handleResetAudio();
                  setTimeout(function(){
                    focusLastMessage("template-4745");
                  }, 200);
                }}
              />
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default SendMessageOrRecordAudio;