import React from 'react';
import AutoLinkText from 'react-autolink-text2';

const AdvertisingMessage = ({
  message,
  imgAdvertising,
  agentAsignMsj,
  textContext,
  bodyTextContext,
}) => {
  return (
    <div className="">
      <div className="section-div4 text-left">
        <div className="header-preview mb-2">
          <div
            className="content-header-order d-flex pointer"
            style={message.originMessage === "user" ? {background:"#f0f0f0"} : {background:"#bef3a6"}}
          >
            <img src={imgAdvertising} alt="img-product" style={{height:"7rem"}} />
            <div className="bg-transparent d-flex flex-column justify-content-center font-weight-bold" >
              <span className="mx-2" style={{color: "#5bc0de"}}>{agentAsignMsj}</span>
              <p className="mb-0 mt-1 mx-2 font-weight-normal d-flex" style={{fontSize: "0.75rem"}}>
                <span className="span-color" style={{color: "#a5a7a8"}}>
                  {textContext.length > 190 ? textContext.substring(0, 190) + '...' : textContext}
                </span>
              </p>
            </div>
          </div>
        </div>
        <span className="span message-text">
          <AutoLinkText text={bodyTextContext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
        </span>
      </div>
    </div>
  );
};

export default AdvertisingMessage;