import React from 'react';
import { HiShoppingCart } from "react-icons/hi";

const OrderMessage = ({
    message,
    headerText,
    imgProduct,
    numberProducts,
    orderShopping,
    listProducts,
    formGenerateLink,
    setModalViewOrder,
    setQuantityOrder,
    setTotalPriceOrder,
    setOrderSelected,
}) => {

    //#--> Visualizar orden selecionada
    const onViewOrder = async (orderShopping) => {
        setModalViewOrder(true)
        let productsOrder = []
        let totalQuantity = 0
        let totalOrder = 0
        for (let pro of orderShopping) {
        const orderProducts = listProducts.find(prod => prod.retailer_id === pro.product_retailer_id)
        console.log('Data product:', orderProducts);

        let productOrder = {}
        productOrder.name = orderProducts.name
        productOrder.description = orderProducts.description
        productOrder.price = orderProducts.sale_price ? orderProducts.sale_price : orderProducts.price
        productOrder.image_url = orderProducts.image_url
        productOrder.quantity = pro.quantity
        productsOrder.push(productOrder)

        totalQuantity = totalQuantity + pro.quantity
        const priceTotalProduct = pro.quantity * pro.item_price
        totalOrder = totalOrder + priceTotalProduct
        }
        setQuantityOrder(totalQuantity)
        setTotalPriceOrder(totalOrder)
        setOrderSelected(productsOrder)
        formGenerateLink.reset({totalOrder: totalOrder})
    }

  return (
    <>
        <div className="">
            <div className="section-div4 text-left">
                <div className="header-preview">
                    <div
                        className="content-header-order d-flex pointer"
                        style={message.originMessage === "user" ? {background:"#f0f0f0"} : {background:"#bef3a6"}}
                        onClick={() => onViewOrder(orderShopping)}
                    >
                        <img src={imgProduct} alt="img-product" style={{height:"5rem"}} />
                        <div className="bg-transparent d-flex flex-column justify-content-center font-weight-bold">
                            <span className="d-flex align-items-center ml-2">
                                <HiShoppingCart style={{fontSize:"1.2rem"}} />
                                <span className="ml-1">{numberProducts && numberProducts > 1 ? `${numberProducts} artículos` : `${numberProducts} artículo`}</span>
                            </span>
                            <p className="mx-2 font-weight-normal" style={{fontSize: "0.75rem"}}>{headerText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default OrderMessage;