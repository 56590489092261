import React from 'react';
import dayjs from "dayjs";
import { TbMessage2X } from "react-icons/tb";

const CloseConvMessage = ({message}) => {
  return (
    <div className="section-div1 transfer" style={{ maxWidth: "60%", margin:" 40px auto" }}>
        <div id="section2-transfer" className="section-div2 transfer">
            <div className="section-div3-transfer" style={{backgroundColor:"#f8c7d1"}}>
            <div className="section-div4">
                <div className="text-center py-1">
                <span className="span f-s-italic">
                    <pre className="w-100 d-flex align-items-center justify-content-center" style={{ whiteSpace: "pre-wrap" }}>
                        <TbMessage2X className="mr-1 f-20" />{message.bodytext}
                    </pre>
                </span>
                </div>
            </div>
            <time aria-hidden="true" className="_6xe5 d-flex time-transfer">
                {dayjs(message.timestamp).locale("es").format("DD-MMM hh:mm a")}
            </time>
            </div>
        </div>
    </div>
  );
};

export default CloseConvMessage;