// API_1
//const urlAPI_1 = "https://tvd6p8m4xk.execute-api.us-east-1.amazonaws.com/dev/"; //# Producción
const urlAPI_1 = "https://api.sendingup.co/prod/api1/" //"https://106ul0a45f.execute-api.us-east-1.amazonaws.com/prod/"; //# Producción

export const upload_s3 = async (file, params_var) => {
    // Divide the file into parts
    const chunkSize = (1024 * 1024) * 5; // 5MB defined as each parts size
    let file_type = file.type;
    console.log("file_type: ", file_type);
    if(file.type === "audio/*"){
        file_type = "audio/webm";
    };
    if(file.size <= chunkSize){
        const result = await fetch(params_var.url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file_type
          }
        });
        if(result.status === 200){
            return result.url.split("?")[0]
        }else{
            console.log('Error putting file...');
            return false;
        }
    }

    const partCount = Math.ceil(file.size / chunkSize);
    const uploadPromises = [];
    const slicedData = [];
    let  completedParts = [];
    for (let i = 0; i < partCount; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const partNumber = i + 1;
      const part = file.slice(start, end);

      const promise = fetch(params_var.url[i], {
        method: 'PUT',
        mode: "cors",
        headers: {
          'Content-Type': file_type,
        },
        body: part,
      }).then((response) => {
        if (response.ok) {
            completedParts.push({ PartNumber: partNumber, ETag: JSON.parse(response.headers.get('etag')) })
        }else{
            completedParts.log(`Error uploading part file ${partNumber}. Status: ${response.status}`);
        }
      });
      uploadPromises.push(promise);
      // It is used then if any part fails, because we will make a second request.
      slicedData.push({ PartNumber: partNumber, body: part });
    }

    let parts = await Promise.allSettled(uploadPromises);
    // Hacemos un reintento de las partes que fallaron.
    let failedUploads = parts.filter(f => f.status === "rejected");
    //array to hold all completed upload even from retry upload as will be seen later
    try {
        if(!failedUploads.length){
            for (let i = 0; i < failedUploads.length; i++) {
               let [data] = slicedData.filter(f => f.PartNumber === failedUploads[i].value.PartNumber)
               await fetch(params_var.url[data.PartNumber - 1], {
                  method: 'PUT',
                  body: data.body,
                  headers: {
                    'Content-Type': file_type
                  }
               }).then((response) => {
                    if (response.ok) {
                        completedParts.push({ PartNumber: data.PartNumber, ETag: JSON.parse(response.headers.get('etag')) })
                    }else{
                        completedParts.log(`Error retry uploading part file ${data.PartNumber}. Status: ${response.status}`);
                    }
               });
            }
         }
    } catch (error) {
        // handle error
        console.log("ERROR retry upload part: ", error);
    }

    const url = urlAPI_1 + "completeMultiPart";
    const token = localStorage.getItem("USER_TOKEN");
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({completedParts: completedParts, key: params_var.key, upload_id: params_var.upload_id, type: file_type}),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if(response.status === 200){
        let aux = await response.json();
        if(aux.error){
            return false;
        }
        return aux.url.Location;
    }else{
        console.log('Error putting file...');
        return false;
    }
};
