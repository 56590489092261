import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../plugins/Spinner";
import Header from "../layout/Header";
import Swal from "sweetalert2";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Navigation from "../layout/Navigation";
import logoFooter from "../../imgs/logo-footer.png";
import { useNavigate, useParams } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import Modal from "../layout/Modal";
import dayjs from "dayjs";

import { FaAngleLeft } from "react-icons/fa";
import { BiSolidHelpCircle } from "react-icons/bi";
import { RiCloseCircleFill } from "react-icons/ri";

const Support = () => {
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const [statusTicket, setStatusTicket] = useState("");

  const [ticket, setTicket] = useState([]);
  const [media, setMedia] = useState([]);
  const [mediaSelected, setMediaSelected] = useState("");
  const [arrayQuestion, setArrayQuestion] = useState([]);
  const [finalArray, setFinalArray] = useState([]);

  const [emailUser, setEmailUser] = useState("");

  const [modal, setModal] = useState(false);

  const [imageSelected1, setImageSelected1] = useState("");
  const [imageSelected2, setImageSelected2] = useState("");
  const [imageSelected3, setImageSelected3] = useState("");

  const { id } = useParams();
  const token = localStorage.getItem("USER_TOKEN");
  const { urlAPI, urlAPI_1, urlAPI_2 } = useAPI();

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      department: "",
      affair: "",
      question: [],
    },
  });

  useEffect(() => {
    getTicket(id);
  }, []);

  // Obtener datos del ticket seleccionado
  const getTicket = async (id) => {
    console.log("id seleccionado.....", id);
    try {
      setLoading(true);
      const url = urlAPI_2 + `tickets/${id}`;
      const response = await fetch(url, {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log("Ticket seleccionado....", result.data);
      setTicket(result.data);
      setEmailUser(result.data.email);
      setStatusTicket(result.data.status);
      setMedia(result.data.question[0].media);
      const { department, affair } = result.data;
      const questions = result.data.question[0].content;
      setArrayQuestion(result.data.question);
      reset({
        department,
        affair,
        question: questions,
      });

      const arrayFinal = result.data.question;

      var arrayNew = [];
      for (let i = 1; i < arrayFinal.length; i++) {
        const element = arrayFinal[i];
        arrayNew.push(element);
      }
      console.log("arrayNew......", arrayNew);
      setFinalArray(arrayNew);

      // const res = arrayFinal.splice(0, 1);
      // console.log("elemento eliminado", res);
      // console.log("Array final", arrayFinal);
      // setFinalArray(arrayFinal);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Enviar nueva observación
  var newArray = arrayQuestion;
  const putTicket = async () => {
    console.log("Array question", newArray);

    const { department, affair, question, observations } = watch();

    const today = new Date();

    if (observations) {
      if (imageSelected1 || imageSelected2 || imageSelected3) {
        newArray.push({
          content: observations,
          media: {
            image: imageSelected1,
            image2: imageSelected2,
            image3: imageSelected3,
          },
          created: today,
          email: emailUser,
        });
      } else {
        newArray.push({
          content: observations,
          created: today,
          email: emailUser,
        });
      }
    }

    console.log("Array 2", newArray);

    const newObject = {
      department,
      affair,
      question: newArray,
    };
    console.log("newObject", newObject);
    try {
      setLoading(true);
      const url = urlAPI_2 + `tickets/${id}`;
      const response = await fetch(url, {
        method: "PUT",
        mode: "cors",
        body: JSON.stringify(newObject),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      const result = await response.json();
      console.log("Data Updated ticket", result);

      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      navigate("/home/support");
    } catch (error) {
      console.log(error);
    }
  };

  //Convertit imagen 1 en base64
  const onImage1 = async (e) => {
    var file = e.target.files[0];
    var base64 = await convertImage1Base64(file);
    // console.log("Imagen 1========> ", base64);
    setImageSelected1(base64);
  };
  const convertImage1Base64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  //Convertit imagen 2 en base64
  const onImage2 = async (e) => {
    var file = e.target.files[0];
    var base64 = await convertImage2Base64(file);
    // console.log("Imagen 2========> ", base64);
    setImageSelected2(base64);
  };
  const convertImage2Base64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  //Convertit imagen 3 en base64
  const onImage3 = async (e) => {
    var file = e.target.files[0];
    var base64 = await convertImage3Base64(file);
    // console.log("Imagen 3========> ", base64);
    setImageSelected3(base64);
  };
  const convertImage3Base64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  const navigate = useNavigate();

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
        <div style={{ height: "45px", lineHeight: "95px" }}>
          <button
            type="button"
            className="btn btn-left col-1-sm-3 my-auto position-absolute"
            onClick={() => {
              navigate("/home/support");
            }}
          >
            <FaAngleLeft /> Tickets
          </button>
        </div>
        <Header />
      </div>

      <section
        className="module-create-user"
        style={{ width: "90%", marginBottom: "40px" }}
      >
        <header className="message pb-2">
          <div id="tittleFormProduct">
            <h1>
              Detalles del ticket <strong>{ticket.consecutiveId}</strong>
            </h1>
            <h4 className="my-3" style={{ fontSize: "18px" }}>
              Estado: <strong>{ticket.status}</strong>
            </h4>
          </div>
        </header>

        <form
        // onSubmit={handleSubmit(putCompany)}
        >
          <div className="module-section" id="product-basic">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="select">Departamento: </label>
                      <div className="su-control-group">
                        <input
                          className="form-control"
                          name="affair"
                          id="affair"
                          aria-readonly
                          type="text"
                          disabled
                          {...register("department", {
                            required: {
                              value: true,
                              message: "El departamento es obligatoria.",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label>Asunto: </label>
                      <div className="su-control-group">
                        <input
                          className="form-control"
                          name="affair"
                          id="affair"
                          aria-readonly
                          type="text"
                          disabled
                          {...register("affair", {
                            required: {
                              value: true,
                              message: "El numero asunto es obligatorio.",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label>Pregunta: </label>
                      <div className="su-control-group">
                        <textarea
                          className="form-control"
                          name="question"
                          id="question"
                          aria-readonly
                          style={{ height: "120px" }}
                          disabled
                          {...register("question", {
                            required: {
                              value: true,
                              message: "La pregunta es obligatorio.",
                            },
                          })}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                {media && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="su-product-gallery d-flex">
                        {media.image && (
                          <div
                            className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                            id="image2_url"
                            onClick={() => {
                              setModal(true);
                              setMediaSelected(media.image);
                            }}
                          >
                            <div className="rectangle-box" id="boximg-img2">
                              <div className="rectangle-content">
                                <div></div>
                              </div>
                              <img src={media.image} className="w-100" />
                            </div>
                          </div>
                        )}

                        {media.image2 && (
                          <div
                            className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                            id="image2_url"
                            onClick={() => {
                              setModal(true);
                              setMediaSelected(media.image2);
                            }}
                          >
                            <div className="rectangle-box" id="boximg-img2">
                              <div className="rectangle-content">
                                <div></div>
                              </div>
                              <img src={media.image2} className="w-100" />
                            </div>
                          </div>
                        )}

                        {media.image3 && (
                          <div
                            className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                            id="image2_url"
                            onClick={() => {
                              setModal(true);
                              setMediaSelected(media.image3);
                            }}
                          >
                            <div className="rectangle-box" id="boximg-img2">
                              <div className="rectangle-content">
                                <div></div>
                              </div>
                              <img src={media.image3} className="w-100" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </section>

      {/* {arrayQuestion.length > 1 && */}
      {finalArray &&
        finalArray.map((question, q) => (
          <section
            key={q}
            className="module-create-user"
            style={{ width: "90%", marginBottom: "40px" }}
          >
            <header className="message pb-2">
              <div id="tittleFormProduct">
                <h5 style={{ fontSize: "14px" }}>
                  {dayjs(question.created)
                    .locale("es")
                    .format("DD MMMM YYYY, hh:mm a")}
                </h5>
                <p>
                  <strong>{question.email}: </strong>
                  {question.content}
                </p>

                {question.media && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="su-product-gallery d-flex">
                        {question.media.image && (
                          <div
                            className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                            id="image2_url"
                            onClick={() => {
                              setModal(true);
                              setMediaSelected(question.media.image);
                            }}
                          >
                            <div className="rectangle-box" id="boximg-img2">
                              <div className="rectangle-content">
                                <div></div>
                              </div>
                              <img
                                src={question.media.image}
                                className="w-100"
                              />
                            </div>
                          </div>
                        )}

                        {question.media.image2 && (
                          <div
                            className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                            id="image2_url"
                            onClick={() => {
                              setModal(true);
                              setMediaSelected(question.media.image2);
                            }}
                          >
                            <div className="rectangle-box" id="boximg-img2">
                              <div className="rectangle-content">
                                <div></div>
                              </div>
                              <img
                                src={question.media.image2}
                                className="w-100"
                              />
                            </div>
                          </div>
                        )}

                        {question.media.image3 && (
                          <div
                            className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                            id="image2_url"
                            onClick={() => {
                              setModal(true);
                              setMediaSelected(question.media.image3);
                            }}
                          >
                            <div className="rectangle-box" id="boximg-img2">
                              <div className="rectangle-content">
                                <div></div>
                              </div>
                              <img
                                src={question.media.image3}
                                className="w-100"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </header>
          </section>
        ))}

      {statusTicket != "Solucionado" ? (
        <section className="module-create-user" style={{ width: "90%" }}>
          <header className="message">
            <div id="tittleFormProduct">
              <h1>Responder</h1>
            </div>
          </header>

          <form onSubmit={handleSubmit(putTicket)}>
            <div className="module-section" id="product-basic">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <label>Observaciones: </label>
                        <div className="su-control-group">
                          <textarea
                            className="form-control"
                            name="observations"
                            id="observations"
                            aria-readonly
                            style={{ height: "120px" }}
                            {...register("observations", {
                              required: {
                                value: true,
                                message: "La pregunta es obligatorio.",
                              },
                            })}
                          ></textarea>
                          {errors.observations && (
                            <Alert>* {errors.observations.message}</Alert>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="su-control">
                        <label>Imagenes adjuntas: </label>{" "}
                        <small>adjunta máximo 3 imagenes</small>
                      </div>
                      <div className="su-product-gallery d-flex">
                        <div
                          className={
                            imageSelected1
                              ? "su-control su-widget-image small uncut image_url_supp mr-1 active"
                              : "su-control su-widget-image small uncut image_url_supp mr-1"
                          }
                          id="image2_url"
                          data-key="image2_url"
                        >
                          <label htmlFor="image1">
                            <span>Imagen</span>
                          </label>
                          {imageSelected1 && (
                            <RiCloseCircleFill
                              className="close icon-cancel-circled mr-1"
                              onClick={() => setImageSelected1("")}
                              style={{
                                position: "absolute",
                                zIndex: "99999",
                                right: "0",
                                fontSize: "27px",
                              }}
                            />
                          )}
                          <input
                            type="file"
                            id="image1"
                            name="image1"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            onChange={(e) => onImage1(e)}
                          />

                          <div
                            className="rectangle-box"
                            id="boximg-img2"
                            htmlFor={imageSelected1 ? "image1" : "none"}
                          >
                            <div className="rectangle-content">
                              <div></div>
                            </div>
                            {imageSelected1 && (
                              <img src={imageSelected1} className="w-100" />
                            )}
                          </div>
                        </div>

                        <div
                          className={
                            imageSelected2
                              ? "su-control su-widget-image small uncut image_url_supp mr-1 active"
                              : "su-control su-widget-image small uncut image_url_supp mr-1"
                          }
                          id="image2_url"
                          data-key="image2_url"
                        >
                          <label htmlFor="image2">
                            <span>Imagen</span>
                          </label>
                          {imageSelected2 && (
                            <RiCloseCircleFill
                              className="close icon-cancel-circled mr-1"
                              onClick={() => setImageSelected2("")}
                              style={{
                                position: "absolute",
                                zIndex: "99999",
                                right: "0",
                                fontSize: "27px",
                              }}
                            />
                          )}
                          <input
                            type="file"
                            id="image2"
                            name="image2"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            onChange={(e) => onImage2(e)}
                          />

                          <div
                            className="rectangle-box"
                            id="boximg-img2"
                            htmlFor={imageSelected2 ? "image2" : "none"}
                          >
                            <div className="rectangle-content">
                              <div></div>
                            </div>
                            {imageSelected2 && (
                              <img src={imageSelected2} className="w-100" />
                            )}
                          </div>
                        </div>

                        <div
                          className={
                            imageSelected3
                              ? "su-control su-widget-image small uncut image_url_supp mr-1 active"
                              : "su-control su-widget-image small uncut image_url_supp mr-1"
                          }
                          id="image2_url"
                          data-key="image2_url"
                        >
                          <label htmlFor="image3">
                            <span>Imagen</span>
                          </label>
                          {imageSelected3 && (
                            <RiCloseCircleFill
                              className="close icon-cancel-circled mr-1"
                              onClick={() => setImageSelected3("")}
                              style={{
                                position: "absolute",
                                zIndex: "99999",
                                right: "0",
                                fontSize: "27px",
                              }}
                            />
                          )}
                          <input
                            type="file"
                            id="image3"
                            name="image3"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            onChange={(e) => onImage3(e)}
                          />

                          <div
                            className="rectangle-box"
                            id="boximg-img2"
                            htmlFor={imageSelected3 ? "image3" : "none"}
                          >
                            <div className="rectangle-content">
                              <div></div>
                            </div>
                            {imageSelected3 && (
                              <img src={imageSelected3} className="w-100" />
                            )}
                          </div>
                        </div>

                        <div
                          className="dropdown"
                          style={{ display: "flex", alignItems: "end" }}
                        >
                          <button
                            className="btn btn-link btn-help p-0 ml-1 mb-1"
                            title="Medidas recomendadas"
                            type="button"
                            style={{ zIndex: "999" }}
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <BiSolidHelpCircle style={{ fontSize: "18px" }} />
                          </button>
                          <div
                            className="dropdown-menu p-0 text-center"
                            aria-labelledby="dropdownMenuButton"
                            style={{ width: "350px" }}
                          >
                            <header className="py-1 px-2">
                              <strong>Medidas recomendadas</strong>
                            </header>
                            <div className="px-2">
                              Recomendamos usar uno de los siguientes tres
                              formatos: <br />
                              a) 1200 x 800 - horizontal.
                              <br />
                              b) 800 x 1200 - vertical.
                              <br />
                              c) 1200 x 1200 cuadrada.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="mx-0">
              <div className="row">
                <div className="col-sm-6"></div>
                <div className="col-sm-6 right">
                  <a
                    href="javascript:;"
                    className="btn btn-lg btn-default mr-1"
                    onClick={() => {
                      navigate("/home/support");
                    }}
                  >
                    Volver
                  </a>
                  <input
                    className="btn btn-lg btn-info"
                    value="Guardar"
                    type="submit"
                    // type="button"
                  />
                </div>
              </div>
            </footer>
          </form>
        </section>
      ) : null}

      <Modal
        status={modal}
        changeStatus={setModal}
        title="Archivo adjunto"
        // action={cleanInputs}
        width={"800px"}
        height={"300px"}
      >
        <Content>
          {/* Creacion del formulario para el registro de cada usuario o persona */}
          <div className="justify-content-center">
            <form>
              <div className="justify-content-center">
                <div className="row mb-3">
                  <img src={mediaSelected} />
                </div>
              </div>
              <div className="row justify-content-end">
                <input
                  className="btn btn-lg btn-info"
                  value="Cerrar"
                  name="submit"
                  id="submit"
                  type="button"
                  onClick={() => {
                    setModal(false);
                    setMediaSelected("");
                  }}
                />
              </div>
            </form>
          </div>
        </Content>
      </Modal>
    </Fragment>
  );
};

export default Support;

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  img {
    width: 50%;
    vertical-align: top;
    border-radius: 3px;
    margin: 0 auto;
  }

  // label {
  //   font-weight: 900;
  //   font-size: 18px;
  // }

  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }

  h5 button {
    color: #5bc0de;
  }

  h5 button:hover {
    color: #28a1c5;
  }
`;
