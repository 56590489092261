import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import Spinner from "../plugins/Spinner";
import useAPI from "../../hooks/useAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chart, registerables } from "chart.js";
import Header from "../layout/Header";
import {
  BsCheck,
  BsCheckAll,
  BsX,
  BsSearch
} from 'react-icons/bs';

import {
  FaAngleLeft,
  FaListOl,
  FaRegClock,
  FaComment,
  FaCalendarCheck
} from 'react-icons/fa';

import { BiSolidHelpCircle } from "react-icons/bi";
import { PiUsersThreeFill } from "react-icons/pi";
import { IoMdSend } from "react-icons/io";
import { Bar } from "react-chartjs-2";

Chart.register(...registerables);

const CampaignReport = () => {
  const [loading, setLoading] = useState(false);
  const [navegationView, setNavegationView] = useState("all");
  const [campaignData, setCampaignData] = useState({
    nameCampaign: "",
    sendingDateCampaign: "",
    creationDateCampaign: "",
    nameListCampaign: "",
    numberUsers: 0,
    notMessages: 0,
    dataBar: {
      labels: [],
      datasets: [
        {
          label: "",
          data: [],
        },
      ],
    },
    dataSent: {
      num: 0,
      percentage: 0,
      percentageVol: 0 
    },
    dataDelivered: {
      num: 0,
      percentage: 0,
      percentageVol: 0 
    },
    dataRead: {
      num: 0,
      percentage: 0,
      percentageVol: 0 
    },
    dataFailed: {
      num: 0,
      percentage: 0,
      percentageVol: 0 
    }            
  });
  const [filtCampaign, setFiltCampaign] = useState([]);
  const [filtAll, setFiltAll] = useState([]);
  
  const [search, setSearch] = useState("");
  
  const { urlAPI, optPUT, optGET, urlAPI_1 } = useAPI();
  const [validation, setValidation] = useState(false);

  const token = localStorage.getItem("USER_TOKEN");
  const { id } = useParams();

  useEffect(() => {
    getCampaignSelected(id);
  }, []);

  const getCampaignSelected = async (id) => {
    try {
      setLoading(true);
      const url = urlAPI_1 + "getDataReport/" + id;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (result.type !== "success") {
        setLoading(false);
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      }

      const data = result.data;
      console.log("data: ", data);
      const all_messages = [
        ...data.contacts_sent,
        ...data.contacts_delivered,
        ...data.contacts_read,
        ...data.contacts_error,
      ];
      setFiltCampaign(all_messages);
      setFiltAll(all_messages);
    
      const campaign_data = {
        nameCampaign: data.campaign.description,
        nameListCampaign: data.list.name,
        numberUsers: data.campaign.results.total,
        creationDateCampaign: dayjs(data.campaign.created)
          .locale("es")
          .format("DD MMMM YYYY"),
        sendingDateCampaign: dayjs(data.campaign.dateProgram)
          .locale("es")
          .format("DD MMMM YYYY"),
        notMessages: all_messages.length,
        dataBar: {
          labels: ["Enviados", "Entregados", "Leídos", "Fallidos"],
          datasets: [
            {
              label: "Información de la campaña",
              data: [
                data.contacts_sent.length,
                data.contacts_delivered.length,
                data.contacts_read.length,
                data.contacts_error.length,
              ],
              backgroundColor: ["#667580", "#58b658", "#57bedd", "#f81a33"],
            },
          ],
        }
      };


      let allMessages = data.campaign.results.total;

      const computeAndSetPercentageData = (dataKey, dataValue) => {
        const percentage = (
          (dataValue.length * 100) /
          allMessages
        ).toFixed(2);
        const percentage_vol = (472 - 472 * (percentage / 100)).toFixed(0);
        campaign_data[dataKey] = {
          num: dataValue.length,
          percentage: percentage,
          percentageVol: percentage_vol,
        };
      };

      computeAndSetPercentageData("dataSent", data.contacts_sent);
      computeAndSetPercentageData("dataDelivered", data.contacts_delivered);
      computeAndSetPercentageData("dataRead", data.contacts_read);
      computeAndSetPercentageData("dataFailed", data.contacts_error);
      console.log(campaign_data);
      setCampaignData(() => {return campaign_data});
      setLoading(false);

    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  const onFilterAll = () => {
    setFiltCampaign(filtAll);
    setNavegationView("all");
    setCampaignData(() => {return { ...campaignData, notMessages: filtAll.length }});
  };

  const onFilter = (status) => {
    const resultFilter = filtAll.filter((element) => element.status === status);
    setFiltCampaign(resultFilter);
    setNavegationView(status);
    setCampaignData(() => {return { ...campaignData,  notMessages: resultFilter.length }});
    if (resultFilter.length <= 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const navigate = useNavigate();

  const searchQuery = (searchTerm) => {
    const searchResults = filtAll.filter((element) => {
      if (element.sendTo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ) {
        return element;
      }
    });
    setFiltCampaign(searchResults);
    if (searchResults.length <= 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  return (
    <Fragment>
      {loading && (
        <div className="content-spinner">
          <Spinner />
        </div>
      )}
      <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
        <div style={{ height: "45px", lineHeight: "95px" }}>
          <button
            type="button"
            className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex"
            onClick={() => {
              navigate(`/home/campaign`);
              setNavegationView("all");
            }}
          >
            <FaAngleLeft /> Campañas
          </button>
        </div>

        <Header />
      </div>
      <header className="filter-report d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <input type="hidden" id="idStore" size="40" value="1399" name="store" />
          <button className={`btn-filter btn btn-link btn-link-all d-flex ${navegationView === "all" ? "active" : ""}`} onClick={onFilterAll}>
            <FaListOl className="mr-1" /> Todos
          </button>
          <a className={`btn-filter btn btn-link d-flex ${navegationView === "sent" ? "active" : ""}`} onClick={() => onFilter("sent")}>
            <BsCheck className="mr-1" style={{ fontSize: "1.35rem", color: "#667580" }} /> Enviados
          </a>
          <a className={`btn-filter btn btn-link d-flex ${navegationView === "delivered" ? "active" : ""}`} onClick={() => onFilter("delivered")}>
            <BsCheckAll className="mr-1" style={{ fontSize: "1.35rem", color: "#58b658" }} /> Entregados
          </a>
          <a className={`btn-filter btn btn-link d-flex ${navegationView === "read" ? "active" : ""}`} onClick={() => onFilter("read")}>
            <BsCheckAll className="mr-1" style={{ fontSize: "1.35rem", color: "#57bedd" }} /> Leidos
          </a>
          <a className={`btn-filter btn btn-link d-flex ${navegationView === "error" ? "active" : ""}`} onClick={() => onFilter("error")}>
            <BsX className="mr-1" style={{ fontSize: "1.35rem", color: "#f81a33" }} /> Fallidos
          </a>
        </div>

        <div style={{ float: "right" }}>
          <form className="form-inline">
            <div className="su-control mt-1">
              <input
                className="form-control"
                type="text"
                placeholder="Búsqueda"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  searchQuery(e.target.value);
                }}
              />
              <button
                className="btn btn-info"
                type="button"
                id="buttonSearchProductBack"
                style={{ height: "34px" }}
              >
                <BsSearch />
              </button>
            </div>
          </form>
        </div>
      </header>

      <div 
        className="container-fluid mt-3 px-0"
        style={
          campaignData.notMessages === 0
            ? { height: "230px", overflow: "auto" }
            : campaignData.notMessages <= 4
            ? { height: "310px", overflow: "auto" }
            : { height: "460px", overflow: "auto" }
        }
      >
        <table className="table table-striped campaigns-report">
          <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
            <tr>
              <th>Enviado a</th>
              <th>Estado</th>
              <th>Fecha de creación</th>
              <th>Fecha de envío</th>
            </tr>
          </thead>
          <tbody>
            {filtCampaign.length ? (filtCampaign.map((campaign, k) => (
              <tr key={k}>
                <td>{campaign.sendTo}</td>
                <td className="status-campaign">
                  {
                    <p>
                      <a
                        href="javascript:;"
                        className={
                          campaign.status === "sent"
                            ? "gray"
                            : campaign.status === "delivered"
                            ? "green"
                            : campaign.status === "read"
                            ? "blue"
                            : "red"
                        }
                      >
                        {
                          campaign.status === "sent"
                            ? "Enviado"
                            : campaign.status === "delivered"
                            ? "Entregado"
                            : campaign.status === "read"
                            ? "Leido"
                            : "Fallido"
                        }
                      </a>
                    </p>
                  }
                </td>
                <td>
                  {dayjs(campaign.creationdate)
                    .locale("es")
                    .format("DD MMMM YYYY")}
                </td>
                <td>
                  {dayjs(campaign.sendingdate)
                    .locale("es")
                    .format("DD MMMM YYYY")}
                </td>
              </tr>
            ))
            ) : ("")}
          </tbody>
        </table>
        {validation && (
          <h1 className="text-center mt-3">Sin registros</h1>
        )}
      </div>

      <section className="module-create-user mt-4" style={{ width: "100%" }}>
        <header className="message text-center">
          <div id="tittleFormProduct">
            <h1>Progreso de la campaña</h1>
          </div>
        </header>

        <form className="Form-create-new-template abstract">
          <div className="mb-3 px-3">
            <h1>{campaignData.nameCampaign}</h1>
            <div className="v-card-text pt-0 pb-3 px-3">
              <div className="row d-flex">
                <div className="list-result mb-4 col-md-5 col-12">
                  <div className="list-item">
                    <div className="list-item__action">
                      <BiSolidHelpCircle className="icons-report" />
                    </div>
                    <div className="list-item__content">
                      <div className="list-item__title">Enviada</div>
                      <div className="list-item__subtitle">
                        Estado de la campaña
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="list-item__action">
                      <PiUsersThreeFill className="icons-report" />
                    </div>
                    <div className="list-item__content">
                      <div className="list-item__title">
                        {campaignData.numberUsers}
                      </div>
                      <div className="list-item__subtitle">
                        {campaignData.nameListCampaign}
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="list-item__action">
                      <FaComment className="icons-report" />
                    </div>
                    <div className="list-item__content">
                      <div className="list-item__title">
                        Mensajes enviados
                      </div>
                      <div className="list-item__subtitle">
                        {campaignData.dataSent.num +
                          campaignData.dataDelivered.num +
                          campaignData.dataRead.num}
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="list-item__action">
                      <FaCalendarCheck className="icons-report" />
                    </div>
                    <div className="list-item__content">
                      <div className="list-item__title">
                        {campaignData.creationDateCampaign}
                      </div>
                      <div className="list-item__subtitle">
                        Fecha de creación
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="list-item__action">
                      <IoMdSend className="icons-report" />
                    </div>
                    <div className="list-item__content">
                      <div className="list-item__title">
                        {campaignData.sendingDateCampaign}
                      </div>
                      <div className="list-item__subtitle">Fecha de envío</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-12">
                  <div className="canvas-bar">
                    <div>
                      <Bar data={campaignData.dataBar} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      <section style={{ display: "flex", gap: "30px" }} className="mb-3">
        <div
          data-v-09835e9f=""
          style={{ width: "33.333333%" }}
          className=" module-report-graphics box px-0"
        >
          <header className="message text-center">
            <div id="tittleFormProduct">
              <h1>Enviados</h1>
            </div>
          </header>
          <div
            data-v-09835e9f=""
            className="fill-height v-card v-sheet theme--light kbWhite"
          >
            <div className="v-card__text py-4 m-carrier">
              <div className="row ma-0 justify-content-center">
                <div className="text-center col col-12"></div>
                <div className="skill">
                  <div className="outer">
                    <div className="inner">
                      <p>{campaignData.dataSent.num}</p>
                      <div id="#por">
                        {campaignData.dataSent.percentage}%
                      </div>
                    </div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="160px"
                    height="160px"
                  >
                    <Circle
                      cx="80"
                      cy="80"
                      r="70"
                      stroke-linecap="round"
                      style={{
                        strokeDashoffset:
                          campaignData.dataSent.percentageVol,
                      }}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-v-09835e9f=""
          style={{ width: "33.333333%" }}
          className=" module-report-graphics box px-0"
        >
          <header className="message text-center">
            <div id="tittleFormProduct">
              <h1>Entregados</h1>
            </div>
          </header>
          <div
            data-v-09835e9f=""
            className="fill-height v-card v-sheet theme--light kbWhite"
          >
            <div className="v-card__text py-4 m-carrier">
              <div className="row ma-0 justify-content-center">
                <div className="text-center col col-12"></div>
                <div className="skill">
                  <div className="outer">
                    <div className="inner">
                      <p>{campaignData.dataDelivered.num}</p>
                      <div id="#por">
                        {campaignData.dataDelivered.percentage}%
                      </div>
                    </div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="160px"
                    height="160px"
                  >
                    <Circle1
                      cx="80"
                      cy="80"
                      r="70"
                      stroke-linecap="round"
                      style={{
                        strokeDashoffset:
                          campaignData.dataDelivered.percentageVol,
                      }}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-v-09835e9f=""
          style={{ width: "33.333333%" }}
          className=" module-report-graphics box px-0"
        >
          <header className="message text-center">
            <div id="tittleFormProduct">
              <h1>Leídos</h1>
            </div>
          </header>
          <div
            data-v-09835e9f=""
            className="fill-height v-card v-sheet theme--light kbWhite"
          >
            <div className="v-card__text py-4 m-send">
              <div className="row ma-0 justify-content-center">
                <div className="text-center col col-12"></div>
                <div className="skill">
                  <div className="outer">
                    <div className="inner">
                      <p>{campaignData.dataRead.num}</p>
                      <div id="#por">{campaignData.dataRead.percentage}%</div>
                    </div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="160px"
                    height="160px"
                  >
                    <Circle2
                      cx="80"
                      cy="80"
                      r="70"
                      stroke-linecap="round"
                      style={{
                        strokeDashoffset:
                          campaignData.dataRead.percentageVol,
                      }}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-v-09835e9f=""
          style={{ width: "33.333333%" }}
          className=" module-report-graphics box px-0"
        >
          <header className="message text-center">
            <div id="tittleFormProduct">
              <h1>Fallidos</h1>
            </div>
          </header>
          <div
            data-v-09835e9f=""
            className="fill-height v-card v-sheet theme--light kbWhite"
          >
            <div className="v-card__text py-4 m-send">
              <div className="row ma-0 justify-content-center">
                <div className="text-center col col-12"></div>
                <div className="skill">
                  <div className="outer">
                    <div className="inner">
                      <p>{campaignData.dataFailed.num}</p>
                      <div id="#por">
                        {campaignData.dataFailed.percentage}%
                      </div>
                    </div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="160px"
                    height="160px"
                  >
                    <Circle2
                      cx="80"
                      cy="80"
                      r="70"
                      stroke-linecap="round"
                      style={{
                        strokeDashoffset:
                          campaignData.dataFailed.percentageVol,
                      }}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default CampaignReport;


const Circle = styled.circle`
  fill: none;
  stroke-width: 20px;
  stroke-dasharray: 472;
  //  stroke-dashoffset: 0;
  stroke: #667580;
`;

const Circle1 = styled.circle`
  fill: none;
  stroke-width: 20px;
  stroke-dasharray: 472;
  //  stroke-dashoffset: 0;
  stroke: #58b658;
`;

const Circle2 = styled.circle`
  fill: none;
  stroke-width: 20px;
  stroke-dasharray: 472;
  stroke-dashoffset: 0;
  stroke: #57bedd;
`;

const Circle3 = styled.circle`
  fill: none;
  stroke-width: 20px;
  stroke-dasharray: 472;
  stroke-dashoffset: 0;
  stroke: #f81a33;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #1b70f7;
    font-size: 24px;
    margin-bottom: 15px;
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .content-template {
    width: 100%;
    margin: 0 auto;
  }

  .message {
    background: #e2e2e2;
    width: 90%;
    font-weight: 900;
    display: block;
    padding: 15px;
    margin: 0 auto;
  }

  input.btn-success,
  button.btn-danger {
    margin: 0 5px;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex-container label {
    font-size: 20px;
    font-weight: 900;
  }

  .flex-container input {
    padding: 5px;
    font-size: 22px;
    border-radius: 12px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
`;

const Navbar = styled.ul`
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 16px;
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  .number {
    background: #808080;
    margin-right: 5px;
    padding: 2.5px 10px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    transition: all 0.3s ease;
  }

  .number.active {
    background: #1b70f7;
  }

  .title {
    font-weight: 600;
  }
  .title.activ {
    font-size: 18px;
    font-weight: 900;
  }
`;

const Container = styled.div`
  margin-top: 1px;
  background: #fff;
  padding: 1rem;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid #ddd;

  .description {
    margin-bottom: 0px;
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .description h1 {
    color: #1b70f7;
    font-size: 25px;
    margin-bottom: 0;
  }

  .description .campaign-created {
    font-size: 30px !important;
  }

  .description p {
    font-size: 16px;
    display: block;
    width: 100%;
  }

  .description input,
  select {
    padding: 15px;
    width: 380px;
    font-size: 18px;
    border-radius: 12px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .description select {
    width: 500px;
    margin-top: 25px;
  }

  .import-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
  }

  .breaker {
    background: transparent;
    font-size: 24px;
    font-weight: 900;
    padding: 10px 40px;
    width: 100px;
    text-align: center;
  }

  .import {
    padding: 15px;
    font-weight: 900;
    font-size: 16px;
    width: 250px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
  }

  .program-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 50px;
  }

  .program-content label,
  .program-date label {
    font-size: 18px;
    font-weight: 900;
  }

  .program-content .checkbox {
    width: 30px;
    height: 24px;
  }

  .program-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .program-date .date-input {
    width: 21%;
    margin-right: 15px;
    padding: 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    border: 0;
    background: #0080ff;
    margin-bottom: 20px;
    color: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  }

  .date-input::-webkit-calendar-picker-indicator {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    .description input,
    select {
      padding: 15px;
      width: 80%;
      font-size: 18px;
    }

    .description select {
      width: 90%;
      margin-top: 60px;
    }

    .continue {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }

  .continue {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  #continue {
    background: transparent;
  }

  .continue label input:focus {
    background-color: transparent;
  }
  /*
  .continue button,
  .continue label,
  .continue span,
  .submit {
    padding: 10px 19px;
    font-weight: 900;
    font-size: 16px;
    // width: 250px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  }
*/

  .continue label .next {
    font-weight: 900;
    font-size: 16px;
    height: 24px;
  }

  .continue label .content {
    font-weight: 900;
    font-size: 16px;
    height: 24px;
    background-color: transparent;
    color: #e2e2e2;
  }

  .continue label:disabled,
  .submit:disabled {
    cursor: no-drop;
  }

  .continue .back-input {
    font-weight: 100;
    background: transparent;
    color: #fff !important;
  }

  .continue .back {
    color: #165bc4;
    background: white;
    border-color: white;
  }

  .continue .back.hidden {
    opacity: 0;
  }

  .continue .back:hover {
    background-color: #e2e2e2;
  }

  .continue .icon {
    font-size: 20px;
    margin: auto auto auto 5px;
  }
`;

const TypeCampaign = styled.div`
  .app-paper {
    width: 100%;
    padding: 0 0 10px;
    // border-top: 2px solid #999;
    // border-bottom: 2px solid #999;
  }

  .app-paper h2 {
    text-align: center;
    padding: 1px;
    font-size: 22px;
    font-weight: 900;
  }

  .hidebx {
    display: none;
  }

  .radio-content {
    width: 80%;
    margin: 0 auto;
    padding: 0 20px;
    gap: 50px;
  }

  @media (max-width: 900px) {
    .radio-content {
      gap: 20px;
    }
  }

  .lbl-radio {
    text-align: center;
    max-width: 140px;
    width: 100%;
    padding: 0 5px 5px;
    background-color: #d1d1d1;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .display-box .icon {
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
  }

  .display-box .icon.wsp {
    color: #1ad924;
  }

  .display-box .icon.sms {
    color: #1e52e3;
  }

  .display-box .type {
    font-weight: 900;
    font-size: 22px;
  }

  input[type="radio"]:checked + .lbl-radio {
    background: rgb(39, 38, 51, 0.9);
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  margin-top: 10px;

  @media (max-width: 990px) {
    width: 100%;
    text-align: center;
  }
`;

const InputContent = styled.div`
  background-color: #11c15b;
  border-radius: 12px;
  transition: all 0.3s ease;

  input[type="file"] {
    display: none;
    color: white;
  }

  label {
    color: white;
    height: 60px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    margin: auto;
    font-size: 20px;
    cursor: pointer;
  }

  &:hover {
    background: #1aeb72;
  }
`;

const ListContainer = styled.div`
  .comprobation {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
`;
{
  /* <div className="container-fluid mt-3 px-0" style={{ height: "460px", overflow: "auto"}}>
                <table className="table table-hover table-bordered">
                  <thead className="sticky-top bg-primary">
                    <tr key="none">
                      <th>Acciones</th>
                      <th>Enviado a</th>
                      <th>estado</th>
                      <th>Enviado</th>
                      <th>Recibido</th>
                      <th>Reboto</th>
                      <th>Fecha de creación</th>
                      <th>Fecha de envío</th>
                    </tr>
                  </thead>
                  <tbody> */
}
{
  /* {campaignSelected.map((campaign, k) => ( */
}
{
  /* {messageSend.map((campaign, k) => (
                        <tr key={k}>
                          <td>
                            <div className="row justify-content-around">
                              <div className="dropdown">
                                <button className="text-primary border-0 bg-transparent d-block pointer mt-2" 
                                  onClick={() => onView(campaign.id)}
                                >
                                <i className="fa fa-eye p-0 mr-2 f-18" style={{color: "#ff9317"}}></i>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>{campaign.sendTo}</td>
                          <td>{campaign.status === 'reboot' ? 'Rebotado' : campaign.status === 'delivered' ? 'Entregado' : 'En espera' }</td>
                          <td>{campaign.send ? 'Si' : 'No'}</td>
                          <td>{campaign.delivered ? 'Si' : 'No'}</td>
                          <td>{campaign.reboot ? 'Si' : 'No'}</td>
                          <td>{dayjs(campaign.creationdate).locale("es").format("DD MMMM YYYY")}</td>
                          <td>{dayjs(campaign.sendingdate).locale("es").format("DD MMMM YYYY")}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                {loading ? <Spinner /> : listCampaigns === '' ? <h1 className="text-center mt-3">No existen registros en el sistema</h1> : null}
              </div> */
}
