import React, { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const isiPhone = () => {
    return /iPhone/i.test(navigator.userAgent);
  };

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  if (!supportsPWA || isiPhone()) {
    return (
      <button
        className="btn btn-primary btn-lg"
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}
      >
        Install App &nbsp; &nbsp;
        <FaDownload />
      </button>
    );
  } else {
    return null;
  }
};

export default InstallPWA;
