import React from 'react';

const TextareaSendMessage = ({
  inputStr,
  inputRef,
  searchQuicklyAnswer,
  setContenMessage,
  setInputStr,
  setViewAnswer,
  setQuicklyAnswers,
  keyDown,
}) => {

  //Función para buscar respuestas rapidas
  const searchQuickReply = (text) => {
    if (text[0] == "/") {
      setViewAnswer(true)
      var searchAnswer = searchQuicklyAnswer.filter((element) => {
        if (element.name.toString().toLowerCase().includes(text.slice(1).toLowerCase())) {
          return element;
        }
      });
      setQuicklyAnswers(searchAnswer)

    } else {
      setViewAnswer(false)
    }
  };

  return (
    <div className="mt-0 su-control su-segmentation-disable d-flex align-items-center" style={{ width: "87%" }}>
      <textarea
        placeholder="Escribe aquí o pulsa / para respuestas rápidas"
        className="form-control px-1"
        name="name"
        id="myTextarea"
        value={inputStr}
        onKeyDown={keyDown}
        ref={inputRef}
        maxLength="4096"
        onChange={(e) => {
          setContenMessage(e.target.value);
          setInputStr(e.target.value);
          searchQuickReply(e.target.value);
        }}
      ></textarea>
      <span className={`total-characters ${inputStr.length == 4096 && 'total-red'} ml-2`}>{`${inputStr.length}/4096`}</span>
    </div>
  );
};

// position: absolute;
// width: 86%;
// bottom: 0px;
// max-height: 260px;
// min-height: 60px;

export default TextareaSendMessage;


// import React, { useRef, useEffect } from 'react';

// const TextareaSendMessage = ({
//   inputStr,
//   setInputStr,
//   searchQuicklyAnswer,
//   setViewAnswer,
//   setQuicklyAnswers,
//   keyDown,
// }) => {

//   const contentEditableRef = useRef(null);

//   // Función para buscar respuestas rápidas
//   const searchQuickReply = (text) => {
//     if (text[0] === "/") {
//       setViewAnswer(true);
//       const searchAnswer = searchQuicklyAnswer.filter((element) =>
//         element.name.toString().toLowerCase().includes(text.slice(1).toLowerCase())
//       );
//       setQuicklyAnswers(searchAnswer);
//     } else {
//       setViewAnswer(false);
//     }
//   };

//   // Ajustar la altura del contentEditable al contenido
//   const adjustHeight = () => {
//     if (contentEditableRef.current) {
//       contentEditableRef.current.style.height = 'auto';
//       contentEditableRef.current.style.height = `${contentEditableRef.current.scrollHeight}px`;
//     }
//   };

//   // Efecto para ajustar la altura inicial y en cambios de inputStr
//   useEffect(() => {
//     adjustHeight();
//   }, [inputStr]);

//   return (
//     <div className="mt-0 su-control su-segmentation-disable d-flex align-items-center" style={{ width: "87%" }}>
//       <div
//         contentEditable
//         ref={contentEditableRef}
//         onInput={(e) => {
//           const text = e.currentTarget.textContent;
//           setInputStr(text);
//           searchQuickReply(text);
//           adjustHeight();
//         }}
//         onKeyDown={keyDown}
//         className="form-control px-1"
//         style={{
//           minHeight: '20px',
//           maxHeight: '100px',
//           overflowY: 'auto',
//           padding: '10px',
//           border: '1px solid #ccc',
//           borderRadius: '4px',
//           whiteSpace: 'pre-wrap'
//         }}
//         placeholder="Escribe aquí o pulsa / para respuestas rápidas"
//       >
//         {inputStr}
//       </div>
//       <span className={`total-characters ${inputStr.length === 4096 ? 'total-red' : ''} ml-2`}>
//         {`${inputStr.length}/4096`}
//       </span>
//     </div>
//   );
// };

// export default TextareaSendMessage;