import React from 'react';
import { FaUser, FaUsers } from "react-icons/fa";
import { IoArrowRedoSharp  } from "react-icons/io5";

const ContactsOrForwardedMessage = ({
    message,
    contactList,
    setListContacts,
    setModalListContacts,
    textContacts,
}) => {
  return (
    <>
        {message.bodyType === "forwarded" && 
            <span className="d-flex align-items-center mb-2" style={{color:"#8696a0", fontSize:"13px", fontStyle:"italic"}}>
                <IoArrowRedoSharp className="mr-1" style={{fontSize: "17px"}} />
                Reenviado
            </span>
        }
        <div style={{width: "100%"}} className="content-img-chat d-flex align-items-center pr-2">
            <div className="ml-1" style={{border: "2px solid", borderRadius: "50%", backgroundColor: "#6a7175"}}>
                {contactList?.length > 1
                    ? <FaUsers style={{margin: "7px", fontSize: "2.2rem"}}/>
                    : <FaUser style={{margin: "7px", fontSize: "2.2rem"}}/>
                }
            </div>
            <div
                className="ml-3 pointer name-contacts"
                style={{fontWeight: "600", lineHeight: "1.3"}}
                onClick={() => {
                    setListContacts(contactList)
                    setModalListContacts(true)
                }}
            >
                {textContacts}
            </div>
        </div>
    </>
  );
};

export default ContactsOrForwardedMessage;