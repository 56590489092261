import React from 'react';
import ReactPlayer from "react-player";
import AutoLinkText from 'react-autolink-text2';
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { RiArrowDownCircleLine } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import { MdCancelScheduleSend } from "react-icons/md";
import useAPI from "../../../hooks/useAPI";
import Swal from "sweetalert2";

const TemplateMessage = ({
    message,
    formatHeader,
    headerText,
    videoSelected,
    onViewImage,
    setLoading,
    listMessages,
    setListMessages
}) => {
    const { urlAPI_1 } = useAPI();
    const token = localStorage.getItem("USER_TOKEN");

    // Funcion para elminar mensaje programado
    const removeMessage = async (id, event) => {
        event.preventDefault()
        try {
          Swal.fire({
            title: "¿Estas seguro que deseas eliminar este mensaje programado?",
            text: "Se borrara el mensaje de forma definitiva.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              setLoading(true);
              const url = urlAPI_1 + `scheduledMessage/${id}`;
              const response = await fetch(url, {
                method: "DELETE",
                mode: "cors",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              });
              const result = await response.json();
              console.log("Data Eliminated", result);
              let listMessagesNew = listMessages.filter((row) => {
                if(row._id != id){
                    return true
                }else{
                    return false;
                }
              })

              setListMessages((prevMessage) => { return listMessagesNew });
              setLoading(false);
    
              //# Confirmación de la eliminación
              Swal.fire({
                title: "¡Bien!",
                text: result.message,
                icon: result.type,
                confirmButtonText: "Aceptar",
              });
            }
          });
        } catch (error) {
          console.log(error);
        }
    };

  return (
    <div className="section-div3 send template">
        <div className="section-div4 text-left">
            
            {/* Scheduled message*/}
            {message.scheduled && (
                <div style={{float: 'right'}}>
                    <button
                        onClick={(event) => event.preventDefault()}
                        className="bg-transparent border-0"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <IoIosArrowDown style={{color: "#455A64", fontSize: "18px"}} className="pointer icon-options-message"/>
                    </button>
                    <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
                        <button className="text-danger border-0 bg-transparent d-block pointer mt-2" onClick={(event) => removeMessage(message._id, event)}>
                            <MdCancelScheduleSend style={{ fontSize: "18px" }}/>
                            <span className="text-dark ml-2 f-14">
                                Cancelar envío
                            </span>
                        </button>
                    </div>
                </div>
            )}

            <div className={formatHeader === "TEXT" ? "header-preview headerText" : videoSelected ? "video-selected" : "header-preview"}>
                {formatHeader && formatHeader === "TEXT" && headerText}
                {formatHeader && formatHeader === "IMAGE" ? (
                    <>
                        {message.bodyMedia ? (
                            <img
                                src={message.bodyMedia}
                                className="w-100 pointer"
                                style={{borderRadius: "5px"}}
                                onClick={() => onViewImage(message.messageId)}
                            />
                        ) : (
                            <div className="medio-image"></div>
                        )}
                    </>
                ) : formatHeader === "VIDEO" ? (
                    <>
                        {message.bodyMedia ? (
                            <ReactPlayer
                                url={message.bodyMedia}
                                loop
                                controls
                                width="100%"
                                height="100%"
                            />
                        ) : (
                            <div className="medio-video"></div>
                        )}
                    </>
                ) : formatHeader === "DOCUMENT" ? (
                    <>
                        {message.bodyMedia ? (
                        <>
                            <div 
                                className="px-2 py-3 d-flex align-items-center justify-content-between content-pdf-template"
                                style={message.originMessage === "user" ? {background:"#f0f0f0"} : {background:"#bef3a6"}}
                            >
                                <div className="d-flex align-items-center" style={{background:"transparent"}}>
                                    <BsFillFileEarmarkPdfFill style={{fontSize: "28px",color: "red",}}/>
                                    <span className="ml-2">
                                        {message.nameMedia && message.nameMedia.length >= 50
                                            ? message.nameMedia.substr(0, 50) + "..."
                                            : message.nameMedia
                                        }
                                    </span>
                                </div>
                                <a href={message.bodyMedia} target="_blank" download={message.nameMedia} rel="noreferrer">
                                    <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                                </a>
                            </div>
                        </>) : (
                        <div className="medio-document"></div>
                        )}
                    </>
                ) : formatHeader === "LOCATION" ? (
                    <>
                        <div className="medio-location"></div>
                    </>
                ) : null}
            </div>

            {formatHeader === "LOCATION" && (
                <div className="data-location">
                    <div className="location-name">{"{{Location name}}"}</div>
                    <div className="location-adress">{"{{Adress}}"}</div>
                </div>
            )}

            <div className="section-div5">
                <span className="span">
                    <span className="span message-text">
                        <AutoLinkText text={message.bodytext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
                    </span>
                </span>
            </div>

        </div>

    </div>
  );
};

export default TemplateMessage;