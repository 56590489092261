import React, { useReducer } from 'react';
import ListsReducer from './ListsReducer';
import ListsContex from './ListsContext';
// import axios from 'axios';

const ListsState = (props) => {

    const initialState = {
        // lists: [],
        selectedList: null,
        personsList: []
    }

    const [state, dispatch] = useReducer(ListsReducer, initialState);

    // const getLists = async () => {
    //     try {
    //         //const token = localStorage.getItem('token')
    //         const result = await fetch('https://api-notifications-dev.herokuapp.com/lists', {  
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2MjZiZTY4OTNhODljNjEzOGJkODNkZjEiLCJpYXQiOjE2NTEyMzk2OTUsImV4cCI6MTY1MjQ0OTI5NX0.N5V2lXzXaM7FVqBAuYc5tzalgkPN1hYVMAq07rs_2ew'
    //             },
    //             //body: JSON.stringify(docu)
    //         })
    //         const data = await result.json()
    //         const res = data.data;
    //         //console.log('result listas', data.data)
    //         dispatch({
    //             type: 'GET_LISTS',
    //             payload: res
    //         })
    //     } catch(error) {
    //         console.log('error on actualizar', error)
    //         return null
    //     }
    // }
    
    const getList = async id => {
        try {
            const result = await fetch('https://api-notifications-dev.herokuapp.com/lists/' + id, {  
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2MjZiZTY4OTNhODljNjEzOGJkODNkZjEiLCJpYXQiOjE2NTEyMzk2OTUsImV4cCI6MTY1MjQ0OTI5NX0.N5V2lXzXaM7FVqBAuYc5tzalgkPN1hYVMAq07rs_2ew'
                }
            })
            const data = await result.json()
            const res = data.data;
            console.log('result lista seleccionada', res)
            console.log('personas seleccionada', res.personBiId)
            dispatch({
                type: 'GET_LIST',
                payload: res,
                payload2: res.personBiId
            })
        } catch(error) {
            console.log('error on actualizar', error)
            return null
        }


        // const resp = await axios.get('https://api-notifications-dev.herokuapp.com/lists/' + id)
        // dispatch({
        //     type: 'GET_LIST',
        //     payload: resp.data.data,
        //     payload2: resp.data.data.personBiId
        // })
    }

    return ( 
        <ListsContex.Provider value={{
            lists: state.lists,
            selectedList: state.selectedList,
            personsList: state.personsList,
            //getLists,
            getList
        }}>
            {props.children}
        </ListsContex.Provider>
     );
}
 
export default ListsState;