import React from 'react';

const MessageSendTemplate = ({
  showContactInfo,
  setStartChat,
  setModalTemplateSend,
  setIsChatSelected,
  setNotChatSelected,
  setIsTemplateSend,
  setContent,
  setIsUrlDynamic,
  setIsUrlDynamic2,
  setIdTemplate,
  setNumberParams,
  setTemplateSelected,
  getIsDark,
}) => {
  return (
    <>
      <p 
        className={`p-class mb-0 text-center f-w-900 mx-1 ${showContactInfo && "isInfo"}`} 
        style={{
          fontSize: showContactInfo ? "0.8em" : "1.05em",
          width: showContactInfo ? "70%" : "80%",
          fontStyle: "italic"
        }}
      >
      La conversación ha finalizado debido a inactividad, conforme a las políticas de <a style={{color: "#5bc0de"}} href="https://developers.facebook.com/docs/messenger-platform/policy/policy-overview/" target="_blank" rel="noreferrer">Meta</a>. Puedes retomarla si recibes una respuesta o puedes enviar una plantilla para obtener una respuesta más rápida.
      </p>
      <input 
        className={`b-send btn btn-lg btn-info ${showContactInfo && "isInfo"}`}
        style={{
          padding: "9px 16px !important",
          width: showContactInfo ? "30%" : "20%"
        }}
        value="Enviar plantilla" 
        type="button"
        onClick={() => {
          getIsDark()
          setStartChat(true);
          setModalTemplateSend(false);
          setIsChatSelected(false);
          setNotChatSelected(false);
          setIsTemplateSend(true);
          setContent("");
          setIsUrlDynamic("");
          setIsUrlDynamic2("");
          setIdTemplate("");
          setNumberParams([]);
          setTemplateSelected([]);
        }}
      />
    </>
  );
};

export default MessageSendTemplate;