import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import styled from "styled-components";
import Modal from "../layout/Modal";
import Spinner from "../plugins/Spinner";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Pagination from "../plugins/Pagination";
import Avvvatars from "avvvatars-react";
import Header from "../layout/Header";
import fileExcel from "../../documents/plantilla.xlsx";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { FaAngleLeft, FaUserPlus, FaListUl } from "react-icons/fa";
import { FaCalendarDays, FaEllipsisVertical } from "react-icons/fa6";
import { BsSearch, BsTrash3, BsPencilFill, BsWhatsapp, BsFileEarmarkExcelFill, BsFillCheckCircleFill, BsFillInfoCircleFill, BsFillTelephoneFill, BsCheck } from "react-icons/bs";
import { PiUsersThree } from "react-icons/pi";
import ProgressBar from 'react-animated-progress-bar';

const token = localStorage.getItem("USER_TOKEN");

const Lists = () => {
  //# Estado de los archivos cargados
  const [excelFile, setExcelFile] = useState(null);
  const [nameList, setNameList] = useState("");

  //# Estados para los servicios
  const [persons, setPersons] = useState([]);
  const [lists, setLists] = useState([]);
  const [tablePersons, setTablePersons] = useState([]);
  const [list, setList] = useState({});
  //# Estados del modulo (Modales, Spinner, etc)
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("list");
  const [importLists, setImportLists] = useState(false);
  const [modeImport, setModeImport] = useState("createName");
  const [validation, setValidation] = useState(false);
  const [required, setRequired] = useState(false);
  const [personsChecked, setPersonsChecked] = useState([]);
  const [tableChecked, setTableChecked] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modalPersonsChecked, setModalPersonsChecked] = useState(false);
  const [searchModal, setSearchModal] = useState("");
  const [validationModal, setValidationModal] = useState(false);
  const [validationUsers, setValidationUsers] = useState(false);

  //# Estado para listar nombres de los campos nuevos como encabezado en la tabla
  const [FieldsNames, setFieldsNames] = useState([]);

  //# Barra de busqueda
  const [tableLists, setTableLists] = useState([]);
  const [search, setSearch] = useState("");

  const [registersPopup, setRegistersPopup] = useState(false);
  const [listRegister, setListRegister] = useState([]);

  const [formatPersonsChecked, setFormatPersonsChecked] = useState([]);

  //# Paginacion
  const { page, setPage, byPage, resetPage } = usePage();
  const { urlAPI, optGET, optPOST, optPUT, optDELETE, urlAPI_1, urlAPI_2 } = useAPI();

  //# React-hook-form para leer los datos y establecer las validaciones
  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({mode: "all",defaultValues: { name: "", description: "", persons: [] }});

  useEffect(() => {
    setFormatPersonsChecked((prev) => { return [...personsChecked].reverse() });
    let persons_checked = personsChecked.some(cliente => parseBoolean(cliente.userActive) === true);
    if (persons_checked) {
      setValidation((prev) => {return false});
    } else {
      setValidation((prev) => {return true});
    }
  }, [personsChecked]);

  useEffect(() => {
    getLists();
    getPersons();
  }, []);

  const getLists = async () => {
    console.log("listando...");
    try {
      setLoading(true);
      const url = urlAPI_1 + "lists";
      const response = await fetch(url, optGET());
      const result = await response.json();
      console.log("listas", result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      } else if (!result.data) {
        setValidation(true);
        setLists([]);
      } else {
        setValidation(false);
        setLists(result.data);
        setTableLists(result.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  const getPersons = async () => {
    try {
      // setLoading(true);
      const url = urlAPI_1 + `persons`;
      const response = await fetch(url, optGET());
      const result = await response.json();
      console.log(result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
        console.log("code 0");
      } else if (!result.data) {
        console.log("no hay data");
        setValidationModal(true);
        setValidationUsers(true);
        setPersons([]);
      } else {
        console.log("si hay lista");
        setPersons(result.data);
        setTablePersons(result.data);
        setFieldsNames(result.listFields);
        setValidationUsers(false);
        setValidationModal(false);
      }
      // setLoading(false);
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  const postList = async (inputs) => {
    try {
      setLoading(true);
      const values = {
        name: inputs.name,
        description: inputs.description,
        persons: inputs.persons.toString(),
      };
      const url = urlAPI_1 + "lists";
      const response = await fetch(url, optPOST(values));
      const result = await response.json();
      console.log("Data Send", result);
      setLoading(false);
      if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      }
      //# POST Completado, se reinician lo campos

      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      setMode("list");
      getLists();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteList = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar esta lista?",
        text: "No podrás tener acceso a su información.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_1 + `lists/${id}`;
          const response = await fetch(url, optDELETE());
          const result = await response.json();
          console.log("Data Eliminated", result);

          //# Actualización de la tabla
          getLists();
          setLoading(false);

          //# Confirmación de la eliminación
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const cleanInputs = () => {
    reset({ name: "", description: "", persons: "" });
  };

  //# Función que ayuda a cargar y validar la importación de archivos en formato Excel
  const uploadFile = (e) => {
    //# Definiendo el tipo de archivo permitido
    const fileType = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFile(null);
        Swal.fire({
          title: "¡Atención!",
          text: "Por favor elige el formato correcto.",
          icon: "info",
        });
      }
    }
  };

  const sendFile = () => {
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];

      //#--> Obtenemos las llaves del objeto que nos genera worksheet
      const allKeys = Object.keys(worksheet);
      
      //#--> Ordenamos alfabeticamente las llaves
      const sortKeys = allKeys.sort()
      
      //#--> Obtenemos el ultimo valor
      const lastValue = sortKeys[sortKeys.length - 1]

      //#--> Obtenemos solamente la letra del ultimo valor
      const letra = lastValue.replace(/\d/g, '');
      
      // const ultimaLetra = letra.charAt(letra.length - 1);
      
      //#--> Obtenemos solamente la letra del ultimo valor
      const numericalValue = letra.charCodeAt(0) - 'A'.charCodeAt(0) + 1;

      //#--> Obtener encabezados directamente de las columnas A a la ultima letra
      const headers = Array.from({ length: numericalValue }, (_, index) => String.fromCharCode(65 + index) + '1');
      console.log("headers=====>>", headers);

      //#--> Validar si algún encabezado está vacío
      const emptyHeaders = headers.filter(header => {
        const cellValue = worksheet[header] ? worksheet[header].v : null;
        return cellValue === undefined || cellValue === null || cellValue === '';
      });
      console.log("emptyHeaders=====>>", emptyHeaders);
      
      if (emptyHeaders.length > 0) {
        setImportLists(false)
        setExcelFile(null);
        //#--> Validamos si es mas de un encabezado para ajustar el mensaje
        let text = ""
        if (emptyHeaders.length > 1) {
          text =  `Los encabezados ${emptyHeaders.join(', ')} no pueden estar vacíos.`
        } else {
          text = `El encabezado ${emptyHeaders.join(', ')} no puede estar vacío.`
        }
        Swal.fire({
          title: "¡Atención!",
          text: text,
          icon: "error",
        });
      } else {
          //#--> Todos los encabezados están llenos
          const data = XLSX.utils.sheet_to_json(worksheet);
          validateAndImport(data);
          setExcelFile(null);
      }
    } else {
      Swal.fire({
        title: "¡Atención!",
        text: "No hay ningun archivo seleccionado, por favor selecciona el archivo a importar.",
        icon: "info",
      });
    }
  };

  const importList = async (array) => {
    console.log('-------Array de importList------', array);
    const values = {
      nameList,
      dataUsers: array,
    };
    console.log(values);
    let processCode = "";
    let total = values.dataUsers.length;
    try {
      setImportLists(false);
      setLoading(true);
      const url = urlAPI_1 + "persons/importList";
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      processCode = result.processCode
      setLoading(false);

      if (response.ok) {
        setNameList("");
        let successSwal = {};
        if(result.message == "Todos los registros contienen errores"){
          successSwal = {
            title: "La lista no pudo ser cargada",
            text: "No se pudo cargar ningún contacto de su lista",
            icon: "error",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'OK'
          };
        }else{
          successSwal = {
            title: result.message,
            text: "La carga de la lista está siendo procesada. Será notificado por mail cuando finalice.",
            icon: result.type,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'OK'
          };
        }
      
        if (result.contactosError && result.contactosError.length > 0) {
          total = total - result.contactosError.length;
          if(result.message == "Todos los registros contienen errores"){
            successSwal.text = 'No se pudo cargar ningún contacto de su lista. Puedes descargar el excel para ver los detalles.';
          }else{
            successSwal.text = 'Algunos contactos dieron error y puedes descargar el excel para ver los detalles. El resto está siendo procesado. Será notificado por mail cuando finalice..';
          }
          successSwal.showConfirmButton = true;
          successSwal.confirmButtonText = 'Descargar Errores';
          successSwal.preConfirm = () => {
            const errorWorkbook = XLSX.utils.book_new();
            const errorWorksheet = XLSX.utils.json_to_sheet(result.contactosError, { skipHeader: true });
            XLSX.utils.book_append_sheet(errorWorkbook, errorWorksheet, 'Errores');
            const errorWbout = XLSX.write(errorWorkbook, { bookType: 'xlsx', type: 'array' });
            const errorBlob = new Blob([errorWbout], { type: 'application/octet-stream' });
            const errorFileName = 'Errores_Importacion_Lista.xlsx';
      
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(errorBlob);
            a.href = url;
            a.download = errorFileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          };
        }
        Swal.fire(successSwal);
      } else {
        Swal.fire({
          title: "¡Atención!",
          text: result.message || "Hubo un error al procesar la solicitud.",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
      // Mostrar mensaje de error
      setLoading(false);
      Swal.fire({
        title: "¡Atención!",
        text: "Hubo un error al procesar la solicitud.",
        icon: "error",
      });
    }

  };

  const validateAndImport = async (array) => {
    console.log('------Recibiendo array-------', array);
    let isValid = true;
    const emptyCells = [];
    const emailRegex = /^\S+@\S+\.\S+/;
    const requiredColumns = ["Nombre", "Código de area", "Teléfono"];
  
    for (let i = 0; i < array.length; i++) {
      const data = array[i];
      const keywords = Object.keys(data);
      
      if (requiredColumns.every((column) => keywords.includes(column))) {
        let errorMessages = [];
        
        //#--> Validar formato de correo
        const correo = data["Correo"];
        if (correo) {
          if (!emailRegex.test(correo)) {
            errorMessages.push("Correo");
            isValid = false;
          }
        }

        //#--> Validar si Código de área es numérico
        const codigoArea = data["Código de area"];
        if (isNaN(codigoArea)) {
          errorMessages.push("Código de área");
          isValid = false;
        }

        //#--> Validar si Teléfono es numérico
        const telefono = data["Teléfono"];
        if (isNaN(telefono)) {
          errorMessages.push("Teléfono");
          isValid = false;
        }

        if (errorMessages.length > 0) {
          const msgFormat = errorMessages.length > 1 ? errorMessages .slice(0, -1).join(", ") + " y " + errorMessages[errorMessages.length - 1] : errorMessages.join(", ");
          emptyCells.push({
            linea: i + 1,
            callingCode: data["Código de area"],
            phone: data.Teléfono,
            columnas: `Formato incorrecto en: ${msgFormat}`,
          });
        }
        
      } else {
        //#--> Al menos uno de los campos requeridos no está presente en esta línea
        isValid = false;
  
        //#--> Buscar las columnas faltantes en la línea actual
        const missingColumns = requiredColumns.filter((column) => !keywords.includes(column));
        const text = missingColumns.length > 1 ? "Campos vaciós:" : "Campo vacio:" ;
        const msgFields = missingColumns.length > 1 ? missingColumns .slice(0, -1).join(", ") + " y " + missingColumns[missingColumns.length - 1] : missingColumns.join(", ");
        emptyCells.push({
          linea: i + 1,
          callingCode: data["Código de area"],
          phone: data.Teléfono,
          columnas: `${text} ${msgFields}`,
        });
      }
    }

    importList(array);
    setNameList("");
    setModeImport("createName");
    /*
    if (isValid) {
      importList(array);
      setNameList("");
      setModeImport("createName");
    } else {
      setImportLists(false);
      setListRegister(emptyCells)
      Swal.fire({
        title: "¡Atención!",
        icon: "error",
        html: `Por favor revise los siguientes registros <span id="verRegistro" class="border-0 pointer bg-transparent" style="color: #5bc0de">aquí</span> que no pueden cargarse a su base de datos`,
        showCancelButton: true,
        confirmButtonText: "Volver a cargar",
        cancelButtonText: `<span id="cancelButton" class="border-0 pointer bg-transparent" >Cancelar</span>`,
        preConfirm: () => {
          setImportLists(true);
        },
        didOpen: () => {
          const verRegistro = document.getElementById("verRegistro");
          verRegistro.addEventListener("click", () => {
            setRegistersPopup(true);
          });
      
          const cancelButton = document.getElementById("cancelButton");
          cancelButton.addEventListener("click", () => {
            setImportLists(false);
          });
        },
        willClose: () => {
          const verRegistro = document.getElementById("verRegistro");
          verRegistro.removeEventListener("click", () => {});
      
          const cancelButton = document.getElementById("cancelButton");
          cancelButton.removeEventListener("click", () => {});
        },
      });
    }
    */
  };

  //# Funcion que se encargar de filtrar por busqueda
  const searchQuery = (searchTerm) => {
    var searchResults = tableLists.filter((element) => {
      if (
        element.name.toString().toLowerCase().includes(searchTerm.toLowerCase()) 
      ) {
        return element;
      }
    });
    setLists(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const searchPersons = (searchTerm) => {
    var searchResults = tablePersons.filter((element) => {
      if (
        element.name
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        element.phone
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return element;
      }
    });
    setPersons(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const searchPersonsChecked = (searchTerm) => {
    var searchResults = tableChecked.filter((element) => {
      if (
        element.name.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        element.phone.toString().toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return element;
      }
    });
    setPersonsChecked(searchResults);
    if (searchResults.length === 0) {
      setValidationModal(true);
    } else {
      setValidationModal(false);
    }
  };

  const getList = async (id) => {
    try {
      setLoading(true);
      const url = urlAPI_1 + "lists/" + id;
      const response = await fetch(url, optGET());
      const result = await response.json();
      console.log(result);
      setList(result.data);
      const { name, description } = result.data;
      reset({ name, description });
      setLoading(false);
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  const getPersonsChecked = async (id) => {
    try {
      setLoadingModal(true);
      setLoading(true);
      const url = urlAPI_1 + `personsByList/${id}`;
      const response = await fetch(url, optGET());
      const result = await response.json();
      console.log("Personas chekeadas ------------->>", result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else {
        const listPersonsCheck = result.data;
        const clientsTrue = listPersonsCheck.filter(cliente => parseBoolean(cliente.userActive) === true);
        const clientsFalse = listPersonsCheck.filter(cliente => parseBoolean(cliente.userActive) === false);
        console.log('clientsTrue----->', clientsTrue);
        
        const orderedClients = [...clientsFalse, ...clientsTrue];

        setPersonsChecked((prev) => { return orderedClients });
        setTableChecked((prev) => { return orderedClients });
        setLoadingModal(false);
      }
      setLoading(false);
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  const addUsers = async (inputs) => {
    const id = list._id;
    const activePersonsIds = tableChecked
    .filter((person) => person.userActive === true)
    .map((activePerson) => activePerson._id);

    if(activePersonsIds.length == 0){
      Swal.fire({
        title: "¡Atención!",
        text: "Debes seleccionar al menos una persona",
        icon: "error"
      });
      return
    }

    const values = {
      name: inputs.name,
      description: inputs.description,
      persons: activePersonsIds.toString(),
    };
    try {
      setLoading(true);
      const url = urlAPI_1 + `lists/${id}`;
      const response = await fetch(url, optPUT(values));
      const result = await response.json();
      console.log("Data Updated", result);
      //# PUT Completado, se reinician lo campos
      if (result.code === 1) {
        getLists();
        setModalPersonsChecked(false);
        reset();
        setPersonsChecked([]);
        setLoading(false);
        //# Confirmacion del cambio
        Swal.fire({
          title: "¡Bien!",
          text: result.message,
          icon: result.type,
          confirmButtonText: "Aceptar",
        });
      } else {
        //# Alerta de error del cambio
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  const formatLists = [...lists].reverse();
  const formatPersons = [...persons].reverse();
  // const formatPersonsChecked = [...personsChecked].reverse();
  // console.log(JSON.stringify(watch(),null,5))

  //#--> Función para descargar plantilla en xlsx
  const downloadTable = () => {
    const table = document.getElementById('tablePersonsList');
    const worksheet = XLSX.utils.table_to_sheet(table);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Lista de contactos');
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const fileName = 'Plantilla sendingup.xlsx';

    // Descargar archivo XLSX
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const handlePersonCheck = (e, person) => {
    let new_person_states = personsChecked.map((row) => {
      if(row._id === person._id){
        row.userActive = parseBoolean(e.target.checked);
      }
      return row;
    })

    setPersonsChecked(new_person_states);

    let new_person_table_states = tableChecked.map((row) => {
      if(row._id === person._id){
        row.userActive = parseBoolean(e.target.checked);
      }
      return row;
    })

    setTableChecked(new_person_table_states);
  };

  function parseBoolean(value) {
    if (typeof value === 'boolean') {
      return value;
    } else if (typeof value === 'string') {
      const lowerCaseValue = value.toLowerCase();
      if (lowerCaseValue === 'true') {
        return true;
      } else if (lowerCaseValue === 'false') {
        return false;
      }
    }
    // Si no es ni booleano ni una cadena válida, devuelve false por defecto
    return false;
  }

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      {/*-------- popup - tabla registros con error ----------*/}
      {registersPopup &&
        <div className="image-preview-overlay">
          <div className="image-preview-container">
            <div className="records-not-loaded p-2" style={{width: "1000px", height: "auto", borderRadius: "7px"}}>
                <div className="p-3">
                  <h2 className="mb-3 d-flex justify-content-center" >
                    Registros no cargados!
                  </h2>
                  <div className="container-fluid mt-3 px-0" style={{ height: "580px", overflow: "auto" }}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Línea</th>
                          <th>Código de area</th>
                          <th>Contacto</th>
                          <th>Descripción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listRegister && listRegister.map((list, l) => (
                          <tr key={l}>
                            <td>{list.linea}</td>
                            <td>{list.callingCode}</td>
                            <td>{list.phone}</td>
                            <td>{list.columnas}</td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <a
                      href="javascript:;"
                      className="btn btn-lg btn-default mr-1"
                      onClick={() => setRegistersPopup(false)}
                      >
                      Cerrar
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      }


      {mode === "create" ? (
        <p></p>
      ) : (
        <>
          <div className="m-auto container-fluid p-0 h-100">
            <div className="col-xs-9 heading">
              <div>
                <h1>Listas</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Agregar Nueva"
                  onClick={() => {
                    navigate(`/home/list/new`);
                  }}
                />
                <button
                  id="inventory-button"
                  className="btn btn-default ml-1"
                  onClick={() => setImportLists(true)}
                >
                  Subir/Importar contactos
                </button>
              </div>

              <Header />
            </div>

            <header>
              <div style={{ float: "right" }}>
                <form className="form-inline">
                  <div className="su-control mt-1">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Búsqueda"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        searchQuery(e.target.value);
                        resetPage(1);
                      }}
                    />
                    <button
                      className="btn btn-info"
                      type="button"
                      id="buttonSearchProductBack"
                      style={{ height: "34px" }}
                    >
                      <BsSearch />
                    </button>
                  </div>
                </form>
              </div>
            </header>

            <div className="container-fluid mt-3 px-0" style={{ height: "75%", overflowY: "auto", overflowX: "none" }}>
              <table className="table table-striped">
                <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
                  <tr>
                    <th>Acciones</th>
                    <th>Nombre</th>
                    <th>N° Contactos</th>
                    <th>Fecha de creación</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {formatLists.length > 0 && formatLists.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((list) => (
                    <tr key={list._id}>
                      <td>
                        <div className="row justify-content-around">
                          <div className="dropdown">
                            <button
                              className="bg-transparent border-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <FaEllipsisVertical
                                className="fa fa-ellipsis-v text-success f-24 pointer"
                                aria-hidden="true"
                              />
                            </button>
                            <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
                              <button className="text-primary border-0 bg-transparent d-block pointer mt-2 w-100 text-left"
                                onClick={() => {
                                  navigate(`/home/list/${list._id}`);
                                }}
                              >
                                <BsPencilFill style={{ fontSize: "18px" }} />
                                <span className="text-dark ml-2 f-14">Editar</span>
                              </button>
                              <button className="text-danger border-0 bg-transparent d-block pointer mt-2 w-100 text-left" onClick={() => deleteList(list._id)}>
                                <BsTrash3 style={{ fontSize: "18px" }} />
                                <span className="text-dark ml-2 f-14">Eliminar</span>
                              </button>
                              <button
                                className="border-0 bg-transparent d-block pointer mt-2 w-100 text-left"
                                // onClick={() => {
                                //   getList(list._id);
                                //   getPersonsChecked(list._id);
                                //   setModalPersonsChecked(true);
                                //   setSearchModal("")
                                // }}
                                onClick={() => {
                                  navigate(`/home/list/${list._id}`);
                                }}
                              >
                                <FaUserPlus className="text-success" style={{ fontSize: "18px" }} />
                                <span className="text-dark f-14">
                                  {" "}
                                  Agregar contactos
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {list.name && list.name.length >= 25
                          ? list.name.substr(0, 25) + "..."
                          : list.name}
                      </td>
                      <td>{list.count}</td>
                      <td>
                        {dayjs(list.created)
                          .locale("es")
                          .format("DD MMMM YYYY")}
                      </td>
                      <td className="status-campaign">
                        <p>
                          <a href="javascript:;" className="green">
                            Activa
                          </a>
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {validation && (
                <div className="text-center mt-3">
                  <div className="empty">
                    <FaListUl style={{ fontSize: "55px" }} />
                    <h1>No tienes listas</h1>
                    <input
                      type="button"
                      className="btn btn-default"
                      value="Agregar Nueva"
                      style={{ fontFamily: "sans-serif" }}
                      onClick={() => {
                        /*
                        cleanInputs();
                        setMode("create");
                        setSearch("");
                        */
                        navigate(`/home/list/new`);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <footer>
              <div className="row">
                <div className="col-md-8"></div>

                <div className="col-md-4">
                  {/* Creación de la paginación simple */}
                  {mode === "list" && (
                    <Pagination
                      totalData={formatLists.length}
                      byPage={byPage}
                      setPage={setPage}
                    />
                  )}
                </div>
              </div>
            </footer>
          </div>
        </>
      )}

      <table
        className="table table-striped"
        id="tablePersonsList"
        style={{ display: "none" }}
      >
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Código de area</th>
            <th>Teléfono</th>
            {FieldsNames &&
              FieldsNames.map((item, k) => <th key={k}>{item}</th>)}
          </tr>
        </thead>
      </table>

      <Modal
        status={importLists}
        changeStatus={setImportLists}
        title="Subir/Importar listas"
        action={() => {
          setNameList("");
          setModeImport("createName");
        }}
      >
        <Content>
          <form
          // style={{width: "70%"}}
          >
            {modeImport === "createName" ? (
              <>
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Nombre de la lista: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={nameList}
                      onChange={(e) => setNameList(e.target.value)}
                      onSubmit={(e) => e.preventDefault()}
                    />
                  </div>
                  {required && (
                    <Alert2>* El nombre de la lista es requerido</Alert2>
                  )}
                  {/* {required && (<p className="text-danger mt-1 f-w-900">* El nombre de la lista es requerido</p>)} */}
                </div>
              </>
            ) : (
              <>
                <div className="mb-3">
                  <h5>
                    Descargue{" "}
                    <span className="border-0 pointer bg-transparent" style={{color: "#5bc0de"}} onClick={downloadTable}>aquí</span>
                    {" "}la plantilla en formato Excel.
                  </h5>
                </div>
                <InputContent>
                {excelFile ? (
                    <label htmlFor="file">
                      <BsFillCheckCircleFill style={{ color: "#5cb85c" }} className="mr-1"/>
                      Archivo cargado
                    </label>
                  ) : (
                    <label htmlFor="file">
                      <BsFileEarmarkExcelFill className="mr-1"/>
                      Selecciona un archivo
                    </label>
                  )}
                  <input
                    type="file"
                    id="file"
                    multiple
                    className="col-2-sm btn my-auto mb-1 "
                    onChange={uploadFile}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </InputContent>
              </>
            )}
          </form>

          <div className="row justify-content-end bd col-12 mt-4">
            {modeImport === "createName" ? (
              <>
                <a
                  href="javascript:;"
                  className="btn btn-lg btn-default mr-1"
                  onClick={() => {
                    setImportLists(false);
                    setModeImport("createName");
                    setNameList("");
                  }}
                >
                  Cerrar
                </a>
                <input
                  className="btn btn-lg btn-info"
                  value="Aceptar"
                  type="submit"
                  onClick={() => {
                    if (nameList === "") {
                      setRequired(true);
                    } else {
                      setModeImport("charge");
                      setRequired(false);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <a
                  href="javascript:;"
                  className="btn btn-lg btn-default mr-1"
                  onClick={() => {
                    setModeImport("createName");
                  }}
                >
                  Volver
                </a>
                <button
                  onClick={() => sendFile()}
                  className="btn btn-success btn-sm"
                >
                  Cargar archivo
                </button>
              </>
            )}
          </div>
        </Content>
      </Modal>

      <Modal
        status={modalPersonsChecked}
        changeStatus={setModalPersonsChecked}
        title={`Agregar contactos a ${list.name === undefined ? "" : list.name}`}
        action={() => {
          reset();
          setPersonsChecked([]);
          setList({});
        }}
      >
        <form className="form-inline justify-content-center mb-2 modal-addList">
          <div className="su-control mt-1">
            <input
              type="text"
              className="form-control"
              placeholder="Búsqueda"
              value={searchModal}
              onChange={(e) => {
                setSearchModal(e.target.value);
                searchPersonsChecked(e.target.value);
              }}
            />
            <button
              className="btn btn-info"
              type="button"
              id="buttonSearchProductBack"
              style={{ height: "34px" }}
            >
              <BsSearch />
            </button>
          </div>
        </form>
        {errors.dataSegmentCheck && (
          <p className="text-danger f-14 f-w-900">
            * {errors.dataSegmentCheck.message}
          </p>
        )}

        <form onSubmit={handleSubmit(addUsers)}>
          <ModalContent className="modal-add-users">
            {/* {loadingModal && <div className="m-auto w-90"><Spinner /></div>} */}
            {/* <ul className="o-auto bg-light d-flex flex-column justify-content-center align-items-center"> */}
            <ul className="o-auto d-flex flex-column justify-content-center align-items-center" style={{ padding: "0 0 5px" }}>
              {formatPersonsChecked.length > 0 && formatPersonsChecked.map((person) => {
                const initialWords = person.name.split(" ");
                const firstWord = initialWords[0];
                const secondWord = initialWords[1];
                let icon = "";
                if (secondWord === undefined) {
                  icon = firstWord[0];
                } else {
                  icon = firstWord[0] + secondWord;
                }

                return (
                  <li key={person._id} id="li-lists" className="bg-light b-primary m-t-5 p-3 row w-90">
                    <div className="col-8 d-flex align-items-center icon-avatar">
                      <Avvvatars value={icon} />
                      <span className="ml-3">{person.name}</span>
                    </div>
                      <input
                        type="checkbox"
                        value={parseBoolean(person.userActive)}
                        className="cmn-toggle cmn-toggle-round-flat"
                        name={person._id}
                        id={person.name}
                        defaultChecked={parseBoolean(person.userActive)}
                        onChange={(e) => {
                          handlePersonCheck(e, person)
                        }}
                      />
                    {/* <input
                      type="checkbox"
                      value={person._id}
                      className="cmn-toggle cmn-toggle-round-flat"
                      name={person.name}
                      id={person.name}
                      defaultChecked={person.userActive}
                      {...register("persons", {
                        required: {
                          value: true,
                          message: "Al menos una lista es obligatoria",
                        },
                      })}
                    /> */}
                    <label className="ml-4" htmlFor={person.name}></label>
                  </li>
                );
              })}
              {validationModal && (
                <div className="text-center mt-3">
                  <div className="empty">
                    <PiUsersThree style={{ fontSize: "55px" }}/>
                    <h1 style={{ fontSize: "30px" }}>No tienes contactos</h1>
                    <input
                      type="button"
                      className="btn btn-default"
                      value="Agregar Nuevo"
                      onClick={() => {
                        navigate("/home/users");
                      }}
                    />
                  </div>
                </div>
              )}
            </ul>
          </ModalContent>
          <div className="row justify-content-end mt-3 pt-3">
            <div className="col-sm-6 right">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => setModalPersonsChecked(false)}
              >
                Volver
              </a>
              <input
                className="btn btn-lg btn-info"
                value="Aceptar"
                type="submit"
              />
            </div>
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default Lists;

const Alert2 = styled.span`
  color: red;
  display: block;
  width: 100%;
`;

const Button = styled.button`
  /*width: 100px;
    padding: 15px;
    font-weight: 900;
    margin-bottom: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);*/
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  input.btn-success,
  button.btn-danger {
    margin: 0 5px;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex-container label {
    font-size: 20px;
    font-weight: 900;
  }

  .flex-container input {
    padding: 5px;
    font-size: 22px;
    border-radius: 15px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .small {
    font-size: 14px;
    margin-top: 4px;
    width: 100%;
  }

  h5 button {
    color: #5bc0de;
  }

  h5 button:hover {
    color: #28a1c5;
  }
`;

const InputContent = styled.div`
  color: #6d7d88;
  background-color: #fdfefe;
  border: 1px solid #dae2e4;
  -moz-box-shadow: 2px 2px 0px 0px #dae2e4;
  -webkit-box-shadow: 2px 2px 0px 0px #dae2e4;
  box-shadow: 2px 2px 0px 0px #dae2e4;

  transition: all 0.3s ease;
  border-radius: 12px;

  input[type="file"] {
    display: none;
    color: white;
  }

  label {
    // color: white;
    height: 60px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    margin: auto;
    font-size: 20px;
    cursor: pointer;
  }

  &:hover {
    color: #6d7d88;
    background-color: #e0e7e8;
    border-color: #b7c6ca;
    -moz-box-shadow: 2px 2px 0px 0px #b7c6ca;
    -webkit-box-shadow: 2px 2px 0px 0px #b7c6ca;
    box-shadow: 2px 2px 0px 0px #b7c6ca;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  margin-top: 10px;

  @media (max-width: 990px) {
    width: 100%;
    text-align: center;
  }
`;

const Container = styled.div`
  background: #fff;
  border-radius: 12px;
  border: 1px solid #ddd;
  padding-bottom: 20px;

  .left-content {
    width: 60%;
  }

  .left-content label {
    font-weight: 900;
    font-size: 16px;
  }

  .left-content input,
  .left-content select {
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px;
    border-radius: 12px;
    border: 1px solid #cccccc;
    width: 85%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    height: 45px;
  }

  .right-content {
    width: 90%;
    margin: 0 auto;
  }

  .right-content table {
    border: 1px solid #ddd;
  }

  .right-content .table-list-users {
    border-bottom: 1px solid #ddd;
    border-radius: 12px;
  }

  .form-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  @media (max-width: 900px) {
    .form-content {
      flex-direction: column;
    }

    .form-field {
      margin-top: 10px;
    }
  }

  .form-field {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 40px auto;
  }

  .form-field label {
    width: 100%;
    text-align: center;
  }

  .table-content {
    width: 95%;
    margin: 0 auto;
    height: 460px;
    overflow: auto;
  }

  .thead {
    font-weight: 900;
  }

  .checkbox {
    width: 30px;
    height: 30px;
    margin: 0 auto;
  }

  .td-check {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ModalContent = styled.div`
  overflow: auto;
  height: 335px;

  .checkbox {
    padding: 25px;
    width: 25px;
    height: 25px;
  }
`;
