import React, { Fragment } from "react";
import Header from "../layout/Header";
import logoLogin from "../../imgs/logo-login.png";
import styled from "styled-components";
import { FaSquareEnvelope, FaSquareFacebook, FaSquareInstagram } from "react-icons/fa6";

const PaymentSummary = () => {
  return (
    <Fragment>
      <div className="col-xs-9 heading">
        <Header />
      </div>
      <div
        className="container-fluid mt-3 px-0"
        style={{
          height: "592px",
          overflowY: "auto",
          display: "block",
          alignItems: "center",
        }}
      >
        <div className="text-center w-100">
          <img src={logoLogin} alt="Logo" style={{ width: "25%" }} />
        </div>


        <div className="text-center mt-3">
          <h1 className="mb-5">Transacción realizada con exito</h1>
          <div>
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="mr-1">Descripción: </h5>
              <Label>Pago servicio Sendingup</Label>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="mr-1">Referencia: </h5>
              <Label>Pendiente por confirmar</Label>
            </div>
            {/* <div className="d-flex justify-content-center align-items-center">
              <h5 className="mr-1">Valor: </h5>
              <Label>$12</Label>
            </div> */}
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="mr-1">Moneda: </h5>
              <Label>USD</Label>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="mr-1">Medio de pago: </h5>
              <Label>ePayco</Label>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3" style={{width: "70%", margin: "auto"}}>
              <Label>Se esta validando el proceso de pago con los bancos, esta operación puede tardar unos minutos, cuando el proceso termine lo veras reflejado en tu estado de cuenta en la sección Consumos y pagos.</Label>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3" style={{width: "70%", margin: "auto"}}>
              <Label className="font-weight-bold">Un email de confirmación sera enviado próximamente.</Label>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <Label>Si tienes alguna duda o inquietud, puedes contactarnos ingresando a cualquiera de los siguientes medios</Label>
            </div>
            <div className="d-flex -content-center">
              <div className="d-flex justify-content-center align-items-center mx-1">
                <Label>
                  <a className="d-flex align-items-center" target='_blank' href="mailto:info@sendingup.co" rel="noreferrer">
                    <FaSquareEnvelope style={{ marginRight: "5px" }} /> info@sendingup.co
                  </a>
                </Label>
              </div>
              <div className="d-flex justify-content-center align-items-center mx-1">
                <Label>
                  <a className="d-flex align-items-center" target='_blank' href="https://www.facebook.com/sendingup.co" rel="noreferrer">
                    <FaSquareFacebook className="icon-facebook-squared" style={{ fontSize: "18px", marginRight: "5px" }} /> sendingup.co
                  </a>
                </Label>
              </div>
              <div className="d-flex justify-content-center align-items-center mx-1">
                <Label>
                  <a className="d-flex align-items-center" target='_blank' href="https://www.instagram.com/sendingup.co/" rel="noreferrer">
                    <FaSquareInstagram className="icon-instagram" style={{ fontSize: "18px", marginRight: "5px" }} /> @sendingup.co
                  </a>
                  </Label>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {window.location.href = "/#/home/consumptionPayments";}}
            >
              Consumos y pagos
            </a>
          </div>
        </div>

      </div>

    </Fragment>
  );
};

export default PaymentSummary;

const Label = styled.label`
  font-size: 18px;
  font-weight: 100;
  a:hover {
    color: #5bc0de;
  } 
`;
