import React, { useState, useEffect, useRef } from "react";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import Spinner from "../plugins/Spinner";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { BsPencilFill, BsTrash3, BsPlusSquare } from "react-icons/bs";
import { LuCalendarClock } from "react-icons/lu";
import { TbCalendarCancel } from "react-icons/tb";
import styled from "styled-components";


const ChatClosures = () => {
  const [companie, setCompanie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatClosuresValue, setChatClosuresValue] = useState("");
  const [enabledClosures, setEnabledClosures] = useState([]);
  const enabledClosuresRef = useRef(false);
  const { urlAPI, optPOST, optGET, optPUT, optDELETE, urlAPI_2 } = useAPI();

  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({defaultValues: {}});

  useEffect(() => {
    getCompanieHours();
  }, []);

  useEffect(() => {
    console.log("enabledClosures changed: ", enabledClosures);
    enabledClosuresRef.current = enabledClosures;
  }, [enabledClosures]);

  const getCompanieHours = async () => {
    const resultIdCompany = localStorage.getItem("ID_COMPANY");
    setLoading(true);
    try {
      const url = urlAPI_2 + "companies/" + resultIdCompany;
      const response = await fetch(url, optGET());
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setCompanie([]);
      } else {
        setCompanie(result.data[0]);
        const {
          chatClosures
        } = result.data[0];
        
        setChatClosuresValue(chatClosures.value);
        setEnabledClosures(chatClosures.enabled);
        enabledClosuresRef.current = chatClosures.enabled;
        reset({
            closureValue: chatClosures.value,
            enabledClosures: chatClosures.enabled,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const putCompanyHours = async (inputs) => {
    const { closureValue, enabledClosures } = inputs;

    if(enabledClosuresRef.current && !closureValue){
      Swal.fire({
        title: "¡Atención!",
        text: "Debes seleccionar un horario.",
        icon: "info",
      });
      return;
    }
    const values_update = {
      chatClosures: {enabled: enabledClosuresRef.current, value: closureValue}
    };

    const id = companie._id;
    try {
      setLoading(true);
      const url = urlAPI_2 + "companies/" + id;
      const response = await fetch(url, optPUT(values_update));
      const result = await response.json();
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0">
        <div className="col-xs-9 heading">
          <div>
            <h1>Cierre automático de chats</h1>
          </div>

          <Header />
        </div>

        <div className="container-fluid mt-3 px-0 d-flex align-items-center" 
          // style={{ height: "670px", overflowY: "auto" }}
        >
          <section className="module-create-user myData" style={{ height: "auto", backgroundColor: "transparent", boxShadow: "none" }}>
            <div>
              <form onSubmit={handleSubmit(putCompanyHours)}>
                <div className="sections-my-data">
                  <div className="d-flex align-items-center">
                    <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                      <TbCalendarCancel style={{ fontSize: "25px", marginRight: "10px" }} />
                      Cierre automático de chats
                    </h2>
                    <input
                        name="messageCheck"
                        id="messageCheck"
                        type="checkbox"
                        value={enabledClosures}
                        className="cmn-toggle cmn-toggle-round-flat"
                        checked={enabledClosures}
                        {...register("enabledClosures")}
                        onChange={(e) => {setEnabledClosures(e.target.checked)}}
                    />
                    <label className="ml-4 mb-0" htmlFor="messageCheck"></label>
                  </div>
                  {enabledClosures &&
                    <div className="module-section border-top-0" id="product-basic">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row" style={{flexDirection: "column"}}>
                            <div className="col-md-4 my-0 mx-auto d-flex align-items-center">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
                                <label htmlFor="select">Horas posteriores a la última interacción: </label>
                                <select
                                  name="closureValue"
                                  id="closureValue"
                                  className="form-control"
                                  {...register("closureValue")}
                                  onChange={(e) => {setChatClosuresValue(e.target.value)}}
                                >
                                  <option value="" key="0" disabled selected>
                                    Seleccione...
                                  </option>
                                  <option value="24" key="24" >
                                    24hs.
                                  </option>
                                  <option value="48" key="48" >
                                    48hs.
                                  </option>
                                  <option value="72" key="72" >
                                    72hs.
                                  </option>                                
                                </select>
                              </div>

                              {chatClosuresValue &&
                                <div className="ml-2">
                                  <BsTrash3 className="text-danger pointer" style={{fontSize: "18px"}} onClick={() => {setChatClosuresValue(""); reset({closureValue: "", enabledClosures: false})}}/>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <footer className="mx-0">
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6 right">
                        <input
                          className="btn btn-lg btn-info"
                          value="Actualizar"
                          type="submit"
                        />
                      </div>
                    </div>
                  </footer>
                </div>

              </form>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ChatClosures;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 180px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  // .content-img .icon-eye {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .content-img img:hover {
    opacity: 0.6;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 250px;
`;