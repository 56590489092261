import React from 'react'
import ReactPaginate from 'react-paginate';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Pagination = ({ totalData, byPage, setPage, onLastPage = () => {} }) => {
  const max = totalData / byPage;
  const handlePageClick = ({ selected }) => {
    setPage(selected + 1);

    // Verifica si estamos en la última página y activa la función onLastPage
    if (selected === Math.ceil(max) - 1) {
      onLastPage();
    }
  };
  return (
    <ReactPaginate 
      previousLabel={<FaChevronLeft />} 
      nextLabel={<FaChevronRight />} 
      pageCount={Math.ceil(max)} 
      marginPagesDisplayed={1} 
      pageRangeDisplayed={3} 
      onPageChange={handlePageClick}
      containerClassName={'pagination'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName={'page-link'}
      breakLabel={'...'}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
    />
  )
}

export default Pagination
