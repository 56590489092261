import React from "react";
import { Handle, Position } from 'reactflow';
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

import { FiMessageSquare } from "react-icons/fi";
import { BsFillFileEarmarkFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkWordFill, BsFileEarmarkExcelFill } from "react-icons/bs";

const handleStyle = { left: 10 };


const CustomNode = ({ data, isConnectable }) => {
    let lastOptionsHeight = null
    if(data.options && data.options.length) {
        lastOptionsHeight = (20 * data.options.length) + 30
    }

    let type = null
    if(data.file && data.file.type) {
        type = data.file.type
    }
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            {!data.first && (
                <Handle type="target" position={Position.Top} isConnectable={isConnectable}  style={{backgroundColor: '#00A884'}}/>
            )}
            <div>
                <div 
                    className="d-flex align-items-center rounded-top" 
                    style={{height: "10px", backgroundColor: "#00A884", padding: "12px"}}
                >
                    <FiMessageSquare className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                    <span style={{fontSize: "10px", color: "#fff"}} >
                        {data.title ?? "Respuesta Básica"}
                    </span>
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(0, 168, 132, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    {data.bot_response && (
                        <div 
                            className="p-1" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Bot:</span>

                            {data.file.view && type && type.includes("image") && (
                                <img 
                                    src={data.file.view} 
                                    alt="Imagen" 
                                    className="w-100"
                                    style={{borderRadius: "4px"}}
                                /> 
                            )}
                            {data.file.view && type && type.includes("video") && (
                                <ReactPlayer
                                    url={data.file.view}
                                    loop
                                    controls
                                    width="100%"
                                    height="100%"
                                    style={{borderRadius: "4px"}}
                                />
                            )}
                            {data.file.view && type && type.includes("application") && (
                                <div>
                                    {type.includes("pdf") ? (
                                        <div className="d-flex align-items-center justify-content-between p-2" style={{background:"#f0f0f0", borderRadius: "4px"}}>
                                            <BsFillFileEarmarkPdfFill style={{fontSize: "18px",color: "red",}}/>
                                            <span 
                                                style={{
                                                    color: "#282828",
                                                    fontSize: "9px",
                                                    overflow: "hidden"
                                                }}
                                                className="ml-1"
                                            >
                                                {data.file.name}
                                            </span>
                                        </div>
                                    ) : type.includes("csv") || type.includes("xlsx") || type.includes("xls") ? (
                                        <div className="d-flex align-items-center justify-content-between p-2" style={{background:"#f0f0f0", borderRadius: "4px"}}>
                                            <BsFileEarmarkExcelFill style={{fontSize: "18px", color: "#00d591"}}/>
                                            <span 
                                                style={{
                                                    color: "#282828",
                                                    fontSize: "9px",
                                                    overflow: "hidden"
                                                }}
                                                className="ml-1"
                                            >
                                                {data.file.name}
                                            </span>
                                        </div>
                                    ) : type.includes("docx") || type.includes("doc") ? (
                                        <div className="d-flex align-items-center justify-content-between p-2" style={{background:"#f0f0f0", borderRadius: "4px"}}>
                                            <BsFillFileEarmarkWordFill style={{fontSize: "18px", color: "#31acf6"}}/>
                                            <span 
                                                style={{
                                                    color: "#282828",
                                                    fontSize: "9px",
                                                    overflow: "hidden"
                                                }}
                                                className="ml-1"
                                            >
                                                {data.file.name}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-between p-2" style={{background:"#f0f0f0", borderRadius: "4px"}}>
                                            <BsFillFileEarmarkFill style={{fontSize: "18px", color: "#5a5a5a"}} className="icon-file-earmark"/>
                                            <span 
                                                style={{
                                                    color: "#282828",
                                                    fontSize: "9px",
                                                    overflow: "hidden"
                                                }}
                                                className="ml-1"
                                            >
                                                {data.file.name}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                            {data.file.view && type && type.includes("audio") && (
                                <ReactAudioPlayer 
                                    src={data.file.view}
                                    controls
                                    style={{
                                        width: "100%",
                                        height: "20px"
                                    }}
                                    className="custom-audio-player"
                                />
                            )}
                            <p style={{fontWeight: 500, fontSize: "9px"}} className="m-0 mt-1">
                                {data.bot_response}
                            </p>
                        </div>
                    )}
                    {data.options && data.options.length > 0 && (
                        <div 
                            className="p-1 mt-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: lastOptionsHeight+"px"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Botones Interactivos</span>
                        </div>
                    )}
                </div>
            </div>
            <Handle 
                type="source" 
                position={Position.Bottom} 
                id="b" 
                isConnectable={isConnectable}  
                style={{backgroundColor: '#00A884'}}
            />
        </div>
    )
}

export default CustomNode;