import React from 'react';
import AutoLinkText from 'react-autolink-text2';
import { IoArrowRedoSharp  } from "react-icons/io5";

const LocationOrForwardedMessage = ({
    message,
    linkLocation,
    imgMap,
    nameLocation,
    linkName,
    adressLocation,
}) => {
  return (
    <>
        {message.bodyType === "forwarded" && 
            <span className="d-flex align-items-center mb-2" style={{color:"#8696a0", fontSize:"13px", fontStyle:"italic"}}>
                <IoArrowRedoSharp className="mr-1" style={{fontSize: "17px"}} />
                Reenviado
            </span>
        }
        <div style={{width: "275px"}} className="content-img-chat">
            <div>
                <a href={linkLocation} target="_blank" rel="noreferrer">
                    <img src={imgMap} className="w-100 pointer img-map" style={{borderRadius: "5px"}} />
                </a>
            </div>
            {nameLocation && 
                <div className="mt-2">
                    <a
                        href={linkName}
                        target="_blank"
                        rel="noreferrer"
                        className="view-location"
                        style={{color: "#00a5f4", fontSize: "1rem", display:"inline-grid"}}
                    >
                    <span>{nameLocation}</span>
                    {adressLocation != "undefined" &&
                        <span id="address-location" style={{color: "#5b5a5a", fontSize:"0.8em"}}>{adressLocation}</span>
                    }
                    </a>
                </div>
            }
        </div>
    </>
  );
};

export default LocationOrForwardedMessage;