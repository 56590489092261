import React from 'react';
import AutoLinkText from 'react-autolink-text2';
import { SiHomeassistantcommunitystore } from "react-icons/si";

const ContextProductMessage = ({
    message,
    bodyTextFlow,
    imgProduct,
    ProductInContext,
    nameProductContext,
    isSalePrice,
    salePrice,
    price,
    setModalViewProductContext,
    setProductContextSelected,
}) => {

     //#--> Visualizar producto en contexto
    const onViewProductContext = async (data) => {
        console.log('Recibiendo data el producto en constexto a ver:', data)
        setProductContextSelected(data)
        setModalViewProductContext(true)
    }
  return (
    <>
        <div className="">
            <div className="section-div4 text-left">
                <div className="header-preview mb-2">
                    <div
                        className="content-header-order d-flex pointer"
                        style={message.originMessage === "user" ? {background:"#f0f0f0"} : {background:"#bef3a6"}}
                        onClick={() => onViewProductContext(ProductInContext)}
                    >
                        <img src={imgProduct} alt="img-product" style={{height:"5rem"}} />
                        <div className="bg-transparent d-flex flex-column justify-content-center font-weight-bold">
                            <span className="d-flex align-items-center ml-2">
                                <SiHomeassistantcommunitystore style={{fontSize:"1.2rem", color: "#a5a7a8"}} className="span-color" />
                                <span className="span-color ml-1" style={{color: "#a5a7a8"}}>{nameProductContext}</span>
                            </span>
                            <p className="mb-0 mt-1 mx-2 font-weight-normal" style={{fontSize: "0.75rem"}}>
                            {isSalePrice ? 
                            <>
                                <span className="span-color" style={{color: "#a5a7a8"}}>
                                    {`${salePrice} ${ProductInContext?.currency}`}
                                </span>
                                <span className="span-color" style={{textDecoration: "line-through", color: "#a5a7a8"}}>
                                    {`${price} ${ProductInContext?.currency}`}
                                </span>
                            </>
                            :
                                <span className="span-color" style={{color: "#a5a7a8"}}>{`${price} ${ProductInContext?.currency}`}</span>
                            }
                            </p>
                        </div>
                    </div>
                </div>
                <span className="span message-text">
                    <AutoLinkText text={bodyTextFlow} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
                </span>
            </div>
        </div>
    </>
  );
};

export default ContextProductMessage;