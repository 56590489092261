import React from 'react';
import AutoLinkText from 'react-autolink-text2';
import { IoArrowRedoSharp  } from "react-icons/io5";

const ForwardedMessage = ({textForwarded}) => {
  return (
    <>
        <span className="d-flex align-items-center mb-2" style={{color:"#8696a0", fontSize:"13px", fontStyle:"italic"}}>
            <IoArrowRedoSharp className="mr-1" style={{fontSize: "17px"}} />
            Reenviado
        </span>
        <span className="span message-text">
            <AutoLinkText text={textForwarded} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
        </span>
    </>
  );
};

export default ForwardedMessage;