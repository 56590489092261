import React from 'react';
import { FaChevronDown, FaSpinner } from "react-icons/fa";

const LoadingMessages = ({
  loadingMessages,
  isIphone,
  focusLastMessage,
}) => {
  return (
    <div className="position-absolute" style={{ zIndex: "999", marginTop: "0.5rem", marginLeft: "1rem", left: "50%" }}>
      {loadingMessages ? (
        <span style={{ padding: "12px" }}>
          <FaSpinner
            className="fa-spin"
            style={{ fontSize: "25px" }}
          />
        </span>
      ) : (
        <span id="finall" className={`pointer d-flex ${isIphone ? '' : 'onClick'}`}
          {...(isIphone ? { onTouchEnd: () => focusLastMessage("template-3864") } : { onClick: () => focusLastMessage("template-3864") })}>
          <FaChevronDown style={{ fontSize: "1.2rem" }} />
        </span>
      )}
    </div>
  );
};

export default LoadingMessages;