import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import styled from "styled-components";
import Swal from "sweetalert2";
import Modal from "../layout/Modal";
import Spinner from "../plugins/Spinner";
import { useForm } from "react-hook-form";
import Pagination from "../plugins/Pagination";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import {faMagnifyingGlass,faChevronLeft,faChevronRight,} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../layout/Header";
import TemplateWhatsapp from "./TemplateWhatsapp";
import { BsSearch, BsPencilFill, BsTrash3, BsPlusSquareDotted, BsDashSquareDotted, BsWhatsapp } from "react-icons/bs";
import { BiListPlus } from "react-icons/bi";
import { FaEye, FaListUl, FaAngleLeft } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { AiFillWarning } from "react-icons/ai";
import { TbTemplate } from "react-icons/tb";

const Templates = () => {
  //# Estados del Modulo (Modales, Spinner, etc)
  const [editTemplate, setEditTemplate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [validation, setValidation] = useState(false);
  // const [modal, setModal] = useState(false);

  //# Estados para los servicios
  const [templates, setTemplates] = useState([]);
  const [tableTemplates, setTableTemplates] = useState([]);
  const [templateData, setTemplateData] = useState({});
  const [templateDataWsp, setTemplateDataWsp] = useState({});
  const [corresp, setCorresp] = useState([]);
  const [typeMessages, setTypeMessages] = useState([]);

  const [contentTemplate, setContentTemplate] = useState("");

  const [search, setSearch] = useState("");
  const [mode, setMode] = useState("list");

  //# Token para permisos de los servicios
  const token = localStorage.getItem("USER_TOKEN");

  //length del contenido para el tamaño del textarea
  const [contentLength, setContentLength] = useState("");
  const [personalize, setPersonalize] = useState(false);
  const [warningMessages, setWarningMessages] = useState(false);

  const { page, setPage, byPage, resetPage } = usePage();
  const { urlAPI, optGET, urlAPI_1, urlAPI_2 } = useAPI();

  const navigate = useNavigate();

  //# React-hook-form para establecer validaciones
  const {watch, register, handleSubmit, formState: { errors }, reset,} = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      type: "",
      content: "",
      // category: "",name: "",lenguages: "",lenguagesTemplate: [],headerType: "",headerText: "",media: "",body: "",footer: "",buttonType: "",fastAnswerText: "",fastAnswerText2: "",actionType: "",actionType2: "",bottonTextAction: "",bottonTextActionGoToWebsite: "",bottonTextAction2: "",country: "",country2: "",phoneNumber: "",phoneNumber2: "",urlType: "",urlType2: "",websiteUrl: "",websiteUrl2: "",isBottons: false,isHeader: ""
    },
  });

  useEffect(() => {
    getTemplates();
    // getCorresp();
    // getTypeMessages();
  }, []);

  //# Listar plantillas
  const getTemplates = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "templates";
      const response = await fetch(url, {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log("LISTA PLANTILLAS ==========", result);
      setLoading(false);
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      }  else if (!result.data) {
        setValidation(true);
        setTemplates([]);
      } else {
        setTemplates(result.data);
        setTableTemplates(result.data);
        setValidation(false);

      }
    } catch (error) {
      console.log(error);
    }
  };

  //# Sincronizar lista desde Facebook
  const getTemplatesFacebook = async () => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas sincronizar las plantillas?",
        text: "Se actualizara la lista.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aceptar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log('Confirmadooo....');
          setLoading(true);
          const url = urlAPI_2 + "syncTemplates";
          const response = await fetch(url, {
            mode: "cors",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          const result = await response.json();
          console.log("Resultado sincronización ==========>", result);
          setLoading(false);
          if (result.type === "error") {
            Swal.fire({
              title: "¡Atención!",
              text: result.message,
              icon: result.type,
            });
          } else if (result.type === "warning") {
            Swal.fire({
              title: result.message,
              text: "¿Desea ir a configurar el campo?",
              icon: result.type,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sí',
              cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/#/home/myData";
              }
            })
          }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
            Swal.fire({
              title: "¡Atención!",
              text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
              icon: 'info',
            });
            navigate('/');
          } else {
            getTemplates();
            Swal.fire({
              title: "¡Bien!",
              text: result.message,
              icon: result.type,
            });
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  };

  const getTypeMessages = async () => {
    try {
      setLoading(true);
      const url = urlAPI + "messagetypes";
      const response = await fetch(url, optGET());
      const result = await response.json();
      console.log("Response of the API", result);
      const typeId = result.data[0]._id;
      setTypeMessages(typeId);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //# Servicio GET
  const getTemplate = async (id) => {
    const url = urlAPI_2 + `templates/${id}`;
    const response = await fetch(url, {
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    console.log('Resul getTemplate====>', result)
    setTemplateData(result);
    setContentLength(result.data.content.length);

    const { name, type, content } = result.data;
    reset(
      {
        name,
        type,
        content,
      },
      { keepDefaultValues: true }
    );
  };

  const getTemplateWsp = async (id) => {
    const url = urlAPI + `templates/${id}`;
    const response = await fetch(url, {
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    setTemplateDataWsp(result);
    const res = result.data;

    // const { category,name,lenguages,lenguagesTemplate,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,bottonTextAction,bottonTextActionGoToWebsite,bottonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2,isBottons,isHeader } = result.data;

    console.log("DATA", result.data);

    // reset({ category,name,lenguages,lenguagesTemplate,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,bottonTextAction,bottonTextActionGoToWebsite,bottonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2,isBottons,isHeader});
  };

  //# Servicio POST para listar
  const postTemplate = async (values) => {
    const { name, type, content } = watch();
    const object = {
      name,
      type: typeMessages,
      content,
    };
    console.log("Objeto a guardar", object);
    try {
      setLoading(true);
      const url = urlAPI + "templates";
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(object),
        // body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log("Plantilla creada....", result);

      //# POST Completado, se reinician lo campos
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      getTemplates();
      setMode("list");
      reset();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    // console.log(values);
  };

  //# Servicio PUT para listar
  const putTemplate = async (values) => {
    const id = templateData.data._id;
    try {
      setLoading(true);
      const url = urlAPI + `templates/${id}`;
      const response = await fetch(url, {
        method: "PUT",
        mode: "cors",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      const result = await response.json();
      // console.log("Data Updated", result);

      //# PUT Completado, se reinician lo campos
      setLoading(false);
      getTemplates();
      reset();
      setModeEdit(false);
      setMode("list");

      //# Confirmacion del cambio
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };

  //# Servicio DELETE para listar
  const deleteTemplate = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar esta plantilla?",
        text: "No podrás tener acceso a su información.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI + `templates/${id}`;
          const response = await fetch(url, {
            method: "DELETE",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          const result = await response.json();
          console.log("Data Eliminated", result);

          //# Actualización de la tabla
          getTemplates();
          setLoading(false);

          //# Confirmación de la eliminación
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const cleanInputs = () => {
    reset({ name: "", type: "", content: "" });
  };

  const searchQuery = (searchTerm) => {
    var searchResults = tableTemplates.filter((element) => {
    const filter = element.content.filter(tem => {if (tem.type === "BODY") return tem.text})
    const newContent = filter[0].text;
    
    if (
      element.name
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.language
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.category
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      newContent
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.status
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    ) {
      return element;
    }
      
    });
    setTemplates(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const getCorresp = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "getDataEmail";
      const response = await fetch(url, {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log("Data list correspondencia ==>", result);
      setCorresp(result.data);
      setLoading(false);
      if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPersonalize = () => {
    if (personalize) {
      setPersonalize(false);
    } else {
      setPersonalize(true);
    }
  };

  const formatTemplates = [...templates].reverse();

  const handleChange = (event) => {
    const optionValue = event.target.value;
    const { name, type, content } = watch();
    const addText = content + " " + optionValue + " ";
    reset({ name, type, content: addText });
    setWarningMessages(true);
  };

  function parseBoolean(value) {
    if (typeof value === 'boolean') {
      return value;
    } else if (typeof value === 'string') {
      const lowerCaseValue = value.toLowerCase();
      if (lowerCaseValue === 'true') {
        return true;
      } else if (lowerCaseValue === 'false') {
        return false;
      }
    }
    // Si no es ni booleano ni una cadena válida, devuelve false por defecto
    return false;
  }

  const handleTemplateIsActive = async (e, template) => {
    try {
      setLoading(true);
      const url = urlAPI_2 + `syncTemplates/${template.id}`;
      const response = await fetch(url, {
        method: "PUT",
        mode: "cors",
        body: JSON.stringify({isActive:e.target.checked}),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      const result = await response.json();
      setLoading(false);
      
      //# Confirmacion del cambio
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log('Error al actualizar active de template:', error);
    }
  }

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      {/* {mode === "list" ? ( */}
        <>
          <div className="m-auto container-fluid p-0 h-100">
            <div className="col-xs-9 heading">
              <div className="mt-2">
                <h1>Plantillas</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Agregar nueva"
                  onClick={() => navigate('/home/templateWhatsapp') }
                />
                <input
                  type="button"
                  className="btn btn-default ml-1"
                  value="Sincronizar"
                  onClick={() => getTemplatesFacebook()}
                />
              </div>

              <Header />
            </div>

            <header>
              <div style={{ float: "right" }}>
                <form className="form-inline">
                  <div className="su-control mt-1">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Búsqueda"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        searchQuery(e.target.value);
                        resetPage();
                      }}
                    />
                    <button
                      className="btn btn-info"
                      type="button"
                      id="buttonSearchProductBack"
                      style={{ height: "34px" }}
                    >
                      <BsSearch />
                    </button>
                  </div>
                </form>
              </div>
            </header>

            <div className="container-fluid mt-3 px-0" style={{ height: "75%", overflowY: "auto", overflowX: "none" }}>
              <table className="table table-striped">
                <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
                  <tr>
                    <th>Acciones</th>
                    <th>Activar/Desactivar</th>
                    <th>Nombre</th>
                    <th>Contenido</th>
                    <th>Idioma</th>
                    <th>Categoría</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {formatTemplates.length > 0 && formatTemplates.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((template, k) => { 
                    const filter = template.content.filter(tem => {
                      if (tem.type === "BODY") return tem.text
                    })
                    const newContent = filter;
                    
                    return(
                    <tr key={k}>
                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="dropdown">
                            <button
                              // onClick={() => getTemplate(template._id)}
                              className="bg-transparent border-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <FaEllipsisVertical className="text-success f-24 pointer"/>
                            </button>
                            <div
                              className="dropdown-menu p-2"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                onClick={() => {
                                  navigate(`/home/templateWhatsappEdit/${template._id}`);
                                  setModeEdit(true);
                                }}
                              >
                                {/* <i
                                  className="icon-pencil"
                                  style={{ fontSize: "18px" }}
                                />
                                <span className="text-dark ml-2 f-14">
                                  Editar
                                </span> */}
                                <FaEye style={{fontSize: "18px",color: "#ff9317",}} />
                                <span className="text-dark ml-2 f-14">
                                  Ver
                                </span>
                              </button>
                              {/* <button
                                onClick={() => deleteTemplate(template.id)}
                                className="text-danger border-0 bg-transparent d-block pointer mt-2"
                              >
                                <i
                                  className="icon-trash-empty"
                                  style={{ fontSize: "18px" }}
                                />
                                <span className="text-dark ml-2 f-14">
                                  Eliminar
                                </span>
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="checkbox"
                            value={parseBoolean(template.isActive)}
                            className="cmn-toggle cmn-toggle-round-flat"
                            name={template.id}
                            id={template.id}
                            defaultChecked={parseBoolean(template.isActive)}
                            onChange={(e) => {
                              handleTemplateIsActive(e, template)
                            }}
                          />
                          <label className="m-0" htmlFor={template.id}></label>
                        </div>
                      </td>
                      <td>
                        {template.name.length >= 35
                          ? template.name.substr(0, 35) + "..."
                          : template.name}
                      </td>
                      <td>{
                        newContent[0].text.length >= 35 ? 
                          newContent[0].text.substr(0, 35) + "..."
                          : newContent[0].text}
                      </td>
                      <td>{template.language}</td>
                      <td>{template.category}</td>
                      <td className="status-template">
                        <p>
                          <a
                            href="javascript:;"
                            className={
                              template.status === "PENDIENTE"
                                ? "gray"
                                : template.status === "APROBADA"
                                ? "green"
                                : "red"
                            }
                          >
                            {template.status}
                          </a>
                        </p>
                      </td>
                    </tr>
                  )})}
                </tbody>
              </table>
              {validation && (
                <div className="text-center mt-3">
                  <div className="empty">
                    <TbTemplate style={{ fontSize: "55px" }} />
                    <h1>No tienes plantillas</h1>
                    <input
                      type="button"
                      className="btn btn-default"
                      value="Sincronizar"
                      onClick={() => getTemplatesFacebook()}
                    />
                  </div>
                </div>
              )}
            </div>

            <footer>
              <div className="row">
                <div className="col-md-8"></div>

                <div className="col-md-4">
                  {/* Creación de la paginación simple */}
                  <Pagination
                    totalData={formatTemplates.length}
                    byPage={byPage}
                    setPage={setPage}
                  />
                </div>
              </div>
            </footer>
          </div>
        </>
      {/* ) : null} */}
    </Fragment>
  );
};

export default Templates;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
  text-align: left;
`;
