import React from 'react';
import AutoLinkText from 'react-autolink-text2';
import ReactPlayer from "react-player";
import { IoArrowRedoSharp  } from "react-icons/io5";

const VideoOrForwardedMessage = ({message, textForwarded}) => {
  return (
    <>
        {message.bodyType === "forwarded" && 
            <span className="d-flex align-items-center mb-2" style={{color:"#8696a0", fontSize:"13px", fontStyle:"italic"}}>
                <IoArrowRedoSharp className="mr-1" style={{fontSize: "17px"}} />
                Reenviado
            </span>
        }
        <ReactPlayer
            className="content-video-chat"
            url={message.bodyMedia}
            loop
            controls
            width="395px"
            height="100%"
        />
        <span className="span message-text">
            <AutoLinkText text={message.bodyType === "forwarded" ? textForwarded : message.bodytext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
        </span>
    </>
  );
};

export default VideoOrForwardedMessage;