import React from 'react';
import logoLogin from "../../imgs/logo-login.png";
import logoDark from "../../imgs/Sending_logo_dark.png";

const NoChatSelected = ({showContactInfo}) => {
  return (
    <section
      className="module-chat chat mx-1 d-flex"
      style={
        showContactInfo
          ? { width: "45%", alignItems: "center" }
          : { width: "73%", alignItems: "center" }
      }
    >
      <div className="text-center w-100">
        <img src={logoDark} alt="Logo" style={{ width: "50%" }} className="logo-sendingup-dark" />
        <img src={logoLogin} alt="Logo" style={{ width: "50%" }} className="logo-sendingup" />
      </div>
    </section>
  );
};

export default NoChatSelected;