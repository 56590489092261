import React, { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import { IoMdDownload } from 'react-icons/io';

const ImagePreview = ({
  setIsViewImage,
  setViewImgAnswer,
  focusLastMessage,
  viewImgAnswer,
  viewSelectedImage,
  imgAnswer,
  scrollPosition,
  urlImgContext,
}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsViewImage(false);
        setViewImgAnswer(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setIsViewImage, setViewImgAnswer, focusLastMessage]);

  const urlImg = urlImgContext ? urlImgContext : viewSelectedImage[0].bodyMedia

  return (
    <div className="image-preview-overlay">
      <div className="image-preview-container" style={{maxWidth: "90%", maxHeight: "90%"}}>
        <div className="image-preview-header">
          <IoClose
            className="pointer icon-close"
            onClick={() => {
                setIsViewImage(false);
                setViewImgAnswer(false);
            }}
          />
          {!viewImgAnswer &&
            <a href={urlImg} className="pointer" target="_blank" download="sendingup-image.jpg" rel="noreferrer">
              <IoMdDownload style={{ cursor: "pointer", fontSize: "1.4em" }}/>
            </a>
          }
        </div>
        <img className="preview-image" src={viewImgAnswer ? imgAnswer : urlImg} alt="Preview" />
      </div>
    </div>
  );
};

export default ImagePreview;