import React from 'react';

const QuickAnswerList = ({
  quicklyAnswers,
  setIsMedia,
  setIsMediaAnswer,
  setIsMediaTypeAnswer,
  setTextDocument,
  setViewAnswer,
  setInputStr,
  setIdAnswerSelected,
  inputRef,
}) => {
  return (
    <div className="content-answers cont py-1 bg-white position-absolute" style={{ width:"50%", bottom:"2rem", left:"7rem", borderRadius:"12px", border:"1px solid #ccc" }}>
      <div className="px-3 list-quickly-answers" style={{ height: "176px", overflow: "auto" }}>
        {quicklyAnswers.length == 0 ? (
          <div className="text-center mt-3">
            <h3 className="text-center mt-3">Sin respuestas rápidas</h3>
          </div>
        ) : (
          <ul>
            {quicklyAnswers.map((quickly, q) => (
                <li
                  key={q}
                  id="quicklyAnswers"
                  className="pointer py-2 px-1 d-flex justify-content-between align-items-center"
                  style={{borderBottom:"1px solid rgb(0,0,0,.05)", fontSize:"0.9em"}}
                  onClick={() => {
                    if (quickly.media){
                      setIsMedia(true);
                      setIsMediaAnswer(quickly.media)
                      setIsMediaTypeAnswer(quickly.mediaType)
                      setTextDocument(quickly.content)
                      setViewAnswer(false);
                      setInputStr("")
                      setIdAnswerSelected(quickly._id)
                    } else {
                      setViewAnswer(false);
                      setInputStr(quickly.content)
                    }
                    if (inputRef.current) {
                      inputRef.current.focus();
                    }
                  }}
                >
                  <div style={{display: "grid"}}>
                    <strong>{quickly.name}</strong>
                    {quickly.content.length >= 70 ? quickly.content.substr(0, 70) + " ..." : quickly.content}
                  </div>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default QuickAnswerList;