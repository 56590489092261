import React from 'react';
import ReactAudioPlayer from "react-audio-player";
import { IoArrowRedoSharp  } from "react-icons/io5";

const AudioOrForwardedMessage = ({message}) => {
  return (
    <>
        {message.bodyType === "forwarded" && 
            <span className="d-flex align-items-center mb-2" style={{color:"#8696a0", fontSize:"13px", fontStyle:"italic"}}>
                <IoArrowRedoSharp className="mr-1" style={{fontSize: "17px"}} />
                Reenviado
            </span>
        }
        <a href={message.bodyMedia}>
            <div
                className="content-audio w-100 px-0 py-0 d-flex align-items-center justify-content-between pointer"
                style={message.originMessage === "user" ? {background: "#f0f0f0"} : {background: "#bef3a6"}}
            >
                <ReactAudioPlayer 
                    src={message.bodyMedia}
                    controls
                    className="custom-audio-player"
                />
            </div>
        </a>
    </>
  );
};

export default AudioOrForwardedMessage;