import React, { useState, useEffect } from "react";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import Spinner from "../plugins/Spinner";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { BsUmbrella } from "react-icons/bs";
import styled from "styled-components";
import Picker from "emoji-picker-react";

import { FaRegFaceLaugh } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";


const AbsentMessage = () => {
  const [companie, setCompanie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);

  const [messageCheck, setMessageCheck] = useState(false);
  const [absentMessage, setAbsentMessage] = useState("");
  const [typeSchedule, setTypeSchedule] = useState("");
  const [closeEmojis2, setCloseEmojis2] = useState(false);
  const [showPicker2, setShowPicker2] = useState(false);
  const [timeFields, setTimeFields] = useState([{ start: '', end: '' }])

  const { urlAPI, optPOST, optGET, optPUT, optDELETE, urlAPI_2 } = useAPI();

  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({});

  useEffect(() => {
    getCompanie();
  }, []);

  const getCompanie = async () => {
    const resultIdCompany = localStorage.getItem("ID_COMPANY");
    console.log("id compañia: ", resultIdCompany);
    try {
      setLoading(true);
      const url = urlAPI_2 + "companies/" + resultIdCompany;
      const response = await fetch(url, optGET());
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Bien!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setValidation(true);
        setCompanie([]);
      } else {
        setCompanie(result.data[0]);
        setValidation(false);
        const {
          absenceMessage,
          absenceMessageTime,
          isAbsenceMessage,
          customSchedule
        } = result.data[0];
        
        if(customSchedule.length && customSchedule[0].start){
          customSchedule[0].start = convertTime(customSchedule[0].start);
          customSchedule[0].end = convertTime(customSchedule[0].end);
        }
        
        console.log("isAbsenceMessage...............--->", isAbsenceMessage);
        console.log("customSchedule...............--->", customSchedule);
        console.log("absenceMessage...............--->", absenceMessage);
        console.log("absenceMessageTime...............--->", absenceMessageTime);
        setTimeFields(customSchedule);
        setMessageCheck(isAbsenceMessage);
        setAbsentMessage(absenceMessage);
        setTypeSchedule(absenceMessageTime);

        reset({
            messageCheck: isAbsenceMessage,
            message: absenceMessage,
            typeSchedule: absenceMessageTime
          },
          { keepDefaultValues: true }
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const putCompany = async (val) => {
    const {message, typeSchedule} = val;
    const valuess = {
      isAbsenceMessage: messageCheck,
      absenceMessage: message,
      absenceMessageTime: typeSchedule,
      customSchedule: timeFields,
    };
  
    if(valuess.customSchedule.length && valuess.customSchedule[0].start){
      valuess.customSchedule[0].start = convertToAmPm(valuess.customSchedule[0].start);
      valuess.customSchedule[0].end = convertToAmPm(valuess.customSchedule[0].end);
    }

    const id = companie._id;
    try {
      setLoading(true);
      const url = urlAPI_2 + "companies/" + id;
      const response = await fetch(url, optPUT(valuess));
      const result = await response.json();
      console.log("Result of the API: Put Commpany", result);
      Swal.fire({
        title: "¡Bien!",
        title: result.message,
        icon: result.type,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Evento para agregar emojis al mensaje
  const onEmojiClick2 = (event, emojiObject) => {
    setAbsentMessage((prevInput) => prevInput + emojiObject.emoji);
  };

  //#--> Manejar cambios en los campos
  const handleChange = (index, field, value) => {
    var newTimeFields = [...timeFields];
    newTimeFields[index][field] = value;
    setTimeFields(newTimeFields);
  };

  const convertToAmPm = (time) => {
    const [hours, minutes] = time.split(':');
    let formattedTime = '';
    if (hours < 12) {formattedTime = `${hours}:${minutes} am`;} 
    else if (hours === '12') {formattedTime = `${hours}:${minutes} pm`;}
    else {
      const formattedHours = (hours - 12) > 9 ? hours - 12 : `0${hours - 12}`;
      formattedTime = `${formattedHours}:${minutes} pm`;
    }
    return formattedTime;
  };

  const convertTime = (time) => {    
    const [hour, amPm] = time.split(' ');
    let formattedTime = '';
    if (amPm.toLowerCase() == "am") { 
      formattedTime = hour 
    }else {
      const [h, m] = hour.split(':');
      var fH = ""
      if (parseInt(h) === 12) {fH = 12}
      else {fH = (parseInt(h) + 12)}
      formattedTime = `${fH}:${m}`;
    }
    return formattedTime;
  }

  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0">
        <div className="col-xs-9 heading">
          <div>
            <h1>Mensaje de ausencia</h1>
          </div>
          <Header />
        </div>

        <div className="container-fluid mt-3 px-0 d-flex align-items-center" 
          // style={{ height: "670px", overflowY: "auto" }}
        >
          <section 
            className="module-create-user myData" 
            style={{ 
              height: "auto", 
              backgroundColor: "transparent", 
              boxShadow: "none",
              maxHeight: messageCheck ? "85vh" : "auto"
            }}
          >
            <div>
              <form onSubmit={handleSubmit(putCompany)}>
                <div className="sections-my-data">
                  <div className="d-flex align-items-center">
                    <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                      <BsUmbrella style={{ fontSize: "25px", marginRight: "10px" }} />
                      Mensaje de ausencia
                    </h2>
                    <input
                      name="messageCheck"
                      id="messageCheck"
                      type="checkbox"
                      value={messageCheck}
                      className="cmn-toggle cmn-toggle-round-flat"
                      // defaultChecked={messageCheck}
                      checked={messageCheck}
                      {...register("messageCheck")}
                      onChange={(e) => {setMessageCheck(e.target.checked)}}
                    />
                    <label className="ml-4 mb-0" htmlFor="messageCheck"></label>
                  </div>
                  
                  {messageCheck &&
                    <div className="module-section border-top-0 scrollable-content" id="product-basic">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                                <label>Mensaje: </label>
                                <div className="su-control-group">
                                  <textarea
                                    className="form-control"
                                    id="message"
                                    name="message"
                                    type="text"
                                    value={absentMessage}
                                    {...register("message", {
                                      required: {
                                        value: true,
                                        message:
                                          "El mensaje es obligatorio.",
                                      },
                                    })}
                                    onChange={(e) => setAbsentMessage(e.target.value)}
                                  ></textarea>

                                  <div className="d-flex align-items-center">
                                    {closeEmojis2 && (
                                      <IoClose
                                        className="icon-cancel ml-1 mr-2 d-flex pointer"
                                        style={{
                                          fontSize: "1.7rem",
                                          alignItems: "center",
                                        }}
                                        onClick={() => {
                                          setShowPicker2((val) => !val);
                                          setCloseEmojis2(false);
                                        }}
                                      />
                                    )}
                                    <FaRegFaceLaugh 
                                      className={
                                        showPicker2
                                          ? "icon-smile icon-chat active d-flex mt-1"
                                          : "icon-smile icon-chat d-flex mt-1"
                                      }
                                      style={{ fontSize: "25px", alignItems: "center" }}
                                      onClick={() => {
                                        setShowPicker2((val) => !val);
                                        if (closeEmojis2) {
                                          setCloseEmojis2(false);
                                        } else {
                                          setCloseEmojis2(true);
                                        }
                                      }}
                                    />

                                    {showPicker2 && (
                                      <Picker
                                        pickerStyle={{
                                          width: "100%",
                                          left: "0px",
                                          position: "absolute",
                                          zIndex: "999",
                                          top: "137px",
                                        }}
                                        onEmojiClick={onEmojiClick2}
                                        groupNames={{
                                          smileys_people: "Emoticones y personas",
                                          animals_nature: "Animales y naturaleza",
                                          food_drink: "Alimentos y bebidas",
                                          travel_places: "Viajes y lugares",
                                          activities: "Actividades",
                                          objects: "Objetos",
                                          symbols: "Símbolos",
                                          flags: "Banderas",
                                          recently_used: "Recientes",
                                        }}
                                      />
                                    )}
                                  </div>
                                  {errors.message && <Alert>* {errors.message.message}</Alert>}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                                <label>Horario: </label>
                                <div className="su-control-group">
                                  <select
                                    name="typeSchedule"
                                    id="typeSchedule"
                                    className="form-control"
                                    defaultValue="2"
                                    {...register("typeSchedule", {
                                      required: {
                                        value: true,
                                        message:
                                          "El horario es obligatorio.",
                                      },
                                    })}
                                    onChange={(e) => setTypeSchedule(e.target.value)}
                                  >
                                    <option value="" key="0" selected disabled>
                                      Seleccione...
                                    </option>
                                    <option value="1" key="1" >
                                      Enviar siempre
                                    </option>
                                    <option value="2" key="2" >
                                      Horario personalizado
                                    </option> 
                                    <option value="3" key="3" >
                                      Fuera del horario comercial
                                    </option>
                                  </select>
                                  {errors.typeSchedule && <Alert>* {errors.typeSchedule.message}</Alert>}
                                </div>
                              </div>
                            </div>
                            
                            {typeSchedule == "2" &&
                              timeFields.map((field, index) => (
                                <>
                                  <div className="col-md-6">
                                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                                      <label>Hora de inicio: </label>
                                      <input
                                        type="time"
                                        name="start"
                                        className="form-control pointer"
                                        defaultValue={field.start}
                                        {...register("start", {
                                          required: {
                                            value: true,
                                            message:
                                              "La hora de inicio es obligatoria.",
                                          },
                                        })}
                                        onChange={(e) => handleChange(index, 'start', e.target.value)}
                                      />
                                      {errors.start && <Alert>* {errors.start.message}</Alert>}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                                      <label>Hora de finalización: </label>
                                      <input
                                        type="time"
                                        name="end"
                                        className="form-control pointer"
                                        defaultValue={field.end}
                                        {...register("end", {
                                          required: {
                                            value: true,
                                            message:
                                            "La hora de finalización es obligatoria.",
                                          },
                                        })}
                                        onChange={(e) => handleChange(index, 'end', e.target.value)}
                                      />
                                      {errors.end && <Alert>* {errors.end.message}</Alert>}
                                    </div>
                                  </div>
                                </>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <footer className="mx-0">
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6 right">
                        <input
                          className="btn btn-lg btn-info"
                          // disabled={!messageCheck}
                          value="Actualizar"
                          type="submit"
                          />
                      </div>
                    </div>
                  </footer>
                </div>

              </form>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AbsentMessage;

const Alert = styled.span`
  color: red;
  display: block;
  width: 250px;
`;