import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import useAPI from "../../hooks/useAPI";
import Spinner from "../plugins/Spinner";
import logoLogin from "../../imgs/logo-login.png";

const Reset = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    watch,
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  const { token } = useParams();

  const { urlAPI, urlAPI_1, urlAPI_2 } = useAPI();

  const postReset = async (inputs) => {
    const password = inputs.password;
    const confirmPassword = inputs.confirmPassword;
    if (password === confirmPassword) {
      const values = {
        password,
        token,
      };
      try {
        setLoading(true);
        const url = urlAPI_2 + "updatePassword";
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        console.log("Data Send", result);

        setLoading(false);

        //# POST Completado, se reinician lo campos
        Swal.fire({
          title: "¡Bien!",
          text: result.message,
          icon: result.type,
          confirmButtonText: "Aceptar",
        });
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    } else {
      Swal.fire({
        title: "¡Atención!",
        text: "Las contraseñas no coinciden",
        icon: "warning",
      });
    }
  };

  return (
    <>
      <>
        {loading ? (
          <div className="content-spinner">
            <Spinner />
          </div>
        ) : null}
        <div id="main-login">
          <div className="container-fluid">
            <main id="login-register" className="loggin">
              <header>
                <h1>
                  <a href="#">
                    <img src={logoLogin} alt="Logo" />
                  </a>
                </h1>
              </header>
              <section className="box login">
                <header>
                  <h3>Reestablece tu contraseña</h3>
                </header>
                <div className="padd">
                  <form onSubmit={handleSubmit(postReset)}>
                    <label>Nueva contraseña</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                      placeholder="Nueva contraseña"
                      {...register("password", {
                        required: {
                          value: true,
                          message: "La contraseña es requerida.",
                        },
                      })}
                    />
                    {errors.password && (
                      <Alert>{errors.password.message}</Alert>
                    )}

                    <label>Contraseña</label>
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Confirmar contraseña"
                      {...register("confirmPassword", {
                        required: {
                          value: true,
                          message: "Es obligatorio confirmar la contraseña.",
                        },
                      })}
                    />

                    {errors.confirmPassword && (
                      <Alert>{errors.confirmPassword.message}</Alert>
                    )}

                    <button type="submit" className="btn btn-primary btn-lg">
                      Restaurar
                    </button>
                    <p style={{ fontSize: "14px" }}>
                      <a
                        href="javascript:;"
                        onClick={() => navigate("/")}
                        title="Recuperar la contraseña"
                        rel="nofollow"
                      >
                        Iniciar sesión
                      </a>
                    </p>
                  </form>
                </div>
              </section>
            </main>
          </div>
        </div>
      </>

      {/* <section className="intro">
        <div
          className="mask d-flex align-items-center gradient-custom"
          style={{ height: "100vh" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="my-auto col-lg-6">
                <div className="card custom-shadow">
                  <div className="card-body p-5 text-dark">
                    <div className="row d-flex justify-content-center">
                      <form className="col-10 text-center p-0" onSubmit={handleSubmit(postReset)}>
                        <h3 className="pb-2 f-w-900">Reestablece tu contraseña</h3>
                        <div className="form-outline mb-3">
                          <input
                            type="password"
                            id="password"
                            name="password"
                            className="form-control form-control-lg p-3 custom-shadow"
                            placeholder="Nueva contraseña"
                            {...register("password", {
                                required: {
                                  value: true,
                                  message: "La contraseña es requerida.",
                                },
                            })}
                          />
                        </div>
                        <div className="form-outline mb-3">
                          <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            className="form-control form-control-lg p-3 custom-shadow"
                            placeholder="Confirmar contraseña"
                            {...register("confirmPassword", {
                                required: {
                                  value: true,
                                  message: "Es obligatorio confirmar la contraseña.",
                                },
                            })}
                          />
                        </div>
                        <div className="row align-items-center col-12 m-auto p-0">
                          <Navigate onClick={() => navigate("/" )} className="h6 font-weight-bold text-primary col-sm-8 text-sm-left p-0 mb-4">
                            Iniciar sesión
                          </Navigate>
                          <input type="submit" className="btn bg-success col-sm-4 f-w-900" value="Restaurar" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Reset;

const Navigate = styled.p`
  cursor: pointer;
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;
