import React from 'react';
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import AutoLinkText from 'react-autolink-text2';
import { BsFillCameraVideoFill, BsFillFileEarmarkFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkWordFill, BsFileEarmarkExcelFill, BsFillFileEarmarkTextFill } from "react-icons/bs";
import { FaHeadphones, FaImage } from "react-icons/fa";
import { RiArrowDownCircleLine } from "react-icons/ri";
import { FaUser, FaUsers } from "react-icons/fa";

const ContextTextMessage = ({
    message,
    messageIdContext,
    messageContextType,
    showContactInfo,
    textImgContext,
    textContext,
    agentAsignMsj,
    typeResponseUser,
    contactList,
    textContacts,
    linkLocation,
    imgMap,
    nameLocation,
    linkName,
    adressLocation,
    ext,
    bodyTextContext,
    setListContacts,
    setModalListContacts,
    onViewMenssageContext,
    onViewImage,
}) => {
  return (
    <div className="">
        <div className="section-div4 text-left">
            <div className="header-preview mb-2">
                <div
                    className="content-header-order d-flex pointer"
                    style={message.originMessage === "user" ? {background:"#f0f0f0"} : {background:"#bef3a6"}}
                    onClick={() => onViewMenssageContext(messageIdContext, messageContextType, textContext)}
                >
                    {messageContextType == "image" || messageContextType == "imageText" ? (
                        <>
                            <div className={`bg-transparent d-flex flex-column justify-content-center font-weight-bold ${showContactInfo && "isShowContatctInfo"}`} style={{borderLeft: "4px solid #5bc0de"}}>
                                <span className="mx-2" style={{color: "#5bc0de"}}>{agentAsignMsj}</span>
                                <p className="mb-0 mt-1 mx-2 font-weight-normal d-flex" style={{fontSize: "0.75rem"}}>
                                    <FaImage className="mr-1" style={{fontSize: "16px"}} />
                                    <span className="span-color" style={{color: "#a5a7a8"}}>{textImgContext ? textImgContext : "Foto"}</span>
                                </p>
                            </div>
                            <img src={textContext} alt="img-product" style={{height:"3rem"}} />
                        </>
                    ): messageContextType == "video" || messageContextType == "videoText" ? (
                        <div className="bg-transparent d-flex flex-column justify-content-center font-weight-bold" style={{borderLeft: "4px solid #5bc0de"}}>
                            <span className="mx-2" style={{color: "#5bc0de"}}>{agentAsignMsj}</span>
                            <p className="mb-0 mt-1 mx-2 font-weight-normal d-flex" style={{fontSize: "0.75rem"}}>
                                {messageContextType == "videoText" || messageContextType == "video" ?
                                    <BsFillCameraVideoFill className="mr-1" style={{fontSize: "16px"}} />
                                : null}
                                <span className="span-color" style={{color: "#a5a7a8"}}>
                                    {textContext.length > 90 ? textContext.substr(0, 90) + "..." : textContext}
                                </span>
                            </p>
                        </div>
                    ): messageContextType == "audio" || messageContextType == "audioText" ? (
                        <div className="bg-transparent d-flex flex-column justify-content-center font-weight-bold" style={{borderLeft: "4px solid #5bc0de"}}>
                            <span className="mx-2" style={{color: "#5bc0de"}}>{agentAsignMsj}</span>
                            <p className="mb-0 mt-1 mx-2 font-weight-normal d-flex" style={{fontSize: "0.75rem"}}>
                                {messageContextType == "audioText" || messageContextType == "audio" ?
                                    <FaHeadphones className="mr-1" style={{fontSize: "16px"}} />
                                : null}
                                <span className="span-color" style={{color: "#a5a7a8"}}>
                                    {/* {textContext} */}
                                    {textContext.length > 90 ? textContext.substr(0, 90) + "..." : textContext}
                                </span>
                            </p>
                        </div>
                    ): (
                        <div className="bg-transparent d-flex flex-column justify-content-center font-weight-bold" style={{borderLeft: "4px solid #5bc0de"}}>
                            <span className="mx-2" style={{color: "#5bc0de"}}>{agentAsignMsj}</span>
                            <p className="mb-0 mt-1 mx-2 font-weight-normal d-flex" style={{fontSize: "0.75rem"}}>
                                {messageContextType == "documentText" || messageContextType == "document" ?
                                <BsFillFileEarmarkTextFill className="mr-1" style={{fontSize: "16px"}} />
                                : null}
                                <span className="span-color" style={{color: "#a5a7a8"}}>
                                    {textContext.length > 90 ? textContext.substr(0, 90) + "..." : textContext}
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            </div>

            {typeResponseUser == "image" || typeResponseUser == "sticker" ? (
                <div style={{width: typeResponseUser == "sticker" ? "200px" : "18rem"}} className="content-img-chat">
                    <img
                        src={message.bodyMedia}
                        className="w-100 pointer"
                        style={{borderRadius: "5px"}}
                        onClick={() => onViewImage(message.messageId)}
                    />
                </div>
            ): typeResponseUser == "video" ? (
                <ReactPlayer
                    className="content-video-chat"
                    url={message.bodyMedia}
                    loop
                    controls
                    width="395px"
                    height="100%"
                />
            ): typeResponseUser == "audio" ? (
                <a href={message.bodyMedia} className='holi'>
                    <div
                        className="content-audio w-100 px-0 py-0 d-flex align-items-center justify-content-between pointer"
                        style={{background: "#f0f0f0"}}
                    >
                        <ReactAudioPlayer
                            src={message.bodyMedia}
                            controls
                            className="custom-audio-player"
                        />
                    </div>
                </a>
            ): typeResponseUser == "contacts" ? (
                <div style={{width: "100%"}} className="content-img-chat d-flex align-items-center">
                    <div className="ml-1" style={{border: "2px solid", borderRadius: "50%", backgroundColor: "#6a7175"}}>
                        {contactList?.length > 1
                            ? <FaUsers style={{margin: "7px", fontSize: "2.2rem"}}/>
                            : <FaUser style={{margin: "7px", fontSize: "2.2rem"}}/>
                        }
                    </div>
                    <div
                        className="ml-3 pointer name-contacts"
                        style={{fontWeight: "600", lineHeight: "1.3"}}
                        onClick={() => {
                            setListContacts(contactList)
                            setModalListContacts(true)
                        }}
                    >
                        {textContacts}
                    </div>
                </div>
            ): typeResponseUser == "location" ? (
                <div style={{width: "275px"}} className="content-img-chat">
                    <div>
                        <a href={linkLocation} target="_blank" rel="noreferrer">
                            <img src={imgMap} className="w-100 pointer img-map" style={{borderRadius: "5px"}}/>
                        </a>
                    </div>
                    {nameLocation && 
                        <div className="mt-2">
                            <a
                                href={linkName}
                                target="_blank"
                                rel="noreferrer"
                                className="view-location"
                                style={{color: "#00a5f4", fontSize: "1rem", display:"inline-grid"}}
                            >
                                <span>{nameLocation}</span>
                                {adressLocation != "undefined" && <span style={{color: "#5b5a5a", fontSize:"0.8em"}}>{adressLocation}</span>}
                            </a>
                        </div>
                    }
                </div>
            ): typeResponseUser == "document" ? ( 
                <> 
                    {ext == "pdf" ? (
                        <div className="content-pdf">
                            <div className="doc-pdf px-2 py-3 d-flex align-items-center justify-content-between" style={{background:"#f0f0f0"}}>
                                <div className="d-flex align-items-center">
                                    <BsFillFileEarmarkPdfFill style={{fontSize: "28px",color: "red",}}/>
                                    <span className="name-doc ml-2">
                                        {(message.nameMedia && message.nameMedia.length >= 50)
                                            ? message.nameMedia.substr(0, 50) + "..."
                                            : message.nameMedia
                                        }
                                    </span>
                                </div>
                                <a href={message.bodyMedia} target="_blank" download={message.nameMedia} rel="noreferrer">
                                    <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                                </a>
                            </div>
                        </div>
                    ) : ext === "csv" || ext === "xlsx" || ext === "xls" ? (
                        <div>
                            <div className="content-excel px-2 py-3 d-flex align-items-center justify-content-between pointer" style={{background:"#f0f0f0"}}>
                                <div className="d-flex align-items-center">
                                    <BsFileEarmarkExcelFill style={{fontSize: "28px", color: "#00d591"}}/>
                                    <span className="name-doc ml-2">
                                        {(message.nameMedia && message.nameMedia.length >= 50)
                                            ? message.nameMedia.substr(0, 50) + "..."
                                            : message.nameMedia.length >= 35
                                            ?  message.nameMedia.substring(0, 13) + "......" + message.nameMedia.substring(message.nameMedia.length - 13)
                                            : message.nameMedia
                                        }
                                    </span>
                                </div>
                                <a href={message.bodyMedia} target="_blank" rel="noreferrer">
                                    <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                                </a>
                            </div>
                        </div>
                    ) : ext === "docx" || ext === "doc" ? (
                        <>
                            <a href="">
                                <div className="content-word px-2 py-3 d-flex align-items-center justify-content-between pointer" style={{background:"#f0f0f0"}}>
                                    <div className="d-flex align-items-center">
                                        <BsFillFileEarmarkWordFill style={{fontSize: "28px", color: "#31acf6"}}/>
                                        <span className="name-doc ml-2">
                                            {(message.nameMedia && message.nameMedia.length >= 50)
                                                ? message.nameMedia.substr(0, 50) + "..."
                                                : message.nameMedia.length >= 35
                                                ?  message.nameMedia.substring(0, 13) + "......" + message.nameMedia.substring(message.nameMedia.length - 13)
                                                : message.nameMedia
                                            }
                                        </span>
                                    </div>
                                    <a href={message.bodyMedia} target="_blank" rel="noreferrer">
                                        <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                                    </a>
                                </div>
                            </a>
                        </>
                    ) : (
                        <>
                            <a href="">
                                <div className="content-doc px-2 py-3 d-flex align-items-center justify-content-between pointer" style={{background: "#f0f0f0"}}>
                                    <div className="d-flex align-items-center">
                                        <BsFillFileEarmarkFill style={{ fontSize: "28px",color: "#5a5a5a"}} className="icon-file-earmark"/>
                                        <span className="name-doc ml-2">
                                            {(message.nameMedia && message.nameMedia.length >= 50)
                                                ? message.nameMedia.substr(0, 50) + "..."
                                                : message.nameMedia.length >= 35
                                                ?  message.nameMedia.substring(0, 13) + "......" + message.nameMedia.substring(message.nameMedia.length - 13)
                                                : message.nameMedia
                                            }
                                        </span>
                                    </div>
                                    <a href={ message.bodyMedia} target="_blank" rel="noreferrer">
                                        <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                                    </a>
                                </div>
                            </a>
                        </>
                    )}
                </>
            ): null}
        
            <span className="span message-text">
                <AutoLinkText text={bodyTextContext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
            </span>
        </div>
    </div>
  );
};

export default ContextTextMessage;