import React, { useState, useEffect } from "react";
import { Handle, Position } from 'reactflow';
import useAPI from "../../../../hooks/useAPI";

import { MdOutlineSupportAgent } from "react-icons/md";

const listAuto = [
    {
        id: 'agents',
        label: 'Angente',
    },
    {
        id: 'areas',
        label: 'Area',
    },
]

const TransferAgent = ({ data, isConnectable}) => {
    const { optGET, urlAPI_2 } = useAPI(); 
    const [listAreas, setListAreas] = useState([]);
    const [listAdvisers, setListAdvisers] = useState([]);

    const getAreas = async () => {
        try {
          const url = urlAPI_2 + "getAreasByCompany";
          const response = await fetch(url, optGET());
          const result = await response.json();
          console.log("Response GET AREAS---->", result);
          if (result.code === 0) {
            Swal.fire({
              title: "¡Atención!",
              text: result.message,
              icon: result.type,
            });
            setListAreas([])
          }else{
            setListAreas(result.data);
          }
        } catch (error) {
          console.log(error);
        }
    };
    const getAgents = async () => {
    try {
        const url = urlAPI_2 + "usersbyArea";
        const response = await fetch(url, optGET());
        const result = await response.json();
        console.log(result, 'res')
        if (result.code === 0) {
        Swal.fire({
            title: "¡Atención!",
            text: result.message,
            icon: result.type,
        });
        } else if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
            title: "¡Atención!",
            text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
            icon: "info",
        });
        navigate("/");
        } else if (result.data.length <= 0) {
        setListAdvisers([]);
        } else {
        setListAdvisers(result.data);
        }
    } catch (error) {
        console.log(error);
    }
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            await Promise.all([
              getAgents(),
              getAreas(),
            ]);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
      }, []);

    let agent_area = ""
    if(listAreas.length || listAdvisers.length) {
        const findArea = listAreas.find(v => v._id == data.agent_area)
        if(findArea) {
            agent_area = findArea.description
        } else {
            const findAgent = listAdvisers.find(v => v._id == data.agent_area)
            if(findAgent) {
                agent_area = findAgent.name
            } else {
                const findAuto = listAuto.find(v => v.id == data.agent_area)
                if(findAuto) {
                    agent_area = findAuto.label
                }
            }
        }
    }
    
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable}  style={{backgroundColor: '#F56F89'}}/>
            <div>
                <div 
                    className="d-flex align-items-center rounded-top" 
                    style={{height: "10px", backgroundColor: "#F56F89", padding: "12px"}}
                >
                    <MdOutlineSupportAgent className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                    <span style={{fontSize: "10px", color: "#fff"}} >
                        {data.title ?? "Transferir Conversación"}
                    </span>
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(245, 111, 137, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    {data.bot_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Bot:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.bot_response}
                            </p>
                        </div>
                    )}
                    {data.agent_area && (
                        <div 
                            className="p-1" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >    
                            <div>
                                <MdOutlineSupportAgent className="mr-1" style={{fontSize: "12px", color: "#000"}}/>
                                <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                    Agente / Area:
                                </span>
                            </div>
                            <p style={{fontWeight: 'bold', fontSize: "11px"}} className="m-0">
                                {agent_area}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Handle 
                type="source" 
                position={Position.Bottom} 
                id="b" 
                isConnectable={isConnectable}  
                style={{backgroundColor: '#F56F89'}}
            />
        </div>
    )
}

export default TransferAgent;