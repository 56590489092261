import React from 'react';
import AutoLinkText from 'react-autolink-text2';
import { BsFillFileEarmarkFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkWordFill, BsFileEarmarkExcelFill } from "react-icons/bs";
import { RiArrowDownCircleLine } from "react-icons/ri";

const InteractiveMessage = ({
    message,
    formatHeader,
    headerText,
    imgProduct,
    showContactInfo,
    numberProducts,
    ext,
    bodyTextFlow,
    onViewImage,
}) => {
  return (
    <div className="section-div3 send template">
        <div className="section-div4 text-left">
            <div className={formatHeader === "text" ? "header-preview headerText" : "header-preview"}>
                {formatHeader && formatHeader === "text" && (
                    <>
                        {imgProduct ?
                            <div className={`content-img-product d-flex ${showContactInfo && "isInfo"}`} style={message.originMessage === "user" ? {background:"#f0f0f0"} : {background:"#bef3a6"}}>
                                <img src={imgProduct} alt="img-product" style={{height: showContactInfo ? "3rem" : "5rem"}} />
                                <div className="bg-transparent d-flex flex-column justify-content-center" style={showContactInfo ? {minWidth:"120px"} : {}}>
                                    <span className="ml-2">{headerText}</span>
                                    <p className="mx-2 font-weight-normal">{numberProducts && numberProducts > 1 ? `${numberProducts} artículos` : `${numberProducts} artículo`}</p>
                                </div>
                            </div>
                        : headerText }
                    </>
                )}
                {formatHeader && formatHeader === "image" ? (
                    <>
                        {message.bodyMedia ? (
                            <img
                                src={message.bodyMedia}
                                className="w-100 pointer"
                                style={{borderRadius: "5px"}}
                                onClick={() => onViewImage(message.messageId)}
                            />
                        ) : (
                            <div className="medio-image"></div>
                        )}
                    </>
                ) : formatHeader === "document" ? (
                    <>
                        {message.bodyMedia ? (
                            <>
                                <div 
                                    className="px-2 py-3 d-flex align-items-center justify-content-between content-pdf-template"
                                    style={message.originMessage === "user" ? {background:"#f0f0f0"} : {background:"#bef3a6"}}
                                >
                                    <div className="d-flex align-items-center" style={{background:"transparent"}}>
                                        {ext === "pdf" ?
                                            <BsFillFileEarmarkPdfFill style={{fontSize: "28px",color: "red",}}/>
                                        : ext === "csv" || ext === "xlsx" || ext === "xls" ?
                                            <BsFileEarmarkExcelFill style={{fontSize: "28px", color: "#00d591"}}/>
                                        : ext === "docx" || ext === "doc" ?
                                            <BsFillFileEarmarkWordFill style={{fontSize: "28px", color: "#31acf6"}}/>
                                        :
                                            <BsFillFileEarmarkFill style={{ fontSize: "28px",color: "#5a5a5a"}} className="icon-file-earmark"/>
                                        }
                                        <span className="ml-2">
                                            {message.nameMedia && message.nameMedia.length >= 50
                                                ? message.nameMedia.substr(0, 50) + "..."
                                                : message.nameMedia
                                            }
                                        </span>
                                    </div>
                                    <a href={message.bodyMedia} target="_blank" download={message.nameMedia} rel="noreferrer">
                                        <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                                    </a>
                                </div>
                            </>
                        ) : (
                            <div className="medio-document"></div>
                        )}
                    </>
                ) : null}
            </div>

            <div className="section-div5">
                <span className="span">
                    <span className="span message-text">
                        <AutoLinkText text={bodyTextFlow} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
                    </span>
                </span>
            </div>
        </div>
    </div>
  );
};

export default InteractiveMessage;