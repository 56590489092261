import React from "react";
import { Handle, Position } from 'reactflow';

import { FaCheckCircle } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";

const CustomNode = ({ data, isConnectable }) => {
    let color = "#008000"
    if(data.id == 'failed') 
        color = "#FF0000"

    let Background = "rgba(0, 128, 0, 0.3)"
    if(data.id == 'failed') 
        Background = "rgba(255, 0, 0, 0.3)"
        
    return (
        <button
            type="button"
            style={{
                border: 0,
                borderRadius: "4px",
                color: color,
                backgroundColor: Background,
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "8px",
                fontWeight: "bold",
                height: "25px",
                width: "150px",
                zIndex: 100,
            }}
        >   
            <Handle 
                type="source" 
                position={Position.Right} 
                id="b" 
                isConnectable={isConnectable}  
                style={{backgroundColor: "#1D2491"}}
            />
            <div className="d-flex align-items-center justify-content-center">
                {data.id == 'succes' && (
                    <FaCheckCircle className="mr-1" style={{fontSize: "12px", color: color}}/>
                )}
                {data.id == 'failed' && (
                    <BiSolidError className="mr-1" style={{fontSize: "12px", color: color}}/>
                )}
                <span>
                    {data.label}
                </span>
            </div>
        </button>
    )
}

export default CustomNode;