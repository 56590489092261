import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import Modal from "../layout/Modal";
import Swal from "sweetalert2";
import Pagination from "../plugins/Pagination";
import Spinner from "../plugins/Spinner";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import ProgressBar from "react-animated-progress-bar";
import { useNavigate } from "react-router-dom";

import { FaEye, FaAngleLeft } from "react-icons/fa";

const ConsumptionPayments = () => {
  const [data, setData] = useState([]);
  const [dataInvoice, setDataInvoice] = useState([]);
  const [totalInvoice, setTotalInvoice] = useState("");
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [percentage, setPercentage] = useState("");
  const [curentData, setCurentData] = useState([]);
  const [curentDataValue, setCurentDataValue] = useState("");
  const [curentProxPy, setCurentProxPy] = useState("");

  const [viewDetails, setViewDetails] = useState(false);

  const [namePlan, setNamePlan] = useState("");
  const [currentTotal, setCurrentTotal] = useState("");
  const [date, setDate] = useState("");
  const [dayCreated, setDayCreated] = useState("");
  const [paymentDate, setPaymentDate] = useState("");

  const [paymentOptions, setPaymentOptions] = useState(false);
  const [routeQr, setRouteQr] = useState("");
  const [routeLink, setRouteLink] = useState("");

  //# Custoom Hooks
  const { page, byPage, resetPage, setPage } = usePage();
  const { urlAPI, optGET, optPOST, daysOverdue, urlAPI_1, urlAPI_2 } = useAPI();

  useEffect(() => {
    currentMonthDetails();
    // getInvoices();
  }, []);

  const token = localStorage.getItem("USER_TOKEN");
  const navigate = useNavigate();

  //#--> Recibimos la lista de facturas y detalles, tanto de meses anteriores como del actual
  const currentMonthDetails = async () => {
    try {
      setLoading(true);
      const url = urlAPI_1 + "getDataBilling";
      const response = await fetch(url, {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log("Lista de facturas...", result);
      setCurentData(result.currentData);
      setCurentDataValue(result.currentDataValue);
      setNamePlan(result.plan);
      setCurrentTotal(result.currentTotal);


      setCurentProxPy(result.proxPy);

      if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      }
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (result.data.length === 0) {
        setValidation(true);
        setData([]);
      } else {
        setData(result.data);
        setValidation(false);
        setDate(dayjs(new Date()).locale("es").format("DD"))
        // console.log('Dia de hoy=====>', dayjs(new Date()).locale("es").format("DD"))
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Cargar el detalle de la factura seleccionada
  const getInvoice = (id) => {
    setModal(false);
    setViewDetails(true);
    const invoiceSelected = data.filter((invoice) => invoice.id == id);
    console.log("Seleccioanda", invoiceSelected);
    setDataInvoice(invoiceSelected[0].detail);
    setTotalInvoice(invoiceSelected[0]);
    var dayCreate = (dayjs(invoiceSelected[0].created).locale("es").format("DD"))
    setDayCreated(parseInt(dayCreate) + daysOverdue);
    
    console.log('fecha normal=>', invoiceSelected[0].paymentDate);
    var date = new Date(invoiceSelected[0].paymentDate)
    // Restar 5 horas
    date.setHours(date.getHours() + 5);

    console.log('fecha mas 5=>', date);
    setPaymentDate(date)
  };

  //#--> Enviar información de la factura seleccionada para generar link de pago
  const postDataBill = async () => {
    console.log('totalInvoice::', totalInvoice);
    try {
      setLoading(true);
      const url = urlAPI_1 + "payBill";
      const response = await fetch(url, optPOST(totalInvoice));
      const result = await response.json();

      console.log("Response of the API: Post DATABILL=>", result);

      if (result.status === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.status,
        });
      } else {
        setPaymentOptions(true);
        const qr = result.data.data.routeQr;
        const link = result.data.data.routeLink;
        console.log('Link QR:', qr);
        setRouteQr(qr);
        setRouteLink(link);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formatBills = [...data].reverse();

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      {viewDetails ? (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                onClick={() => setViewDetails(false)}
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex align-items-center"
              >
                <FaAngleLeft className="mr-1" /> Consumos y pagos
              </button>
            </div>

            <Header />
          </div>

          <section className="module-order">
            <form>
              <div className="module-section">
                <div className="row">
                  <div className="col-sm-3 status-campaign">
                    <h1 id="numOrder">{`Detalles de la factura # ${totalInvoice.id}`}</h1>
                    <p className="mb-2" style={{fontSize: "1.3em", display:"grid"}}>
                      <h5 className="mr-2 mb-0">Periodo de facturación: </h5>
                      {totalInvoice.month}
                    </p>


                    {totalInvoice.paymentDate &&
                      <p className="mb-2 d-flex" style={{fontSize: "1.3em"}}>
                        <h5 className="mr-2 mb-0">Fecha de pago:</h5>
                          {
                            dayjs(totalInvoice.paymentDate).locale("es").format("DD MMMM YYYY")
                          }
                      </p>
                    }

                    {totalInvoice.paymentMethod &&
                      <p className="mb-2 d-flex" style={{fontSize: "1.3em"}}>
                        <h5 className="mr-2 mb-0">Método de pago:</h5>
                        {totalInvoice.paymentMethod}
                      </p>
                    }

                    {totalInvoice.paymentReference &&
                      <p className="mb-2 d-flex" style={{fontSize: "1.3em"}}>
                        <h5 className="mr-2 mb-0">Referencia de pago: </h5>
                        {totalInvoice.paymentReference}
                      </p>
                    }


                    <p>
                      <a href="javascript:;" className={totalInvoice.status ? "green" : date > dayCreated ? "red" : "gray" }>
                        {totalInvoice.status ? "Pagada" : date > dayCreated ? "Vencida" : "Pendiente" }
                      </a>
                    </p>

                  </div>

                  <div className="col-sm-9">
                    <div>
                      <div className="table-responsive">
                        <table className="table table-striped content-table-detail-invoice">
                          <thead>
                            <tr>
                              {/* <th>Código</th> */}
                              <th>Tipo</th>
                              <th>Descripción</th>
                              <th>Cantidad</th>
                              <th>Valor unitario</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody id="productsOrder">
                            {dataInvoice &&
                              dataInvoice.map((detail, k) => (
                                <tr key={k}>
                                  {/* <td>{detail.id}</td> */}
                                  <td>{detail.type}</td>
                                  <td>{detail.description}</td>
                                  <td>{detail.quantity}</td>
                                  <td>
                                    <pre>
                                      {/* $USD {Intl.NumberFormat().format(detail.subtotal)} */}
                                      $USD {detail.price}
                                    </pre>
                                  </td>
                                  <td>
                                    <pre>
                                      {/* $USD {Intl.NumberFormat().format(detail.total)} */}
                                      $USD {detail.total}
                                    </pre>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      <table className="total">
                        <tbody>
                          {/* <tr className="md">
                            <td>
                              <span>Subtotal:</span>
                            </td>
                            <td>
                              <pre>
                                {totalInvoice.total
                                  ? `$USD ${Intl.NumberFormat().format(
                                      totalInvoice.total
                                    )}`
                                  : "$USD 0"}
                              </pre>
                            </td>
                          </tr> */}
                          <tr className="lg">
                            <td>
                              <span
                                style={{ fontWeight: "bold", fontSize: "1.6em" }}
                              >
                                Total:
                              </span>
                            </td>
                            <td>
                              <pre style={{ fontSize: "1.6em" }}>
                                {totalInvoice.total
                                  ? `$USD ${Intl.NumberFormat().format(
                                      totalInvoice.total
                                    )}`
                                  : "$USD 0"}
                              </pre>
                            </td>
                          </tr>
                          {!totalInvoice.status &&
                            <tr>
                              <td></td>
                              <td>
                                  <div className="mt-3" style={{textAlign: "end"}}>
                                    <a
                                      href="javascript:;"
                                      className="btn btn-lg btn-info mr-1"
                                      onClick={postDataBill}
                                    >
                                      Pagar
                                    </a>
                                  </div>
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>

                    {/* <div>
                      {!totalInvoice.status &&
                        <div className="mt-3">
                          <a
                            href="javascript:;"
                            className="btn btn-lg btn-info mr-1"
                            onClick={postDataBill}
                          >
                            Pagar
                          </a>
                        </div>
                      }
                    </div> */}
                  </div>
                </div>
              </div>
            </form>
          </section>
        </>
      ) : (
        <>
          <div className="m-auto container-fluid p-0 h-100">
            <div className="col-xs-9 heading">
              <div>
                <h1>Consumos y pagos</h1>
              </div>
              <Header />
            </div>

            {!loading ? (
              <>
                <div className="content-payments" style={{height:"12%"}}>
                  <div>
                    <div>
                      <h5>Plan</h5>
                      <p>{namePlan}</p>
                    </div>
                  </div>
                  <div className="current-total" style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <h5>Total consumo a hoy</h5>
                      <p>$USD{" "}{currentTotal}</p>
                    </div>
                    {/* <ProgressBar width="230" trackWidth="13" defColor={{fair: 'yellow',good: 'orangered',excellent: 'red',poor: 'green',}} trackPathColor="#d1d1d1" percentage={percentage} /> */}
                  </div>
                  <div className="buttons">
                    <input
                      onClick={() => setDetails(true)}
                      className="btn btn-lg btn-info"
                      value="Detalles mes actual"
                      name="submit"
                      id="submit"
                      type="button"
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div className="container-fluid mt-3 px-0" style={{ height: "70%", overflow: "auto" }}>
              <table className="table table-striped invoiceList">
                <thead>
                  <tr>
                    <th>Detalle</th>
                    <th>Código</th>
                    <th>Periodo de facturación</th>
                    <th>Total</th>
                    <th>Fecha de corte</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {formatBills && formatBills.map((bill, d) => {
                      var dayBill = dayjs(bill.created).locale("es").format("DD")
                      var dayBillOverdue = parseInt(dayBill) + daysOverdue;
                      return (
                        <tr key={d}>
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="dropdown">
                                <button
                                  className="border-0 bg-transparent d-block pointer mt-2"
                                  onClick={() => getInvoice(bill.id)}
                                >
                                  <FaEye style={{ fontSize: "18px", color: "#ff9317" }} />
                                  <span className="text-dark ml-2 f-14"></span>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>{bill.id}</td>
                          <td>{bill.month}</td>
                          <td>
                            $USD{" "}
                            {Intl.NumberFormat("en-US", {
                              // style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                            }).format(bill.total)}
                          </td>
                          <td>
                            {dayjs(bill.created)
                              .locale("es")
                              .format("DD MMMM YYYY")}
                          </td>
                          <td className="status-campaign">
                            <p>
                              <a
                                href="javascript:;"
                                // className={date > dayBillOverdue ? "red" : bill.status ? "green" : "gray"}
                                className={bill.status ? "green" : date > dayBillOverdue ? "red" : "gray" }
                              >
                                {bill.status ? "Pagada" : date > dayBillOverdue ? "Vencida" : "Pendiente" }
                              </a>
                            </p>
                          </td>
                        </tr>
                      )}
                    )}
                </tbody>
              </table>
              {validation && (
                <h1 className="text-center mt-3">
                  No existen registros en el sistema
                </h1>
              )}
            </div>

            <footer>
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4">
                  <Pagination
                    totalData={data.length}
                    byPage={byPage}
                    setPage={setPage}
                  />
                </div>
              </div>
            </footer>
          </div>
        </>
      )}

      {/* Ver detalle del mes actual */}
      <Modal
        status={details}
        changeStatus={setDetails}
        title="Detalles mes actual"
        width={"1200px"}
      >
        <Content>
          <div className="justify-content-center w-100">
            <form>
              <div className="justify-content-center">
                <div className="content-table-current row mb-3">
                  <table className="table table-striped consumption">
                    <thead>
                      <tr>
                        <th>Tipo</th>
                        <th style={{ width: "auto" }}>Descripción</th>
                        <th>Cantidad</th>
                        <th>Precio unitario</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {curentData && curentData.map((data, a) => (
                        <tr key={a}>
                          <td>{data.type}</td>
                          <td style={{ width: "auto" }}>
                            {data.description}
                          </td>
                          <td>{data.quantity}</td>
                          <td>
                            {/* $USD {Intl.NumberFormat().format(data.price)} */}
                            $USD {data.price}
                          </td>
                          <td>
                            {/* $USD {Intl.NumberFormat().format(data.total)} */}
                            $USD {data.total}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot style={{ background: "#f5fafd" }}>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="td-current-total-text" colSpan="1" style={{ fontSize: "1.6rem" }}>
                          <strong>Total</strong>
                        </td>
                        <td className="td-current-total" style={{ fontSize: "1.4rem" }}>
                          <strong>
                            {currentTotal
                              ? `$USD ${Intl.NumberFormat().format(currentTotal)}`
                              : "$USD 0"}
                          </strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </Content>
      </Modal>

      {/* Ver opciones de pago */}
      <Modal
        status={paymentOptions}
        changeStatus={setPaymentOptions}
        title="Puedes pagar tu factura usando"
        width={"1200px"}
      >
        <Content2>
          <div className="text-center w-50">
              <div>
                <h3>Escanea este código QR</h3>
              </div>
              <div>
                <img src={routeQr} alt="QR"/>
              </div>
          </div>
          <div className="text-center w-50">
              <div>
                <h3>Ir a la pasarela de pagos</h3>
              </div>
              <div>
                <a href={routeLink} target="_blank" onClick={() => setPaymentOptions(false)} rel="noreferrer">
                  <img className="img-pay" src="/images/Icono-pagos.png" alt="QR" style={{width: "65%"}}/>
                </a>
              </div>
          </div>
        </Content2>
        <div className="row justify-content-end mt-3 pt-3">
          <div className="col-sm-12 text-center">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => setPaymentOptions(false)}
            >
              Volver
            </a>
          </div>
        </div>
      </Modal>

      <Modal
        status={modal}
        changeStatus={setModal}
        title={`Detalles de la factura # ${totalInvoice.id}`}
        width={"1200px"}
      >
        <Content>
          <div className="justify-content-center w-100">
            <form>
              <div className="justify-content-center">
                <div className="row mb-3">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Tipo</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Sub total</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataInvoice &&
                        dataInvoice.map((detail, l) => (
                          <tr key={l}>
                            <td>{detail.id}</td>
                            <td>{detail.type}</td>
                            <td>{detail.description}</td>
                            <td>{detail.quantity}</td>
                            <td>
                              {/* $USD {Intl.NumberFormat().format(detail.subtotal)} */}
                              $USD {detail.subtotal}
                            </td>
                            <td>
                              $USD {Intl.NumberFormat().format(detail.total)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot style={{ background: "#f5fafd" }}>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="2">
                          <strong>Total</strong>
                        </td>
                        <td>
                          <strong>
                            {totalInvoice.total
                              ? `$USD ${Intl.NumberFormat().format(
                                  totalInvoice.total
                                )}`
                              : "$USD 0"}
                          </strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  {/* {loading ? <Spinner /> : validation && <h1 className="text-center mt-3">No existen registros en el sistema</h1>} */}
                </div>
              </div>
            </form>
          </div>
        </Content>
      </Modal>
    </Fragment>
  );
};

export default ConsumptionPayments;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    vertical-align: top;
    border-radius: 3px;
  }

  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: initial;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  img {
    width: 70%;
    vertical-align: top;
    border-radius: 3px;
  }

  img.img-pay {
    transition: transform .2s; 
    border-radius: 20px;
  }
  
  img.img-pay:hover {
    transform: scale(1.03);
    background-color: #d9d9d973; 
  }

  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
`;
