import { GET_LIST } from '../types'

export default (state, action) => {
    const {payload2, payload, type} = action;

    switch (type) {
        // case GET_LISTS:
        //     return {
        //         ...state,
        //         lists: payload
        //     }
        case GET_LIST:
            return {
                ...state,
                selectedList: payload,
                personsList: payload2
            }

        default:
            return state;
    }

}