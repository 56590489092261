import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Workbox } from 'workbox-window';
import * as serviceWorker from "./serviceWorker";
// import socketConnectionsReducer from './components/socket-connection-reducer'

const root = ReactDOM.createRoot(document.getElementById('pcoded'));
root.render(
      <App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();

/*
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/service-worker.js');

  wb.addEventListener('installed', (event) => {
    if (event.isUpdate) {
      // Tu lógica para gestionar actualizaciones
    } else {
      // Tu lógica para gestionar la primera instalación
    }
  });

  wb.register()
      .then((registration) => {
            console.log('Service Worker registrado con éxito: ', registration);

            // Registra el evento beforeinstallprompt
            registration.addEventListener('beforeinstallprompt', (e) => {
                  // Guarda el evento para mostrar el banner de instalación más adelante
                  let deferredPrompt = e;

                  // Aquí puedes personalizar la forma en que muestras el banner de instalación,
                  // como agregar un botón en la interfaz de usuario que el usuario puede hacer clic para instalar.
                  // Por ejemplo:
                  mostrarBannerDeInstalacion();
            });
      })
      .catch((error) => {
            console.error('Error al registrar el Service Worker: ', error);
      });
}
*/