import React from "react";
import "./spinner.css";
import styled from "styled-components";
import logo2 from "../../imgs/logo_2.png";
import logo1 from "../../imgs/logo_1.png";
import logo0 from "../../imgs/logo_0.png";

const SpinnerNew = () => {
  return (
    
      <Div>
        <>
          <i className="layer_0">
            <img alt="logo0" src={logo0} />
          </i>
          <i className="layer_1">
            <img alt="logo1" src={logo1} />
          </i>
          <i className="layer_2">
            <img alt="logo2" src={logo2} />
          </i>
        </>
        <div style={{marginTop: "130px",marginLeft: "57px"}}>
          <p style={{fontSize: "1.5rem",color: "#fff"}}>Esto tomará solo un momento...</p>
        </div>
      </Div>
  );
};

export default SpinnerNew;

const Div = styled.div`
  display: flex;
  position: relative;
  width: 430px;
  height: 240px;
  justify-content: center;

  i {
    position: absolute;
    display: block;
    width: 80px;
    height: 101px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 80px 101px;
  }

  i.layer_1 img {
    animation-duration: 2s;
    animation-name: moveCloud;
    animation-iteration-count: infinite;
  }

  i.layer_2 img {
    animation-duration: 2s;
    animation-name: moveGlobe;
    animation-iteration-count: infinite;
  }

  i.layer_2 {
    animation: moveGlobe2 2s linear infinite;
  }

  @keyframes moveCloud {
    0% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(-8%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes moveGlobe {
    0% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(-8%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes moveGlobe2 {
    0% {
      transform: scale(1);
    }
    5% {
      transform: scale(0.98);
    }
    10% {
      transform: scale(1);
    }
    15% {
      transform: scale(0.98);
    }
    20% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.98);
    }
    30% {
      transform: scale(1);
    }
    35% {
      transform: scale(0.98);
    }
    40% {
      transform: scale(1);
    }
    45% {
      transform: scale(0.98);
    }
    50% {
      transform: scale(1);
    }
    55% {
      transform: scale(0.98);
    }
    60% {
      transform: scale(1);
    }
    65% {
      transform: scale(0.98);
    }
    70% {
      transform: scale(1);
    }
    75% {
      transform: scale(0.98);
    }
    80% {
      transform: scale(1);
    }
    85% {
      transform: scale(0.98);
    }
    90% {
      transform: scale(1);
    }
    95% {
      transform: scale(0.98);
    }
    100% {
      transform: scale(1);
    }
  }
`;
