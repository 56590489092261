import React from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import Countdown from "react-countdown";

const InactivityPopup = ({
    setInactivityPopup,
    connectDispatcher,
    socketConn,
    closedByInactivity,
}) => {

    const navigate = useNavigate();

    //#--> Mostrar los segundos en el modal de inactividad
    const renderer = ({ seconds }) => {
        return <span style={{fontSize: "45px"}}>{seconds}</span>;
    };

    //#--> Cerrar sesión cuando termina el tiempo en el mensaje de inactividad
    const handleCountdownComplete = () => {
        console.log("Removing USER_TOKEN because handleCountdownComplete");
        localStorage.removeItem("USER_TOKEN");
        localStorage.removeItem("USER_NAME");
        localStorage.removeItem("USER_ROLEDESCRIPTION");
        localStorage.removeItem("USER_ROLEID");
        if (socketConn.current) {
        closedByInactivity.current = true;
        socketConn.current.close();
        }
        navigate("/");
    };
  return (
    <div className="image-preview-overlay">
        <div className="image-preview-container">
            <div className="content-modal-inactivity bg-white p-2" style={{width: "650px", height: "auto", borderRadius: "7px"}}>
                <div className="p-3">
                    <h2 className="mb-3 d-flex justify-content-center" >
                        ¡Su sesión esta por expirar!
                    </h2>
                    <h2 className="mb-3 d-flex justify-content-center">
                        <Countdown date={Date.now() + 59000} renderer={renderer} onComplete={handleCountdownComplete}/>
                    </h2>
                    <p className="text-justify" style={{fontSize: "1rem"}}>
                        Estimado usuario, nos percatamos de que ha transcurrido un período de inactividad en la plataforma durante algunos minutos.
                    </p>
                    <div className="d-flex justify-content-end mt-3">
                    <input
                        className="btn btn-lg btn-info"
                        value="Continuar en el chat"
                        type="button"
                        onClick={() => {setInactivityPopup(false); connectDispatcher();}}
                    />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default InactivityPopup;