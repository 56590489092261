import { useState } from "react"

const usePage = () => {
  const [page, setPage] = useState(1)
  const [byPage, setByPage] = useState(15)

  const resetPage = () => {
      setPage(1)
  }

  return {
      page,
      byPage,
      resetPage,
      setPage,
      setByPage
  }
}

export default usePage