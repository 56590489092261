import React, { Fragment, useState,useEffect } from "react";
import useAPI from "../../../hooks/useAPI";
import Swal from "sweetalert2";

const MetricsPerAgent = ({dataAgents, selectedOptionInit, onUpdateMetrics}) => {
    const [selectedOption, setSelectedOption] = useState(selectedOptionInit);
      
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        onUpdateMetrics(event.target.value);
    };

    return (
        <Fragment>
            <div div className="content-2 metrics-agent">
                <header className="text-center">
                    <h2 className="m-0">Métricas por Agente</h2>
                </header>
                <div className="text-right d-flex justify-content-end">
                    <select name="select" id="select" className="form-control" value={selectedOption} onChange={handleSelectChange} style={{width: "15%", height: "26px", padding: "0"}}>
                        <option value="0" key="0" selected>Hoy</option>
                        <option value="1" key="1" >Últimos 7 días</option>
                        <option value="2" key="2" >Últimos 15 días</option>
                        <option value="3" key="3" >Últimos 30 días</option>
                        <option value="4" key="4" >Últimos 60 días</option>
                    </select>
                </div>

                <div className="content-table pb-3" style={{ height: "500px", overflowY: "auto" }}>
                    <table id="metrics-agent" className="table table-striped campaigns-report" 
                        // style={{ height: "490px", overflowY: "auto" }}
                    >
                        <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
                            <tr>
                            <th>Agente</th>
                            <th>Departamento</th>
                            <th>Atendidas</th>
                            <th>Cerradas</th>
                            <th>Pendientes</th>
                            <th>Tiempo de gestión</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataAgents && Object.keys(dataAgents).map((id_user, i) =>(
                                <tr key={i}>
                                    <td>{dataAgents[id_user].user.name}</td>
                                    <td>{dataAgents[id_user].area}</td>
                                    <td>{dataAgents[id_user].open}</td>
                                    <td>{dataAgents[id_user].closed}</td>
                                    <td>0</td>
                                    <td>{dataAgents[id_user].atentionTime}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    );
};

export default MetricsPerAgent;
