import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { IoMdDownload,IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const ImageGallery = ({
  imgSelected,
  listImages,
  setIsGallery,
  setImgSelected,
}) => {

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleNext = () => {
    if (selectedIndex < listImages.length - 1) {
      const newIndex = selectedIndex + 1;
      setSelectedIndex(newIndex);
      setImgSelected(listImages[newIndex]);
    }
  };
  
  const handlePrev = () => {
    if (selectedIndex > 0) {
      const newIndex = selectedIndex - 1;
      setSelectedIndex(newIndex);
      setImgSelected(listImages[newIndex]);
    }
  };

  const handleKeyDown2 = (event) => {
    if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'ArrowLeft') {
      handlePrev();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown2);
    return () => {
      document.removeEventListener('keydown', handleKeyDown2);
    };
  }, [selectedIndex]);
          
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsGallery(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setIsGallery]);

  return (
    <div className="image-preview-overlay">
      <div className="image-preview-container h-100" style={{width: "auto"}}>
        <div className="image-preview-header p-0">
          <IoClose
            className="pointer icon-close"
            onClick={() => setIsGallery(false)}
          />
          <a
            href={imgSelected}
            className="pointer"
            target="_blank"
            download="sendingup-image.jpg"
            rel="noreferrer"
          >
            <IoMdDownload style={{ cursor: "pointer", fontSize: "1.4em" }}/>
          </a>
        </div>
        <div className="px-4 py-2 h-100" style={{ overflow: "auto"}}>
          <div className="preview-container h-100">
            <div className="w-100 h-100">
                {imgSelected && (
                  <div className="image-viewer d-flex align-items-center" style={{height:"80%"}}>
                    <img src={imgSelected} alt="Selected" className="w-100 h-100" style={{objectFit:"scale-down"}} />
                  </div>
                )}
                <div className="d-flex justify-content-center w-100 mt-3 pt-2" style={{borderTop:"1px solid"}}>
                    {listImages.map((file_data, index_file) => (
                      <div key={index_file} className="preview-item-container">
                        <div className={`preview-item ${imgSelected === file_data && "media-selected"}`}>
                            <img 
                              src={file_data} 
                              alt="img" 
                              onClick={() => {
                                setImgSelected(file_data)
                                setSelectedIndex(index_file)
                              }}
                            />
                        </div>
                      </div>
                    ))}
                </div>
            </div>
            <div className="navigation-arrows">
              {selectedIndex > 0 && (
                <div 
                  className="pointer position-absolute d-flex align-items-center justify-content-center"
                  style={{
                    background: "#808080a8",
                    borderRadius: "50%",
                    left: "10px",
                    top: "40%",
                    width: "35px",
                    height: "35px",
                  }}
                >
                  <IoIosArrowBack
                    className="arrow-icon text-white"
                    onClick={handlePrev}
                    style={{ fontSize: "2em" }}
                  />
                </div>
              )}
              {selectedIndex < listImages.length - 1 && (
                <div
                  className="pointer position-absolute d-flex align-items-center justify-content-center"
                  style={{
                    background: "#808080a8",
                    borderRadius: "50%",
                    right: "10px",
                    top: "40%",
                    width: "35px",
                    height: "35px",
                  }}
                >
                  <IoIosArrowForward
                    className="arrow-icon text-white"
                    onClick={handleNext}
                    style={{ fontSize: "2em" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;