import React from 'react';
import AutoLinkText from 'react-autolink-text2';
import { IoArrowRedoSharp  } from "react-icons/io5";

const ImageOrStickerMessage = ({
    message,
    typeForwarded,
    textForwarded,
    onViewImage,
    onViewGalery
}) => {
  return (
    <>
        {message.bodyType === "forwarded" && (
            <span className="d-flex align-items-center mb-2" style={{color:"#8696a0", fontSize:"13px", fontStyle:"italic"}}>
                <IoArrowRedoSharp className="mr-1" style={{fontSize: "17px"}} />
                Reenviado
            </span>
        )}
        <div style={{width: message.bodyType === "sticker" || typeForwarded === "sticker" ? "200px" : "18rem"}} className="content-img-chat" id={message.bodyType === "sticker" || typeForwarded === "sticker" ? "img-sticker" : ""}>
            <img
                src={message.bodyMedia}
                className="w-100 pointer"
                style={{borderRadius: "5px"}}
                onClick={() => {
                    let arr = [message.bodyMedia]
                    onViewGalery(arr)
                }}
                //   onClick={() => onViewImage(message.messageId)}
            />
            <span className="span message-text">
                <AutoLinkText text={message.bodyType === "forwarded" ? textForwarded : message.bodytext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
            </span>
        </div>
    </>
  );
};

export default ImageOrStickerMessage;