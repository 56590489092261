import React, {useState, useEffect} from "react";
import { Handle, Position } from 'reactflow';
import useAPI from "../../../../hooks/useAPI";

import { HiOutlineSave } from "react-icons/hi";
const SaveAnswer = ({ data, isConnectable}) => {
    const { optGET, urlAPI_2 } = useAPI(); 
    const [correspondence, setCorrespondence] = useState([]);

    const getCorresp = async () => {
        try {
          const url = urlAPI_2 + "getDataEmail";
          const response = await fetch(url, optGET());
          const result = await response.json();
          setCorrespondence(result.data);
          if (result.message === "Tu petición no tiene cabecera de autorización") {
            Swal.fire({
              title: "¡Atención!",
              text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
              icon: "info",
            });
            navigate("/");
          }
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
            await Promise.all([
                getCorresp(),
            ]);
            } catch (error) {
            console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    let variable = ""
    if(correspondence.length) {
        const find = correspondence.find(v => v.field == data.variable)
        if(find) {
            variable = find.fieldCorresp
        } 
    }
    
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable}  style={{backgroundColor: '#C5C82D'}}/>
            <div>
                <div 
                    className="d-flex align-items-center rounded-top" 
                    style={{height: "10px", backgroundColor: "#C5C82D", padding: "12px"}}
                >
                    <HiOutlineSave className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                    <span style={{fontSize: "10px", color: "#fff"}} >
                        {data.title ?? "Guardar Respuesta"}
                    </span>
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.variable && (
                        <div 
                            className="p-1" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >    
                            <div>
                                <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                    Guardar respuesta en la variable:
                                </span>
                            </div>
                            <p 
                                style={{
                                    fontWeight: 'bold', 
                                    fontSize: "9px", 
                                    backgroundColor: "#F0F0F0", 
                                    color: "#000",
                                    borderRadius: "4px"
                                }} 
                                className="m-0 p-1 text-center"
                            >
                                {variable}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Handle 
                type="source" 
                position={Position.Bottom} 
                id="b" 
                isConnectable={isConnectable}  
                style={{backgroundColor: '#C5C82D'}}
            />
        </div>
    )
}

export default SaveAnswer;