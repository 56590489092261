import React, {useState, useEffect} from "react";
import { Handle, Position } from 'reactflow';
import useAPI from "../../../../hooks/useAPI";

import { TiFlowMerge } from "react-icons/ti";
const FlowsWhatsApp = ({ data, isConnectable}) => {
    const { optGET, urlAPI_2 } = useAPI(); 
    const [flows, setFlows] = useState([]);

    const getFlows = async () => {
        try {
          const url = urlAPI_2 + "flows";
          const response = await fetch(url, optGET());
          const result = await response.json();
          if (result.type === "error") {
            Swal.fire({
              title: "¡Atención!",
              text: result.message,
              icon: result.type,
            });
          }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
            Swal.fire({
              title: "¡Atención!",
              text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
              icon: 'info',
            });
            navigate('/');
          }  else if (!result.data) {
            setFlows([]);
          } else {
            setFlows(result.data);
            setTableFlows(result.data);
    
          }
        } catch (error) {
          console.log(error);
        }
      };

    useEffect(() => {
        const fetchData = async () => {
            try {
            await Promise.all([
                getFlows(),
            ]);
            } catch (error) {
            console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    let flow = ""
    if(flows.length) {
        const find = flows.find(v => v._id == data.flow)
        if(find) {
            flow = find.name
        } 
    }
    
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable}  style={{backgroundColor: '#5B104D'}}/>
            <div>
                <div 
                    className="d-flex align-items-center rounded-top" 
                    style={{height: "10px", backgroundColor: "#5B104D", padding: "12px"}}
                >
                    <TiFlowMerge className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                    <span style={{fontSize: "10px", color: "#fff"}} >
                        {data.title ?? "Flujo de WhatsApp"}
                    </span>
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(91, 16, 77, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    {data.flow && (
                        <div 
                            className="p-1" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >    
                            <div>
                                <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                    Flujos:
                                </span>
                            </div>
                            <p 
                                style={{
                                    fontWeight: 'bold', 
                                    fontSize: "9px", 
                                    backgroundColor: "#F0F0F0", 
                                    color: "#000",
                                    borderRadius: "4px"
                                }} 
                                className="m-0 p-1 text-center"
                            >
                                {flow}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Handle 
                type="source" 
                position={Position.Bottom} 
                id="b" 
                isConnectable={isConnectable}  
                style={{backgroundColor: '#5B104D'}}
            />
        </div>
    )
}

export default FlowsWhatsApp;