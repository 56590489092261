import React from 'react';

const AssingConversationMessage = ({
  roleId,
  agentAssigned,
  setPhoneContact,
  setModalTransferAgent,
  onAssingAgent,
  userId,
}) => {
  return (
    <div className="content-btn-asign-agent d-flex px-2 justify-content-center align-items-center" style={{ height: "10%", marginBottom: "6px", position: "relative" }}>
      <p className="p-asign mb-0 text-center f-w-900 mx-1" style={{fontSize: "1rem", width: "80%",fontStyle: "italic"}}>
        {roleId == "6284fdd697029c6d9743ccb3" || roleId == "64886b1b250f9d31bf2ed68d"
          ? "Debes asignar esta conversación a un agente para que pueda ser atendida."
          : "Debes asignarte esta conversación para poder atenderla."
        }
      </p>
      <input className="btn-asign btn btn-lg btn-info" style={{padding: "9px 16px !important",width: "20%"}}
        value={roleId == "6284fdd697029c6d9743ccb3" || roleId == "64886b1b250f9d31bf2ed68d" ? "Asignar agente" : "Asignarme"}
        type="button"
        onClick={async () => {
          const idPhone = localStorage.getItem("CHAT_SELECTED");
          agentAssigned.current = false;
          setPhoneContact(idPhone);
          if (roleId == "6284fdd697029c6d9743ccb3" || roleId == "64886b1b250f9d31bf2ed68d") {
            console.log("Es administrador o supervisor.................");
            setModalTransferAgent(true);
          } else {
            console.log("Es un agente.............. ", roleId);
            onAssingAgent(userId);
          }
        }}
      />
    </div>
  );
};

export default AssingConversationMessage;