import React from 'react';

const GroupedImages = ({message, onViewGalery}) => {  
  const maxImagesToShow = 4;
  const remainingImagesCount = message.bodyMedia.length - maxImagesToShow;
  
  return (
    <div className="image-group">
      {message.bodyMedia.slice(0, maxImagesToShow).map((image, index) => (
        <div 
          key={index} 
          id={message.bodyMedia.length === 3 && 'imgs-3'}
          className="image-container pointer" 
          onClick={() => onViewGalery(message.bodyMedia)}
        >
          <img src={image} alt={`image-${index}`} className="image" />
          {index === maxImagesToShow - 1 && remainingImagesCount > 0 && (
            <div className="overlay">
              +{remainingImagesCount}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GroupedImages;