import React from 'react';
import AutoLinkText from 'react-autolink-text2';
import { BsFillFileEarmarkFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkWordFill, BsFileEarmarkExcelFill } from "react-icons/bs";
import { IoArrowRedoSharp  } from "react-icons/io5";
import { RiArrowDownCircleLine } from "react-icons/ri";

const DocumentOrForwardedMessage = ({
    showContactInfo,
    message,
    ext,
    textForwarded,
}) => {
  return (
    <div className={showContactInfo && "isShowContactInfo"}>
        {message.bodyType === "forwarded" && (
            <span className="d-flex align-items-center mb-2" style={{color:"#8696a0", fontSize:"13px", fontStyle:"italic"}}>
                <IoArrowRedoSharp className="mr-1" style={{fontSize: "17px"}} />
                Reenviado
            </span>
        )}
        {ext === "pdf" ? (
            <div className="content-pdf" style={{width: "17rem"}}>
                <div className={`doc-pdf px-2 py-3 d-flex align-items-center justify-content-between ${message.originMessage != "user" && "doc-agent"}`}
                style={message.originMessage === "user" ? {background:"#f0f0f0"} : {background:"#bef3a6"}}
                >
                    <div className="d-flex align-items-center">
                        <BsFillFileEarmarkPdfFill style={{fontSize: "28px",color: "red",}}/>
                        <span className="name-doc ml-2">
                            {(message.nameMedia && message.nameMedia.length >= 50)
                                ? message.nameMedia.substr(0, 50) + "..."
                                : message.nameMedia
                            }
                        </span>
                    </div>
                    <a href={message.bodyMedia} target="_blank" download={message.nameMedia} rel="noreferrer">
                        <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                    </a>
                </div>
                <span className="span message-text">
                    <AutoLinkText text={message.bodyType === "forwarded" ? textForwarded : message.bodytext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
                </span>
                <span className="size-document">
                    {message.sizemedia}
                </span>
            </div>
        ) : ext === "csv" || ext === "xlsx" || ext === "xls" ? (
            <div id="cont-excel" style={{width: "17rem"}}>
                <div className={`content-excel px-2 py-3 d-flex align-items-center justify-content-between pointer ${message.originMessage != "user" && "doc-agent"}`}
                    style={message.originMessage === "user" ? {width: "17rem", background:"#f0f0f0"}: {width: "17rem", background:"#bef3a6"}}
                >
                    <div className="d-flex align-items-center">
                        <BsFileEarmarkExcelFill style={{fontSize: "28px", color: "#00d591"}} />
                        <span className="name-doc ml-2">
                            {(message.nameMedia && message.nameMedia.length >= 50)
                                ? message.nameMedia.substr(0, 50) + "..."
                                : message.nameMedia.length >= 35
                                ?  message.nameMedia.substr(0, 13) + "......" + message.nameMedia.substr(message.nameMedia.length - 13)
                                : message.nameMedia
                            }
                        </span>
                    </div>
                    <a href={message.bodyMedia} target="_blank" rel="noreferrer">
                        <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                    </a>
                </div>
                <span className="span message-text">
                    <AutoLinkText text={message.bodyType === "forwarded" ? textForwarded : message.bodytext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
                </span>
                <span className="size-document">
                    {message.sizemedia}
                </span>
            </div>
        ) : ext === "docx" || ext === "doc" ? (
            <>
                <a href="">
                    <div className={`content-word px-2 py-3 d-flex align-items-center justify-content-between pointer ${message.originMessage != "user" && "doc-agent"}`}
                        style={message.originMessage === "user" ? {width: "17rem", background:"#f0f0f0"} : {width: "17rem", background:"#bef3a6"}}
                    >
                        <div className="d-flex align-items-center">
                            <BsFillFileEarmarkWordFill style={{fontSize: "28px", color: "#31acf6"}} />
                            <span className="name-doc ml-2">
                                {(message.nameMedia && message.nameMedia.length >= 40)
                                    ? message.nameMedia.substr(0, 34) + "..."
                                    : message.nameMedia.length >= 35
                                    ?  message.nameMedia.substr(0, 13) + "......" + message.nameMedia.substr(message.nameMedia.length - 13)
                                    : message.nameMedia
                                }
                            </span>
                        </div>
                        <a href={message.bodyMedia} target="_blank" rel="noreferrer">
                            <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                        </a>
                    </div>
                </a>
                <span className="span message-text">
                    <AutoLinkText text={message.bodyType === "forwarded" ? textForwarded : message.bodytext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
                </span>
                <span className="size-document">
                    {message.sizemedia}
                </span>
            </>
        ) : (
            <>
                <a href="">
                    <div
                        className={`content-doc px-2 py-3 d-flex align-items-center justify-content-between pointer ${message.originMessage != "user" && "doc-agent"}`}
                        style={message.originMessage === "user" ? {width: "17rem", background: "#f0f0f0"} : {width: "17rem", background:"#bef3a6"}}
                    >
                        <div className="d-flex align-items-center">
                            <BsFillFileEarmarkFill style={{ fontSize: "28px",color: "#5a5a5a"}} className="icon-file-earmark" />
                            <span className="name-doc ml-2">
                                {(message.nameMedia && message.nameMedia.length >= 18)
                                    ? message.nameMedia.substr(0, 18) + "..."
                                    // : message.nameMedia.length >= 15
                                    // ?  message.nameMedia.substr(0, 13) + "......" + message.nameMedia.substr(message.nameMedia.length - 13)
                                    : message.nameMedia
                                }
                            </span>
                        </div>
                        <a href={ message.bodyMedia} target="_blank" rel="noreferrer">
                            <RiArrowDownCircleLine className="icon-down-circled pointer" style={{fontSize: "2.5rem", opacity: "0.3",}}/>
                        </a>
                    </div>
                </a>
                <span className="span message-text">
                    <AutoLinkText text={message.bodyType === "forwarded" ? textForwarded : message.bodytext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
                </span>
                <span className="size-document">
                    {message.sizemedia}
                </span>
            </>
        )}
    </div>
  );
};

export default DocumentOrForwardedMessage;