import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

const NotFound = () => {
    
    const navigate = useNavigate();

    useEffect(() => {
        Swal.fire({
            title:'Error 404',
            text: 'Pagina NO encontrada.',
            icon: 'error'
        })
        navigate('/');
    }, [])

}

export default NotFound