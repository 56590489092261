import React from "react";
import { Handle, Position } from 'reactflow';

import { LuTags } from "react-icons/lu";
const Tags = ({ data, isConnectable}) => {
    
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable}  style={{backgroundColor: '#5BC0DE'}}/>
            <div>
                <div 
                    className="d-flex align-items-center rounded-top" 
                    style={{height: "10px", backgroundColor: "#5BC0DE", padding: "12px"}}
                >
                    <LuTags className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                    <span style={{fontSize: "10px", color: "#fff"}} >
                        {data.title ?? "Etiquetar"}
                    </span>
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(91, 192, 222, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    {data.tags && data.tags.length > 0 && (
                        <div 
                            className="p-1" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >    
                            <div>
                                <LuTags className="mr-1" style={{fontSize: "12px", color: "#000"}}/>
                                <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                    Etiquetas:
                                </span>
                            </div>
                            {data.tags.map(tag => (
                                <div className="d-flex align-items-center">
                                    <p 
                                        style={{fontWeight: "bold", fontSize: "9px", backgroundColor: "#E6E6E6", borderRadius: "4px"}} 
                                        className="m-0 p-1 mb-1"
                                    >
                                        {tag.title}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <Handle 
                type="source" 
                position={Position.Bottom} 
                id="b" 
                isConnectable={isConnectable}  
                style={{backgroundColor: '#5BC0DE'}}
            />
        </div>
    )
}

export default Tags;