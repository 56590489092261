import React from 'react';
import AutoLinkText from 'react-autolink-text2';

const TextMessage = ({message, isTextError}) => {
  return (
    <span style={{fontStyle: isTextError ? "italic" : "normal", fontWeight: isTextError && "700" }}>
      <AutoLinkText text={message.bodytext} linkProps={{ target: '_blank', rel: 'noopener noreferrer'}}/>
    </span>
  );
};

export default TextMessage;